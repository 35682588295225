import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-11 my-24 gap-12 lg:w-theme-dw md:mx-auto md:px-0 flex flex-col md:flex-row md:items-start" }
const _hoisted_2 = { class: "flex-1 flex flex-col gap-6" }
const _hoisted_3 = { class: "flex flex-col gap-5 mt-6" }
const _hoisted_4 = { class: "flex flex-row items-center gap-3" }
const _hoisted_5 = { class: "flex items-center justify-center bg-theme-white border-2 border-gray-4 rounded-full h-[40px] w-[40px]" }
const _hoisted_6 = { class: "flex flex-row items-center gap-3" }
const _hoisted_7 = { class: "flex items-center justify-center bg-theme-white border-2 border-gray-4 rounded-full h-[40px] w-[40px]" }
const _hoisted_8 = { class: "flex flex-row items-center gap-3" }
const _hoisted_9 = { class: "flex items-center justify-center bg-theme-white border-2 border-gray-4 rounded-full h-[40px] w-[40px]" }
const _hoisted_10 = { class: "flex flex-col md:flex-row flex-wrap w-full" }
const _hoisted_11 = { class: "flex-1 flex flex-col gap-6 bg-theme-gray-1 px-11 md:px-16 py-24" }
const _hoisted_12 = { class: "flex flex-col gap-5 mt-6" }
const _hoisted_13 = { class: "flex flex-row items-center gap-3" }
const _hoisted_14 = { class: "flex items-center justify-center bg-theme-white border-2 border-gray-4 rounded-full h-[40px] w-[40px]" }
const _hoisted_15 = { class: "flex flex-row items-center gap-3" }
const _hoisted_16 = { class: "flex items-center justify-center bg-theme-white border-2 border-gray-4 rounded-full h-[40px] w-[40px]" }
const _hoisted_17 = { class: "flex flex-row items-center gap-3" }
const _hoisted_18 = { class: "flex items-center justify-center bg-theme-white border-2 border-gray-4 rounded-full h-[40px] w-[40px]" }
const _hoisted_19 = { class: "flex flex-col py-28 gap-16 lg:w-theme-dw px-11 md:mx-auto md:px-0" }

import { computed, ref, watch } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import ContactForm from '@/components/ContactForm.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AboutUs',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TopBanner, {
      layout: "normal",
      currentPage: "About Us",
      heading: "About Us",
      bannerClass: "banner-booking"
    }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "text-theme-gray-5 text-size-8 md:text-head-md" }, " For over 3 decades, we have been providing the best transport services. We offer Standard, Premium, and Luxury people carriers. ", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-theme-gray-4 font-light text-size-6" }, " We address our offer to all who want to travel in comfortable conditions. We have top-class vehicles, so our customers can be assured of comfort and safety. ", -1)),
        _createElementVNode("ul", _hoisted_3, [
          _createElementVNode("li", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_font_awesome_icon, {
                size: "lg",
                icon: "fa-solid fa-check",
                class: "text-theme-active"
              })
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-theme-gray-4 font-light text-size-6" }, "Professionally Trained Chauffeurs", -1))
          ]),
          _createElementVNode("li", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_font_awesome_icon, {
                size: "lg",
                icon: "fa-solid fa-check",
                class: "text-theme-active"
              })
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-theme-gray-4 font-light text-size-6" }, "24/7 Full-Service Transportation", -1))
          ]),
          _createElementVNode("li", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_font_awesome_icon, {
                size: "lg",
                icon: "fa-solid fa-check",
                class: "text-theme-active"
              })
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-theme-gray-4 font-light text-size-6" }, "Global network of Transportation Partners", -1))
          ])
        ])
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("section", { class: "flex-1" }, [
        _createElementVNode("img", {
          src: "/assets/13.jpeg",
          alt: ""
        })
      ], -1))
    ]),
    _createElementVNode("section", _hoisted_10, [
      _cache[11] || (_cache[11] = _createElementVNode("section", { class: "md:flex md:flex-1 bg-center bg-no-repeat bg-cover banner-river h-[240px] md:h-auto" }, [
        _createElementVNode("div")
      ], -1)),
      _createElementVNode("section", _hoisted_11, [
        _cache[9] || (_cache[9] = _createElementVNode("h2", { class: "text-theme-gray-5 text-size-8 md:text-head-md" }, " Our main headquarters is in the Kingdom of Bahrain with satellite office in Riyadh, Saudia Arabia ", -1)),
        _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-theme-gray-4 font-light text-size-6" }, " Our broad network of transportation partners across the globe allows us to offer excellent ground transportation services for both individuals and groups of any size, wherever they may be. Whether you’re planning a special event with loved ones or friends, we understand that you would prefer to enjoy yourself and not be concerned about driving. Our skilled chauffeurs will navigate you through any city you find yourself in. ", -1)),
        _createElementVNode("ul", _hoisted_12, [
          _createElementVNode("li", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_font_awesome_icon, {
                size: "lg",
                icon: "fa-solid fa-check",
                class: "text-theme-active"
              })
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-theme-gray-4 font-light text-size-6" }, "Professionally Trained Chauffeurs", -1))
          ]),
          _createElementVNode("li", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_font_awesome_icon, {
                size: "lg",
                icon: "fa-solid fa-check",
                class: "text-theme-active"
              })
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "text-theme-gray-4 font-light text-size-6" }, "24/7 Full-Service Transportation", -1))
          ]),
          _createElementVNode("li", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_font_awesome_icon, {
                size: "lg",
                icon: "fa-solid fa-check",
                class: "text-theme-active"
              })
            ]),
            _cache[8] || (_cache[8] = _createElementVNode("span", { class: "text-theme-gray-4 font-light text-size-6" }, "Global network of Transportation Partners", -1))
          ])
        ])
      ])
    ]),
    _cache[13] || (_cache[13] = _createStaticVNode("<section class=\"grid grid-cols-4\"><div class=\"flex flex-col col-span-4 md:col-span-1 bg-theme-gray-4 py-12 pl-20 pr-12\"><div class=\"ml-auto font-light text-head-md-2 text-[#FFFFFF4D]\">01</div><div class=\"mb-4\"><span class=\"font-icon theme-icon calendar-icon text-white text-[47px]\"></span></div><p class=\"text-white font-light text-size-6 mb-4\"> Each of our drivers is characterized by professionalism and punctuality </p></div><div class=\"flex flex-col col-span-4 md:col-span-1 bg-theme-gray-2 py-12 pl-20 pr-12\"><div class=\"ml-auto font-light text-head-md-2 text-[#FFFFFF4D]\">02</div><div class=\"mb-4\"><span class=\"font-icon theme-icon arrow-rounded-icon text-white text-[47px]\"></span></div><p class=\"text-white font-light text-size-6 mb-4\"> We always go extra mile when it comes to the safety </p></div><div class=\"flex flex-col col-span-4 md:col-span-1 bg-theme-gray-4 py-12 pl-20 pr-12\"><div class=\"ml-auto font-light text-head-md-2 text-[#FFFFFF4D]\">03</div><div class=\"mb-4\"><span class=\"font-icon theme-icon suitcase-icon text-white text-[47px]\"></span></div><p class=\"text-white font-light text-size-6 mb-4\"> You can rely on our knowledge and experience to guide you on your tour </p></div><div class=\"flex flex-col col-span-4 md:col-span-1 bg-theme-gray-2 py-12 pl-20 pr-12\"><div class=\"ml-auto font-light text-head-md-2 text-[#FFFFFF4D]\">04</div><div class=\"mb-4\"><span class=\"font-icon theme-icon award-icon text-white text-[47px]\"></span></div><p class=\"text-white font-light text-size-6 mb-4\"> Premium quality and award winning customer service </p></div></section>", 1)),
    _createElementVNode("section", _hoisted_19, [
      _cache[12] || (_cache[12] = _createElementVNode("h2", { class: "text-theme-gray-4 text-size-7 md:text-head-md-2 font-light text-center" }, [
        _createElementVNode("p", null, "Private chauffeur, sightseeing tour?"),
        _createElementVNode("p", null, "Please fill out the form below with questions or comments")
      ], -1)),
      _createVNode(ContactForm)
    ])
  ], 64))
}
}

})