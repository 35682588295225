<template>
   <div class="bg-white">
      
      <section id="mainbanner" class="bg-center bg-no-repeat bg-cover banner-img">
      
         <div class="flex flex-col mx-auto w-full lg:w-theme-dw py-24 px-11 md:px-0">
            <h2 class="font-bold text-size-4 md:text-size-6 uppercase text-white mb-4">Premium Services Tailored for You.</h2>
            <div class="flex flex-col text-white mb-12">
               <div class="text-head-md-3 md:text-head-lg">International Ground Transportation and more</div>
               <div class="text-head-md-3 md:text-head-lg">Make Your Online Reservation</div>
            </div>
            <Search layout="simple" :data="search" :type="search && search.driveType ? search.driveType : (search && search.type ? search.type : 'hourly')" />
         </div>
      </section>
      <section class="flex flex-col mx-auto lg:w-theme-dw mt-24 pb-10 px-11 md:px-0">
         <div class="flex flex-col md:flex-row md:items-start md:space-x-8">
            <h2 class="flex-1 font-extrabold text-theme-active text-head-md md:text-[50px] leading-[55px] mb-16 md:mb-0">From Sedans to Luxury.</h2>
            <div class="flex flex-col flex-1 mb-12 md:mb-0">
               <div class="text-size-8 md:text-head-md text-gray-5 mb-8">Expect the Best</div>
               <div class="font-light text-theme-gray-4 text-size-6">
                  By offering exceptional service with no detail unattended, we have been fortunate enough to have developed into the leading provider 
                  of international ground transportation in the area.
               </div>
            </div>
            <div class="flex flex-col flex-1">
               <div class="text-size-8 md:text-head-md text-gray-5 mb-8">Travel in Comfort</div>
               <div class="font-light text-theme-gray-4 text-size-6">
                  At Vehitra World we pride ourselves in delivering extensive services to fulfill all of your needs with first-rate customer care. 
                  Our goal is to make your travels safe, effortless, and on schedule.
               </div>
            </div>
         </div>
         <div class="flex flex-row items-start mt-20">
            <div class="flex flex-col flex-1">
               <img src="/assets/6.jpeg" alt="">
            </div>
            <div class="flex flex-col flex-1 mt-10">
               <img src="/assets/9.jpg" alt="">
            </div>
         </div>
         <div class="flex flex-col items-center mt-20">
            <div>
               <font-awesome-icon size="2xl" icon="fa-solid fa-quote-right" class="text-theme-active" />
            </div>
            <div class="text-center mt-4 font-light text-size-7 md:text-[28px] text-theme-gray-4">I invite you to try our service and I personally guarantee<br> you will have a fully satisfying experience.</div>
            <div class="text-theme-gray-2 uppercase mt-8">Ghazi Hamadeh - Chairman & CEO</div>
         </div>
      </section>
      <section class="bg-theme-gray-1 pt-24 pb-32 px-11 md:px-0">
         <div class="flex flex-col mx-auto lg:w-theme-dw">
            <h2 class="font-bold text-theme-active text-size-4 md:text-size-6 text-center mb-3 uppercase">What we offer</h2>
            <div class="font-light text-head-md-2 md:text-head-xl text-theme-gray-5 text-center">See What We Can Do For You</div>
            <div class="grid grid-cols-2 gap-10 mt-20">
               <a href="#mainbanner" class="relative group col-span-2 md:col-span-1">
                  <img class="w-full" src="/assets/8.jpeg" alt="">
                  <div class="bg-[#858C9266] w-full h-full absolute top-0 left-0"></div>
                  <div class="absolute bottom-4 md:bottom-10 left-4 md:left-8">
                     <div class="h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500"></div>
                     <span class="font-bold text-size-4 md:text-size-6 uppercase text-white">Airport transfer</span>
                  </div>
               </a>
               <a href="#mainbanner" class="relative group col-span-2 md:col-span-1">
                  <img class="w-full" src="/assets/7.jpeg" alt="">
                  <div class="bg-[#858C9266] w-full h-full absolute top-0 left-0"></div>
                  <div class="absolute bottom-4 md:bottom-10 left-4 md:left-8">
                     <div class="h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500"></div>
                     <span class="font-bold text-size-4 md:text-size-6 uppercase text-white">Chauffeur Services</span>
                  </div>
               </a>
               
               <div class="grid grid-cols-2 gap-10 col-span-2 md:col-span-1">
                  <a href="https://carsirent.com" target="_blank" class="relative group">
                     <img class="w-full" src="/assets/10.jpeg" alt="">
                     <div class="bg-[#858C9266] w-full h-full absolute top-0 left-0"></div>
                     <div class="absolute bottom-4 md:bottom-10 left-4 md:left-8">
                        <div class="h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500"></div>
                        <span class="font-bold text-size-4 md:text-size-6 uppercase text-white">CAR RENTAL</span>
                     </div>
                  </a>
                  <div class="relative group pointer" @click="router.push({name: 'MeetGreet'})">
                     <img class="w-full" src="/assets/4.jpg" alt="">
                     <div class="bg-[#858C9266] w-full h-full absolute top-0 left-0"></div>
                     <div class="absolute bottom-4 md:bottom-10 left-4 md:left-8">
                        <div class="h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500"></div>
                        <span class="font-bold text-size-4 md:text-size-6 uppercase text-white">MEET & GREET</span>
                     </div>
                  </div>
               </div>
               <div class="grid grid-cols-2 gap-10 col-span-2 md:col-span-1">
                  
                  <div class="relative group pointer" @click="router.push({name: 'BusesCoaches'})">
                     <img class="w-full" src="/assets/busescoaches.jpeg" alt="">
                     <div class="bg-[#858C9266] w-full h-full absolute top-0 left-0"></div>
                     <div class="absolute bottom-4 md:bottom-10 left-4 md:left-8">
                        <div class="h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500"></div>
                        <span class="font-bold text-size-4 md:text-size-6 uppercase text-white">BUSES & COACHES</span>
                     </div>
                  </div>
                  <div class="relative group pointer" @click="router.push({name: 'Planmytrip'})">
                     <img class="w-full" src="/assets/5.jpg" alt="">
                     <div class="bg-[#858C9266] w-full h-full absolute top-0 left-0"></div>
                     <div class="absolute bottom-4 md:bottom-10 left-4 md:left-8">
                        <div class="h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500"></div>
                        <span class="font-bold text-size-4 md:text-size-6 uppercase text-white">LEISURE & BUSSINESS SPECIAL EVENT</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="bg-white-1 pt-24 pb-32 px-11 md:px-0">
         <div class="flex flex-col mx-auto lg:w-theme-dw">
            <h2 class="font-bold text-theme-active text-size-4 md:text-size-6 text-center mb-3 uppercase">Why choose us</h2>
            <div class="font-light text-head-md-2 md:text-head-xl text-theme-gray-5 text-center">Proudly Serving the Mena Region Since 1988</div>
            <div class="md:hidden text-size-6 font-bold text-theme-gray-4 mt-8">
               7 CORE VALUES that make us the best transportation company serving the mena travelers to more than 60,000 worldwide locations.
            </div>
            <div class="hidden md:flex md:flex-row justify-center items-center md:mt-20">
               <img src="/assets/11.png" alt="">
            </div>
            <div class="mt-12 md:hidden md:mt-0">
               <div class="flex flex-col gap-y-8">
                  <div class="flex flex-row items-center gap-5">
                     <div class="flex flex-row items-center justify-center rounded-full border-2 border-text-theme-gray-4 p-3">
                        <font-awesome-icon size="lg" icon="fa-solid fa-check" class="text-theme-active" />
                     </div>
                     <div class="text-theme-gray-4 text-size-6 uppercase font-light">First rate customer service</div>
                  </div>
                  <div class="flex flex-row items-center gap-5">
                     <div class="flex flex-row items-center justify-center rounded-full border-2 border-text-theme-gray-4 p-3">
                        <font-awesome-icon size="lg" icon="fa-solid fa-check" class="text-theme-active" />
                     </div>
                     <div class="text-theme-gray-4 text-size-6 uppercase font-light">CLEAN AND WELL-MAINTAINED VEHICLES</div>
                  </div>
                  <div class="flex flex-row items-center gap-5">
                     <div class="flex flex-row items-center justify-center rounded-full border-2 border-text-theme-gray-4 p-3">
                        <font-awesome-icon size="lg" icon="fa-solid fa-check" class="text-theme-active" />
                     </div>
                     <div class="text-theme-gray-4 text-size-6 uppercase font-light">INNOVATIVE USE OF TECHNOLOGY</div>
                  </div>
                  <div class="flex flex-row items-center gap-5">
                     <div class="flex flex-row items-center justify-center rounded-full border-2 border-text-theme-gray-4 p-3">
                        <font-awesome-icon size="lg" icon="fa-solid fa-check" class="text-theme-active" />
                     </div>
                     <div class="text-theme-gray-4 text-size-6 uppercase font-light">PUNCTUALITY AND VERACITY</div>
                  </div>
                  <div class="flex flex-row items-center gap-5">
                     <div class="flex flex-row items-center justify-center rounded-full border-2 border-text-theme-gray-4 p-3">
                        <font-awesome-icon size="lg" icon="fa-solid fa-check" class="text-theme-active" />
                     </div>
                     <div class="text-theme-gray-4 text-size-6 uppercase font-light">PURSUIT OF CONTINUOUS IMPROVEMENT</div>
                  </div>
                  <div class="flex flex-row items-center gap-5">
                     <div class="flex flex-row items-center justify-center rounded-full border-2 border-text-theme-gray-4 p-3">
                        <font-awesome-icon size="lg" icon="fa-solid fa-check" class="text-theme-active" />
                     </div>
                     <div class="text-theme-gray-4 text-size-6 uppercase font-light">SAFETY IS OUR HIGHEST PRIORITY</div>
                  </div>
                  <div class="flex flex-row items-center gap-5">
                     <div class="flex flex-row items-center justify-center rounded-full border-2 border-text-theme-gray-4 p-3">
                        <font-awesome-icon size="lg" icon="fa-solid fa-check" class="text-theme-active" />
                     </div>
                     <div class="text-theme-gray-4 text-size-6 uppercase font-light">HIGHLY TRAINED PROFESSIONAL DRIVERS</div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="bg-white-1">
         <div class="flex flex-col md:flex-row flex-wrap w-full">
            <div class="flex flex-1 bg-theme-gray-1 pb-12 pt-24 md:py-36">
               <swiper :spaceBetween="30" :pagination="{ clickable: true }" :modules="modules" class="my-swiper swipe-testimonials">
                  <swiper-slide>
                     <div class="flex flex-col items-center justify-center w-full mx-auto cursor-pointer">
                        <div class="relative bg-white rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
                           <span class="font-icon theme-icon comments-icon text-theme-active absolute -top-[23px] -left-[13px] text-[47px]"></span>
                        </div>
                        <div class="font-light text-head-sm text-theme-gray-4 text-center mt-12">
                           I couldn’t be happier with the transfer you did. It was great and the car was amazing. 
                           Thank you for your great service and I will continue to refer friends and family to you all.
                        </div>
                        <div class="uppercase text-theme-gray-2 text-size-2 mt-10 tracking-wider">Aisha Rawan</div>
                     </div>
                  </swiper-slide>
                  <swiper-slide>
                     <div class="flex flex-col items-center justify-center w-full mx-auto cursor-pointer">
                        <div class="relative bg-white rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
                           <span class="font-icon theme-icon comments-icon text-theme-active absolute -top-[23px] -left-[13px] text-[47px]"></span>
                        </div>
                        <div class="font-light text-head-sm text-theme-gray-4 text-center mt-12">
                           Great experience with Vehitra World. I have used many others in Saudi Arabia but this was by far the best. You guys made our 
                           trip so easy. Great customer service, wonderful drivers and by far the best and most affordable company I have used. 
                           Thank you soo much!
                        </div>
                        <div class="uppercase text-theme-gray-2 text-size-2 mt-10 tracking-wider">Muhammad</div>
                     </div>
                  </swiper-slide>
                  <swiper-slide>
                     <div class="flex flex-col items-center justify-center w-full mx-auto cursor-pointer">
                        <div class="relative bg-white rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
                           <span class="font-icon theme-icon comments-icon text-theme-active absolute -top-[23px] -left-[13px] text-[47px]"></span>
                        </div>
                        <div class="font-light text-head-sm text-theme-gray-4 text-center mt-12">
                           I hired Vehitra World to pick me up at the airport with their Sedan. I had just got back from a long trip in Germany and was 
                           very tired. The driver was on time and extremely courteous and I had no problems at all. Great service!
                        </div>
                        <div class="uppercase text-theme-gray-2 text-size-2 mt-10 tracking-wider">Kevin Foster</div>
                     </div>
                  </swiper-slide>
                  <swiper-slide>
                     <div class="flex flex-col items-center justify-center w-full mx-auto cursor-pointer">
                        <div class="relative bg-white rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
                           <span class="font-icon theme-icon comments-icon text-theme-active absolute -top-[23px] -left-[13px] text-[47px]"></span>
                        </div>
                        <div class="font-light text-head-sm text-theme-gray-4 text-center mt-12">
                           This is the best service I have had so far. The driver was punctual and very helpful, he knew the city well. 
                           His skills helped avoid traffic jams. I will recommend Vehitra World to all my friends!
                        </div>
                        <div class="uppercase text-theme-gray-2 text-size-2 mt-10 tracking-wider">Mitch Michael</div>
                     </div>
                  </swiper-slide>
               </swiper>
            </div>
            <div class="md:flex md:flex-1 bg-center bg-no-repeat bg-cover banner-cars h-[240px] md:h-auto"></div>
         </div>
      </section>
      <section class="bg-white-1 pb-32">
         <div class="bg-center bg-no-repeat bg-cover banner-2-img py-24">
            <div class="flex flex-col mx-auto bg-theme-gray-1 py-12 mx-auto w-[80%] md:w-[30%] md:px-12">
               <div class="flex flex-col justify-center items-center">
                  <span class="font-icon theme-icon tower-icon text-theme-active text-[47px]"></span>
                  <h4 class="uppercase text-theme-gray-5 font-bold md:text-size-6 text-size-4 pt-4">Your Next Travel With Us</h4>
                  <div class="text-center md:text-head-md-2 text-size-7 text-theme-gray-5 font-light pt-8">
                     Use our online tool to request your preferred ride
                  </div>
                  <div class="mt-8 text-center bg-theme-gray-7 text-theme-gray-2 text-size-2 rounded-full py-4 text-center px-8 uppercase cursor-pointer hover:bg-theme-active hover:text-white transition ease-in-out duration-300">
                     Apply Now
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="bg-white-1 pb-24 md:pb-32">
         <div class="flex flex-col mx-auto lg:w-theme-dw px-8 md:px-auto">
            <h2 class="font-bold text-theme-active md:text-size-6 text-size-4 text-center mb-3 uppercase">Key Features</h2>
            <div class="font-light md:text-head-xl text-head-md-2 text-theme-gray-5 text-center">Make Your Trip Your Way With Us</div>
            <div class="flex flex-col mt-20">
               <div class="flex flex-col md:flex-row items-start md:gap-24 gap-12">
                  <div class="flex flex-1 flex-col items-center">
                     <div class="relative bg-theme-gray-7 rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
                        <span class="font-icon theme-icon car-hood-icon text-theme-active absolute -top-[23px] -left-[13px] text-[47px]"></span>
                     </div>
                     <div class="font-bold text-theme-gray-5 md:text-size-6 text-size-4 my-6">Safety First</div>
                     <div class="text-theme-gray-4 text-size-6 font-light text-center">
                        Experienced staff and professionally trained chauffeurs
                     </div>
                  </div>
                  <div class="flex flex-1 flex-col items-center">
                     <div class="relative bg-theme-gray-7 rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
                        <span class="font-icon theme-icon bus-icon text-theme-active absolute -top-[23px] -left-[13px] text-[47px]"></span>
                     </div>
                     <div class="font-bold text-theme text theme-gray-5 text-size-6 my-6">Largest Fleet</div>
                     <div class="text-theme-gray-4 text-size-6 font-light text-center">
                        We offer you an extensive fleet of vehicles including the latest models sedans, premium, and luxury.
                     </div>
                  </div>
                  <div class="flex flex-1 flex-col items-center">
                     <div class="relative bg-theme-gray-7 rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
                        <span class="font-icon theme-icon paper-plane-icon text-theme-active absolute -top-[23px] -left-[13px] text-[47px]"></span>
                     </div>
                     <div class="font-bold text-theme text theme-gray-5 text-size-6 my-6">International Service</div>
                     <div class="text-theme-gray-4 text-size-6 font-light text-center">
                        We provide our transportation services in more than 60,000 worldwide locations.
                     </div>
                  </div>
                  <div class="flex flex-1 flex-col items-center">
                     <div class="relative bg-theme-gray-7 rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
                        <span class="font-icon theme-icon road-map-icon text-theme-active absolute -top-[23px] -left-[13px] text-[47px]"></span>
                     </div>
                     <div class="font-bold text-theme text theme-gray-5 text-size-6 my-6">GCC Prime Service</div>
                     <div class="text-theme-gray-4 text-size-6 font-light text-center">
                        Our services are available in all GCC Countries; Saudi Arabia, UAE, Kuwait, Qatar, Oman, Bahrain with latest models cars and professional drivers.
                     </div>
                  </div>
               </div>
               <div class="flex flex-row items-center justify-center mt-10">
                  <div class="w-fit uppercase text-size-2 py-4 px-8 text-center rounded-full bg-white border-2 text-theme-active cursor-pointer hover:bg-theme-active hover:text-white border-theme-active hover:text-theme-active transition ease-in-out duration-300">
                     About Company
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination } from 'swiper/modules'
import Search from '@/components/Search.vue'
import 'swiper/css'
import 'swiper/css/pagination'
import { retrieveUserTempSearch, deleteUserTempSearch } from '@/services/search-service'
import { UserSearch } from '@/types'
import router from '@/router'

const modules = [Pagination]
const search = retrieveUserTempSearch()
deleteUserTempSearch()

</script>