<template>

   <div v-if="!isLoading" class="my-0 md:w-[50%] mx-auto ">
      <div ref="pdfContent">
         <Voucher :data="data" />
      </div>
      <div class="flex flex-row items-center gap-3 justify-end">
         <button @click="handleCancelBooking" type="button" class="rounded-md bg-theme-button5-bg px-2.5 py-1.5 text-sm font-semibold text-theme-button5-txt shadow-sm">
            Cancel Booking
         </button>
         <button v-if="showMakePaymentButton" @click="handleMakePayment" type="button" class="rounded-md bg-theme-button1-bg px-2.5 py-1.5 text-sm font-semibold text-theme-button1-txt shadow-sm">
            Make Payment
         </button>
         <button @click="downloadPDF" type="button" class="rounded-md bg-theme-button2-bg px-2.5 py-1.5 text-sm font-semibold text-theme-button2-txt shadow-sm">
            Download PDF
         </button>
      </div>
   </div>
   <Modal :show="openConfirmModal" @closed="handleConfirmClose">
      <template v-slot:content>
         <div class="mt-8">
            To cancel this booking contact our support representative at support@vehitraworld.com
         </div>
      </template>
   </Modal>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import Voucher from '@/components/booking/Voucher.vue'
import { Booking } from '@/types'
import BookingService from '@/services/booking-service'
import { getSessionPrefix, isMakePaymentVisible, saveBookingNumber } from '@/utils/common'
import router from '@/router'
// @ts-ignore
import html2pdf from 'html2pdf.js'
import Modal from '@/widgets/Modal.vue'
import { saveUserSearch } from '@/services/search-service'
import CarService from '@/services/car-service'

const props = defineProps(['number'])
const data = ref<Booking | null>(null)
const isLoading = ref<boolean>(true)
const { readBooking } = BookingService()
const pdfContent = ref(null)
const openConfirmModal = ref<boolean>(false)
const showMakePaymentButton = ref<boolean>(false)
const { saveSelectedCar } = CarService()

onMounted(async () => {

   const response = await readBooking(props.number)
   isLoading.value = false
   
   if (response.success) {
      data.value = response.data.data
   } else {
      console.log(response.error)
   }

   showMakePaymentButton.value = response.data.data.search ? isMakePaymentVisible(response.data.data.search.pickUp.date) : false
})

const downloadPDF = () => {

   const content = pdfContent.value
   html2pdf(content).from(content).save()
}

const handleCancelBooking = () => {

   openConfirmModal.value = true
}

const handleMakePayment = () => {
   if (data.value) {
      saveUserSearch(data.value.search)
      saveBookingNumber(props.number)
      saveSelectedCar(data.value.car)
      router.push({name: 'BookingWizard', params: {step: 'contact-details'}})
   }
}

const handleConfirmClose = () => openConfirmModal.value = false

</script>