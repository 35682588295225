import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-2" }
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = {
  key: 1,
  class: "font-bold text-theme-gray-5 text-size-6"
}
const _hoisted_4 = {
  key: 2,
  class: "ml-auto"
}

import { onMounted, ref, watch } from 'vue'
import { getDateDisplayFormat } from '@/utils/date'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'


export default /*@__PURE__*/_defineComponent({
  __name: 'DateField',
  props: ['layout', 'hasError', 'data'],
  emits: ['dateChanged'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const date = ref<string | null>(null)

onMounted(() => {

   if (props.data) {
      date.value = props.data
   }
})

watch(date, (newValue, oldValue) => {

   let val : string | null = newValue

   if (newValue === '') {
      val = null
   }

  emit('dateChanged', val)
})


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["p-5", __props.layout == 'simple' ? 'col-span-7 md:col-span-1' : 'col-span-8 md:col-span-4'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([__props.hasError ? 'text-theme-error' : 'text-theme-gray-2', "flex gap-2 uppercase text-small"])
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("span", null, "PickUp Date", -1)
      ]), 2),
      _createElementVNode("div", _hoisted_2, [
        (__props.layout=='simple')
          ? (_openBlock(), _createBlock(_unref(flatPickr), {
              key: 0,
              modelValue: date.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((date).value = $event)),
              config: _unref(getDateDisplayFormat)(),
              class: "font-bold text-theme-gray-5 text-size-6 border-0 outline-none w-[90%]",
              placeholder: ""
            }, null, 8, ["modelValue", "config"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(date.value), 1)),
        (__props.hasError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_font_awesome_icon, {
                size: "1x",
                icon: "fa-solid fa-circle-exclamation",
                class: "text-theme-error"
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}
}

})