import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "w-full mb-5" }
const _hoisted_2 = { class: "flex flex-row items-center w-full" }
const _hoisted_3 = {
  key: 0,
  class: "ml-auto"
}
const _hoisted_4 = {
  key: 1,
  class: "ml-auto"
}
const _hoisted_5 = {
  key: 0,
  class: "z-10 !mt-0 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex" }

import { ref, onMounted } from 'vue'
import { CarType, Locality, Supplier } from '@/types/index'
import Admin from '@/composables/admin/index'
import { resetErrors, handleApiError } from '@/utils/common'
import LoaderIcon from '@/components/LoaderIcon.vue'

const SEARCH_DELAY = 400

export default /*@__PURE__*/_defineComponent({
  __name: 'SaveLocation',
  props: ['mode'],
  emits: ['success', 'error'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const isDropOpen = ref<boolean>(false)
const location = ref<string>('')
const locations = ref<Locality[]>([])
const selectedLocation = ref<Locality | null>(null)
let searchTimeout: ReturnType<typeof setTimeout> | null = null

const handleSelect = (value: Locality) => {
   location.value = value.name
   selectedLocation.value = value
   isDropOpen.value = false
}

const validateForm = () : boolean => {

   let response = true

   if (selectedLocation === null) {
      response = false
   }

   return response 
}

const handleSubmit = async () => {

   hasError.value = false
   const isValid = validateForm()

   if (isValid) {

   }
}


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        for: "name",
        class: _normalizeClass([hasError.value ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
      }, "Search", 2),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((location).value = $event)),
          type: "text",
          class: _normalizeClass([!hasError.value ? 'border-gray-300' : 'border-theme-error', "mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm"])
        }, null, 2), [
          [_vModelText, location.value]
        ]),
        (isLoading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(LoaderIcon)
            ]))
          : _createCommentVNode("", true),
        (hasError.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_font_awesome_icon, {
                size: "1x",
                icon: "fa-solid fa-circle-exclamation",
                class: "text-theme-error"
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      (isDropOpen.value && locations.value.length)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locations.value, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                onClick: ($event: any) => (handleSelect(item)),
                key: index,
                class: "flex flex-row items-start gap-2 py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer"
              }, [
                _createElementVNode("span", null, _toDisplayString(item.name), 1)
              ], 8, _hoisted_6))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (handleSubmit())),
        type: "button",
        class: "ml-auto mt-20 bg-theme-active py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white outline-none"
      }, " Save ")
    ])
  ], 64))
}
}

})