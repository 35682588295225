<template>

   <TopBanner layout="normal" currentPage="Booking" heading="Booking" bannerClass="banner-booking" />

   <div v-if="!html" class="flex flex-col py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
      <div class="flex flex-col">
         <div class="grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7">
            <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5">
               <div :class="!errors.cardName ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                  <span>Card holder name *</span>
                  <span v-if="errors.cardName" class="text-theme-error">(required)</span>
               </div>
               <input v-model="paymentDetails.card.name" type="text" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
            </div>
            <div class="col-span-2 md:col-span-1">
               <div class="col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                  <div :class="!errors.cardNumber ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                     <span>Card number *</span>
                     <span v-if="errors.cardNumber" class="text-theme-error">({{ errors.cardNumber }})</span>
                  </div>
                  <input v-model="paymentDetails.card.number" type="number" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
               </div>
            </div>
         </div>
         <div class="grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7">
            <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5">
               <div :class="!errors.cardCVC ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                  <span>CVC *</span>
                  <span v-if="errors.cardCVC" class="text-theme-error">(required)</span>
               </div>
               <input v-model="paymentDetails.card.cvc" type="text" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
            </div>
            <div class="col-span-2 md:col-span-1">
               <div class="col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                  <div :class="!errors.cardExpiration ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                     <span>Expiration *</span>
                     <span v-if="errors.cardExpiration" class="text-theme-error">(required)</span>
                  </div>
                  <input v-model="paymentDetails.card.expiration" type="month" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
               </div>
            </div>
         </div>
         <div @click="handleBookingProceed" :class="isLoading ? 'cursor-not-allowed opacity-60' : 'hover:bg-white hover:text-theme-active cursor-pointer'" class="gap-4 mt-12 uppercase rounded-full px-8 py-4 border-2 border-theme-active bg-theme-active text-white text-size-2 flex items-center justify-center w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500">
            <span>Reserve</span>
            <span>
               <font-awesome-icon size="lg" icon="fa-solid fa-chevron-right" />
            </span>
         </div>
         <div v-if="errors.responseError" class="text-theme-error mt-4">
            {{ errors.responseError }}
         </div>
      </div>
   </div>
   <div v-else v-html="html" ref="formContainer" class="challenge-form mx-auto flex items-center justify-center py-12"></div>
   <div class="flex flex-col py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
      <h2 class="font-bold text-head-sm">Checkout API Response</h2>
      <div class="grid grid-cols-2 mt-12">
         <div class="col-span-1 min-h-96 border border-gray-300">
            <h3 class="text-center mt-2">Initiate Authentication Response</h3>
            <div class="overflow-auto">{{ initiateResponsee }}</div>
         </div>
         <div class="col-span-1 min-h-96 border-r border-b border-t border-gray-300">
            <h3 class="text-center mt-2">Authenticate Payer Response</h3>
            <div class="overflow-auto">{{ authenticatePayerResponsee }}</div>
         </div>
      </div>
   </div>  
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import { onMounted, ref, nextTick } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import RideDetails from '@/components/booking/RideDetails.vue'
import ChooseVehicle from '@/components/booking/ChooseVehicle.vue'
import ContactDetails from '@/components/booking/ContactDetails.vue'
import Finish from '@/components/booking/Finish.vue'
import { BookingStep, DriveType } from '@/types'
import { BOOKING_STEPS } from '@/utils/constants'
import { decodeFromQuery } from '@/services/search-service'
import { getBackendBaseUrl, isValidCardNumber, resetErrors, saveBookingNumber, savePaymentSession, savePaymentTransaction } from '@/utils/common'
import Payment from '@/composables/Payment'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'
import { useRouter } from 'vue-router'
import creditCardType from 'credit-card-type'
// @ts-ignore
import $ from 'jquery'

const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const { createSession, updateSession, createToken, initiateAuthentication, authenticatePayer } = Payment()
const paymentDetails = ref<any>({
   card: {
      name: '',
      number: '',
      cvc: '',
      expiration: ''
   },
   token: '',
   session: null,
   order: null,
   transaction: null
})
const errors = ref<any>({
   cardName: null,
   cardNumber: null,
   cardCVC: null,
   cardExpiration: null,
   responseError: null
})
const isLoading = ref<boolean>(false)
const initiateResponsee = ref<any>(null)
const authenticatePayerResponsee = ref<any>(null)
const html = ref<any>(null)
const formContainer = ref(null)
const router = useRouter()

const checkAndCreateSession = async () : Promise<{status: boolean, session: string | null}> => {

   const sessionResponse = await createSession()
   if (sessionResponse.status == 200) {
      savePaymentSession(sessionResponse.data.session)
      return {status: true, session: sessionResponse.data.session}
   } else {
      return {status: false, session: null}
   }
}

onMounted(async () => {

   const sessionResponse = await checkAndCreateSession()
   if (!sessionResponse.status) {
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: 'Payment cannot be processed at the moment'}
   } else {
      paymentDetails.value.session = sessionResponse.session
   }
})

const handleBookingProceed = async () => {
   
   resetErrors(errors.value)
   let isValid = validateForm()

   if (isValid) {

      const bookingNumber = 'FRT65r67YHUI'
      saveBookingNumber(bookingNumber)
      paymentDetails.value.card.brand = getCardBrand(paymentDetails.value.card.number.toString())
      paymentDetails.value.order = bookingNumber
      isLoading.value = true

      const tokenResponse = await createToken(paymentDetails.value)
      if (tokenResponse.status == 201) {
         const sessionResponse = await updateSession(paymentDetails.value.session, tokenResponse.data.token, bookingNumber as string)
         
         if (sessionResponse.status == 200) {
            paymentDetails.value.transaction = sessionResponse.data.transaction
            savePaymentTransaction(paymentDetails.value.transaction)
            //initiate3DSAuthentication(paymentDetails.value.order, paymentDetails.value.transaction)
            const initiateResponse = await initiateAuthentication(paymentDetails.value.session, bookingNumber, paymentDetails.value.transaction)
            initiateResponsee.value = JSON.stringify(initiateResponse.data.response)
            
            if (initiateResponse.data.response.transaction.authenticationStatus == 'AUTHENTICATION_AVAILABLE'){
               const authenticatePayerResponse = await authenticatePayer(paymentDetails.value.session, bookingNumber, `${paymentDetails.value.transaction}`)
               authenticatePayerResponsee.value = JSON.stringify(authenticatePayerResponse.data.response)
               const response = authenticatePayerResponse.data.response
               if (response && response.response && response.response.gatewayRecommendation == 'PROCEED') {
                  const action = response.authentication.redirect.customizedHtml['3ds2'].acsUrl
                  const inputValue = response.authentication.redirect.customizedHtml['3ds2'].cReq
                  html.value = `<form id="threedsChallengeRedirectForm" target="challengeFrame" action="${action}" method="post"><input type="hidden" name="creq" value="${inputValue}"><iframe id="challengeFrame" name="challengeFrame" width="100%" height="500" ></iframe>`
                  
                  await nextTick()
                  const form = document.getElementById('threedsChallengeRedirectForm') as HTMLFormElement | null
                  if (form) {
                     form.submit()
                  }
               } else {
                  console.log('payer authentication request finished but returned a different than expected response')
               }
            } else {
               console.log('initiate authentication request finished but returned a different than expected response')
            }
         } else {
            console.log(sessionResponse)
         }
      } else {
         console.log(tokenResponse)
      }
   }
}

const validateForm = () => {
   
   let response = true
   
   if (paymentDetails.value.card.name === '') {
      errors.value.cardName = true
      response = false
   }

   if (paymentDetails.value.card.number === '') {
      errors.value.cardNumber = 'Required'
      response = false
   } else if (!isValidCardNumber(paymentDetails.value.card.number)) {
      errors.value.cardNumber = 'Invalid'
      response = false
   }

   if (paymentDetails.value.card.cvc === '') {
      errors.value.cardCVC = true
      response = false
   }

   if (paymentDetails.value.card.expiration === '') {
      errors.value.cardExpiration = true
      response = false
   }

   return response
}

const getCardBrand = (cardNumber: string) => {
   const cardType = creditCardType(cardNumber);

   if (cardType.length > 0) {
      return cardType[0].niceType
   }

   return 'Unknown'
}

const notifClosed = () : void => {
   notif.value.show = false
}

window.addEventListener('message', function(event) {

   const backendUrl = getBackendBaseUrl()
   if (backendUrl.includes(event.origin)) {

      if (event.data === 'redirect') {
         router.push({name: 'BookingWizard', params:{step: 'finish'}})
      }
   }
})

</script>