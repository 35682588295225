import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-12 py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0" }
const _hoisted_2 = { class: "flex flex-col w-full lg:w-theme-auth mx-auto gap-5" }
const _hoisted_3 = { class: "flex flex-col gap-2" }
const _hoisted_4 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_5 = { class: "flex flex-row items-center gap-4" }

import { ref } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import Auth from '@/composables/Auth'
import { handleApiError } from '@/utils/common'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ForgetPassword',
  setup(__props) {

const email = ref<string>('')
const isLoading = ref<boolean>(false) 
const { forgetPassword }  = Auth()
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})

const handleResetPassword = async () => {

   if (email.value != '' && !isLoading.value) {

      isLoading.value = true
      const response = await forgetPassword(email.value)
      isLoading.value = false
      
      if (response.status == 200) {
         notif.value = { show: true, type: 'success', message: 'Email Sent', messageDetails: 'An email has been sent at your provided address'}
         resetForm()
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: error, messageDetails: ''}
         setTimeout(() => { notifClosed() }, 4000)
      }
   }
}

const resetForm = () :void => {
   email.value = ''
}

const notifClosed = () => {
   notif.value.show = false
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TopBanner, {
      layout: "full-width",
      currentPage: "Forget Password",
      heading: "Forget Password",
      bannerClass: "banner-auth"
    }),
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"flex flex-col md:flex-row items-center bg-theme-gray-7 px-12 py-10 gap-6\"><div class=\"relative bg-white rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center\"><span class=\"font-icon theme-icon info-icon text-theme-active absolute -top-[23px] -left-[13px] text-[47px]\"></span></div><div class=\"flex flex-col gap-3\"><h4 class=\"font-bold text-size-6 text-theme-gray-5\">Information</h4><p class=\"font-light text-size-6 text-theme-gray-4\">Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.</p></div></div>", 1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("label", {
            for: "login-username",
            class: "uppercase text-small text-theme-gray-4"
          }, "Username or email address *", -1)),
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
              type: "text",
              id: "login-username",
              class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
            }, null, 512), [
              [_vModelText, email.value]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            onClick: handleResetPassword,
            class: _normalizeClass([isLoading.value ? 'cursor-not-allowed opacity-40' : 'cursor-pointer hover:text-theme-active hover:bg-white', "order-1 md:order-2 gap-4 uppercase rounded-full w-full md:w-auto px-4 md:px-8 py-2 md:py-4 border-2 border-theme-active text-white bg-theme-active text-theme-gray-2 text-size-2 flex items-center justify-center ml-auto transition-all ease-in-out duration-500"])
          }, " Reset password ", 2)
        ])
      ])
    ]),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"])
  ], 64))
}
}

})