import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "hidden md:flex flex-col md:flex-row md:items-center md:space-x-3 divide-y divide-theme-gray-7 md:border-0 border-b border-theme-gray-7"
}
const _hoisted_2 = {
  key: 1,
  class: "hidden md:grid grid-cols-3 border-b-2 border-theme-active"
}
const _hoisted_3 = { class: "flex md:hidden items-center justify-between bg-theme-active text-white pr-5" }
const _hoisted_4 = { class: "flex flex-col divide-y divide-theme-gray-7 border-b border-theme-gray-7" }
const _hoisted_5 = { class: "flex flex-col bg-white z-50 w-full" }

import { DriveType } from '@/types';
import { ref } from 'vue'
import Modal from '@/widgets/Modal.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NavTabs',
  props: ['layout', 'driveType'],
  emits: ['driveType'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const drive = ref<any>(props.driveType)
const showMoreMenu = ref<boolean>(false)

const handleClick = (type : string) => {
   drive.value = type as DriveType
   emit('driveType', type)
   showMoreMenu.value = false
}

const handleRedirection = (type : string) => {
   //window.location = "booking/lead/meet_greet";
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.layout == 'simple')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (handleClick('distance'))),
            class: _normalizeClass([{'bg-theme-active text-white': drive.value === 'distance', 'bg-white hover:text-theme-active text-theme-gray-2': drive.value !== 'distance'}, "text-size-1 text-center p-5 uppercase cursor-pointer"])
          }, "Airport Transfer", 2),
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (handleClick('hourly'))),
            class: _normalizeClass([{'bg-theme-active text-white': drive.value === 'hourly', 'bg-white hover:text-theme-active text-theme-gray-2': drive.value !== 'hourly'}, "text-size-1 text-center p-5 uppercase cursor-pointer"])
          }, "Full Day Chauffeur", 2),
          _createElementVNode("div", {
            onClick: _cache[2] || (_cache[2] = () => {}),
            class: _normalizeClass([{'bg-theme-active text-white': drive.value === '', 'bg-white hover:text-theme-active text-theme-gray-2': drive.value !== ''}, "text-size-1 text-center p-5 uppercase cursor-pointer"])
          }, _cache[17] || (_cache[17] = [
            _createElementVNode("a", {
              href: "https://carsirent.com",
              target: "_blank"
            }, "Car Rental", -1)
          ]), 2),
          _createElementVNode("div", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({name: 'MeetGreet'}))),
            class: _normalizeClass([{'bg-theme-active text-white': drive.value === '', 'bg-white hover:text-theme-active text-theme-gray-2': drive.value !== ''}, "text-size-1 text-center p-5 uppercase cursor-pointer"])
          }, "Meet & Greet", 2),
          _createElementVNode("div", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push({name: 'BusesCoaches'}))),
            class: _normalizeClass([{'bg-theme-active text-white': drive.value === '', 'bg-white hover:text-theme-active text-theme-gray-2': drive.value !== ''}, "text-size-1 text-center p-5 uppercase cursor-pointer"])
          }, "Buses & Coaches", 2),
          _createElementVNode("div", {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push({name: 'Planmytrip'}))),
            class: _normalizeClass([{'bg-theme-active text-white': drive.value === '', 'bg-white hover:text-theme-active text-theme-gray-2': drive.value !== ''}, "text-size-1 text-center p-5 uppercase cursor-pointer"])
          }, "Multiple Requests", 2)
        ]))
      : (__props.layout == 'full')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", {
              onClick: _cache[6] || (_cache[6] = ($event: any) => (handleClick('distance'))),
              class: _normalizeClass([{'bg-theme-active text-white': drive.value === 'distance', 'bg-white hover:text-theme-active text-theme-gray-2 border border-theme-gray-7': drive.value !== 'distance'}, "col-span-3 md:col-span-1 text-size-1 text-center p-5 uppercase cursor-pointer"])
            }, "Airport Transfer", 2),
            _createElementVNode("div", {
              onClick: _cache[7] || (_cache[7] = ($event: any) => (handleClick('hourly'))),
              class: _normalizeClass([{'bg-theme-active text-white': drive.value === 'hourly', 'bg-white hover:text-theme-active text-theme-gray-2 border border-theme-gray-7': drive.value !== 'hourly'}, "col-span-3 md:col-span-1 text-size-1 text-center p-5 uppercase cursor-pointer"])
            }, "Full Day Chauffeur", 2)
          ]))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          onClick: _cache[8] || (_cache[8] = ($event: any) => (handleClick(drive.value))),
          class: "text-size-1 text-center p-5 uppercase cursor-pointer"
        }, _toDisplayString(drive.value == 'hourly' ? 'Full Day Chauffeur' : 'Airport Transfer'), 1)
      ]),
      _createElementVNode("button", {
        onClick: _cache[9] || (_cache[9] = ($event: any) => (showMoreMenu.value = true)),
        class: "text-white uppercase text-xs"
      }, "More"),
      _createVNode(Modal, {
        show: showMoreMenu.value,
        onClosed: _cache[16] || (_cache[16] = ($event: any) => (showMoreMenu.value = false))
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              onClick: _cache[10] || (_cache[10] = ($event: any) => (handleClick('distance'))),
              class: _normalizeClass([{'bg-theme-active text-white': drive.value === 'distance', 'bg-white hover:text-theme-active text-theme-gray-2': drive.value !== 'distance'}, "text-size-1 text-center p-5 uppercase cursor-pointer"])
            }, "Airport Transfer", 2),
            _createElementVNode("div", {
              onClick: _cache[11] || (_cache[11] = ($event: any) => (handleClick('hourly'))),
              class: _normalizeClass([{'bg-theme-active text-white': drive.value === 'hourly', 'bg-white hover:text-theme-active text-theme-gray-2': drive.value !== 'hourly'}, "text-size-1 text-center p-5 uppercase cursor-pointer"])
            }, "Full Day Chauffeur", 2),
            _createElementVNode("div", {
              onClick: _cache[12] || (_cache[12] = () => {}),
              class: _normalizeClass([{'bg-theme-active text-white': drive.value === '', 'bg-white hover:text-theme-active text-theme-gray-2': drive.value !== ''}, "text-size-1 text-center p-5 uppercase cursor-pointer"])
            }, _cache[18] || (_cache[18] = [
              _createElementVNode("a", {
                href: "https://carsirent.com",
                target: "_blank"
              }, "Car Rental", -1)
            ]), 2),
            _createElementVNode("div", {
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$router.push({name: 'MeetGreet'}))),
              class: _normalizeClass([{'bg-theme-active text-white': drive.value === '', 'bg-white hover:text-theme-active text-theme-gray-2': drive.value !== ''}, "text-size-1 text-center p-5 uppercase cursor-pointer"])
            }, "Meet & Greet", 2),
            _createElementVNode("div", {
              onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.$router.push({name: 'BusesCoaches'}))),
              class: _normalizeClass([{'bg-theme-active text-white': drive.value === '', 'bg-white hover:text-theme-active text-theme-gray-2': drive.value !== ''}, "text-size-1 text-center p-5 uppercase cursor-pointer"])
            }, "Buses & Coaches", 2),
            _createElementVNode("div", {
              onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.$router.push({name: 'Planmytrip'}))),
              class: _normalizeClass([{'bg-theme-active text-white': drive.value === '', 'bg-white hover:text-theme-active text-theme-gray-2': drive.value !== ''}, "text-size-1 text-center p-5 uppercase cursor-pointer"])
            }, "Multiple Requests", 2)
          ])
        ]),
        _: 1
      }, 8, ["show"])
    ])
  ], 64))
}
}

})