import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 gap-4 w-full my-12" }
const _hoisted_2 = { class: "col-span-1 flex flex-row items-end" }
const _hoisted_3 = { class: "flex flex-col items-end ml-auto" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "bg-gray-400 pt-1 pb-4 px-3 border-t-2 border-black" }
const _hoisted_6 = { class: "flex flex-row items-start justify-between" }
const _hoisted_7 = { class: "flex flex-col items-start" }
const _hoisted_8 = { class: "flex flex-col items-center" }
const _hoisted_9 = { class: "flex flex-row items-start justify-between px-3" }
const _hoisted_10 = { class: "flex flex-col" }
const _hoisted_11 = {
  key: 0,
  class: "flex flex-col items-start"
}
const _hoisted_12 = { class: "flex flex-col" }
const _hoisted_13 = {
  key: 0,
  class: "flex flex-col items-end"
}
const _hoisted_14 = {
  key: 0,
  class: "flex flex-row items-start justify-between px-3"
}
const _hoisted_15 = { class: "flex flex-col" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { class: "flex flex-col border-t-2 border-black gap-3" }
const _hoisted_18 = { class: "flex flex-row items-start bg-gray-400 px-3 py-1" }
const _hoisted_19 = { class: "w-1/3 text-right" }
const _hoisted_20 = { class: "font-bold" }
const _hoisted_21 = { class: "flex flex-row items-start" }
const _hoisted_22 = { class: "w-1/3 text-left" }
const _hoisted_23 = { class: "font-normal" }
const _hoisted_24 = { class: "w-1/3 text-center" }
const _hoisted_25 = { class: "font-normal" }
const _hoisted_26 = { class: "w-1/3 text-right" }
const _hoisted_27 = { class: "font-normal" }
const _hoisted_28 = { class: "flex flex-col gap-1 border-t-2 border-black mt-4" }
const _hoisted_29 = { class: "flex flex-row items-center justify-end gap-4 mt-2" }
const _hoisted_30 = { class: "flex flex-row items-center justify-end gap-4" }

import { ref } from 'vue'
import { formattedDateTime } from '@/utils/common'


export default /*@__PURE__*/_defineComponent({
  __name: 'Invoice',
  props: ['data'],
  setup(__props) {

const props = __props

console.log(props.data)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        class: "w-[235px]",
        src: "/assets/logo.png",
        alt: ""
      }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", null, "312 Falcon Tower, Displomatic Area", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("div", null, "Bahrain - Manama", -1)),
        (__props.data.refNumber)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Reference Number: " + _toDisplayString(__props.data.refNumber), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "font-bold" }, "Order ID", -1)),
          _createElementVNode("div", null, _toDisplayString(__props.data.number), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "font-bold" }, "Date", -1)),
          _createElementVNode("div", null, _toDisplayString(_unref(formattedDateTime)(__props.data.createdAt)), 1)
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex flex-col items-end" }, [
          _createElementVNode("div", { class: "font-bold" }, "Currency"),
          _createElementVNode("div", null, "USD")
        ], -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "font-bold mb-3" }, "Invoice To:", -1)),
        (__props.data.bookedBy)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", null, _toDisplayString(__props.data.bookedBy.name), 1),
              _createElementVNode("div", null, _toDisplayString(__props.data.bookedBy.email), 1),
              _createElementVNode("div", null, _toDisplayString(__props.data.bookedBy.phone), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "font-bold mb-3 text-right" }, "Client Details:", -1)),
        (__props.data.contact)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("div", null, _toDisplayString(__props.data.contact.name), 1),
              _createElementVNode("div", null, _toDisplayString(__props.data.contact.email), 1),
              _createElementVNode("div", null, _toDisplayString(__props.data.contact.phone), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (__props.data.comments)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "font-bold mb-3" }, "Comments:", -1)),
            _createElementVNode("div", {
              class: "flex flex-col items-start",
              innerHTML: __props.data.comments
            }, null, 8, _hoisted_16)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "w-1/3 text-left" }, [
          _createElementVNode("div", { class: "font-bold" }, "Date & Time")
        ], -1)),
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "w-1/3 text-center" }, [
          _createElementVNode("div", { class: "font-bold" }, "Pick up")
        ], -1)),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, _toDisplayString(__props.data.search.type == 'hourly' ? 'Hours' : 'Drop off'), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, _toDisplayString(__props.data.search.pickUp.date + ' ' + __props.data.search.pickUp.time), 1)
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, _toDisplayString(__props.data.search.pickUp.location.name), 1)
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, _toDisplayString(__props.data.search.type == 'hourly' ? __props.data.search.hours + ' Hrs' : __props.data.search.dropOff.location.name), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_28, [
      _createElementVNode("div", _hoisted_29, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "font-bold" }, "Subtotal (VAT inclusive)", -1)),
        _createElementVNode("div", null, _toDisplayString(__props.data.totalAmount) + " USD", 1)
      ]),
      _createElementVNode("div", _hoisted_30, [
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "font-bold" }, "Total", -1)),
        _createElementVNode("div", null, _toDisplayString(__props.data.totalAmount) + " USD", 1)
      ])
    ]),
    _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"col-span-1 flex flex-col gap-4 pt-16\"><div class=\"grid grid-cols-2\"><div class=\"flex flex-col gap-4 col-span-2\"><h2 class=\"font-bold\">Bank Transfer Details (For Wire Transfer)</h2><div class=\"flex flex-col gap-1\"><div class=\"flex flex-row items-center gap-2\"><div class=\"font-bold\">Account Name:</div><div>Vehitra Tech WLL</div></div><div class=\"flex flex-row items-center gap-2\"><div class=\"font-bold\">Bank name:</div><div>AHLI UNITED BANK</div></div><div class=\"flex flex-row items-center gap-2\"><div class=\"font-bold\">Branch Location:</div><div>Seef Mall Manama Bahrain</div></div><div class=\"flex flex-row items-center gap-2\"><div class=\"font-bold\">IBAN:</div><div>BH14 AUBB 0001 0578 8900 01</div></div><div class=\"flex flex-row items-center gap-2\"><div class=\"font-bold\">Account Number:</div><div>0010-578890-001</div></div><div class=\"flex flex-row items-center gap-2\"><div class=\"font-bold\">Swift:</div><div>AUB BB HBM</div></div></div></div></div></div>", 1))
  ]))
}
}

})