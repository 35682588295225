import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full" }
const _hoisted_2 = { class: "py-10 bg-gray-50 flex-grow" }
const _hoisted_3 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_4 = { class: "flex flex-col gap-12 py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0" }
const _hoisted_5 = { class: "flex flex-col w-full mx-auto gap-5" }
const _hoisted_6 = { class: "flex flex-col gap-2" }
const _hoisted_7 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_8 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_9 = { class: "flex flex-col gap-2" }
const _hoisted_10 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_11 = {
  key: 0,
  class: "text-ef-error"
}
const _hoisted_12 = {
  key: 0,
  class: "text-ef-error"
}

import { onMounted, ref } from 'vue'
import TopBar from '@/components/account/TopBar.vue'
import SideBar from '@/components/account/SideBar.vue'
import { checkUserPasswordSetup, handleApiError, updateAuthUser, resetErrors } from '@/utils/common'
import Modal from '@/widgets/Modal.vue'
import Auth from '@/composables/Auth'


export default /*@__PURE__*/_defineComponent({
  __name: 'Account',
  setup(__props) {

const sidebarOpen = ref<boolean>(false)
const openModal = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const error = ref<string>('')
const { setupPassword }  = Auth()
const form = ref<any>({
   password: { value: '', error: false, errorMessage: '' },
   passwordConfirmation: { value: '', error: false, errorMessage: '' }
})

const handleSideBarOpen = () => {
   sidebarOpen.value = true
}

const updatePassword = async () => {

   const isValid = validateForm()
   if (isValid) {

      isLoading.value = true
      const response = await setupPassword(form.value.password.value as string, form.value.passwordConfirmation.value as string)
      isLoading.value = false

      if (response.status == 200) {
         updateAuthUser(response.data.user)
         openModal.value = false
      } else {
         error.value = handleApiError(response)
      }
   }
}

const validateForm = () : boolean => {

   let response = true
   form.value.password.error = false
   form.value.passwordConfirmation.error = false

   if (form.value.password.value == '') {
      form.value.password.error = true
      form.value.password.errorMessage = 'Required'
      response = false
   }

   if (form.value.passwordConfirmation.value == '') {
      form.value.passwordConfirmation.error = true
      form.value.passwordConfirmation.errorMessage = 'Required'
      response = false
   }
   
   if (form.value.password.value != '' && form.value.password.value.length < 6) {
      form.value.password.error = true
      form.value.password.errorMessage = 'Must be at least 6 characters long'
      response = false
   } else if (form.value.password.value != '' && form.value.passwordConfirmation.value != '' && form.value.password.value != form.value.passwordConfirmation.value) {
      form.value.passwordConfirmation.error = true
      form.value.passwordConfirmation.errorMessage = 'Password confirmation is not the same as password'
      response = false
   }

   return response
}

onMounted(() => {

   const hasSetupPassword = checkUserPasswordSetup()

   if (hasSetupPassword === 0) {
      openModal.value = true
   }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(TopBar, { onOpenSidebar: handleSideBarOpen }),
      _createElementVNode("main", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "content")
        ])
      ])
    ]),
    _createVNode(Modal, {
      show: openModal.value,
      canClose: false
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[2] || (_cache[2] = _createElementVNode("label", {
                for: "reset-password",
                class: "uppercase text-small text-theme-gray-4"
              }, "New password *", -1)),
              _createElementVNode("div", _hoisted_7, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.password.value) = $event)),
                  type: "password",
                  class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
                }, null, 512), [
                  [_vModelText, form.value.password.value]
                ])
              ]),
              (form.value.password.error)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(form.value.password.errorMessage), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[3] || (_cache[3] = _createElementVNode("label", {
                for: "reset-password-repeat",
                class: "uppercase text-small text-theme-gray-4"
              }, "Repeat password *", -1)),
              _createElementVNode("div", _hoisted_10, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.passwordConfirmation.value) = $event)),
                  type: "password",
                  class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
                }, null, 512), [
                  [_vModelText, form.value.passwordConfirmation.value]
                ])
              ]),
              (form.value.passwordConfirmation.error)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(form.value.passwordConfirmation.errorMessage), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", { class: "flex flex-row items-center gap-4" }, [
              _createElementVNode("div", {
                onClick: updatePassword,
                class: "gap-4 uppercase rounded-full px-4 w-full md:w-auto md:px-8 py-2 md:py-4 border-2 border-theme-active text-white bg-theme-active hover:text-theme-active hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer ml-auto transition-all ease-in-out duration-500"
              }, " Update password ")
            ]),
            (error.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(error.value), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}
}

})