<template>
   <div class="w-full mb-5">
      <label for="name" :class="!errors.name ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium">Name</label>
      <input v-model="subAgent.name" type="text" :class="!errors.name ? 'border-gray-300' : 'border-theme-error'" class="mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
   </div>
   <div v-if="data == null" class="w-full mb-5">
      <label for="name" :class="!errors.email ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium">Email</label>
      <input v-model="subAgent.email" type="text" :class="!errors.email ? 'border-gray-300' : 'border-theme-error'" class="mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
   </div>
   <div class="w-full mb-5">
      <label for="name" :class="!errors.phone ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium">Phone Number</label>
      <input v-model="subAgent.phone" type="text" :class="!errors.phone ? 'border-gray-300' : 'border-theme-error'" class="mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
   </div>
   <div class="w-full mb-5">
      <label for="name" :class="!errors.commission ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium">Commission (in %)</label>
      <input v-model="subAgent.commission" type="number" :class="!errors.commission ? 'border-gray-300' : 'border-theme-error'" class="mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
   </div>
   <div class="flex">
      <button @click="handleSubmit()" type="button" :class="isLoading ? 'cursor-not-allowed opacity-70' : 'hover:bg-indigo-700'" class="ml-auto mt-20 bg-indigo-600 py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white outline-none">
         Save
      </button>
   </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import Agent from '@/composables/admin/agent'
import { isValidEmail, resetErrors, handleApiError, isLoggedIn } from '@/utils/common'
import { Agent as AgentType } from '@/types/admin'

const props = defineProps(['data'])
const emit = defineEmits(['success', 'error'])
const isLoading = ref<boolean>(false)
const { createSubAgent, updateSubAgent } = Agent()
const authUser = isLoggedIn()
const subAgent = ref<AgentType>({
   id: '',
   name: '',
   email: '',
   commission: authUser.user.commission,
   address: '',
   phone: '',
   isParent: false
})
const errors = ref<any>({
   name: false,
   email: false,
   phone: false,
   commission: false
})

if (props.data) {
   subAgent.value = props.data
}

const validateForm = () : boolean => {

   let response = true

   if (subAgent.value.name == '') {
      response = false
      errors.value.name = true
   }

   if (subAgent.value.email == '' || !isValidEmail(subAgent.value.email)) {
      response = false
      errors.value.email = true
   }

   if (subAgent.value.phone == '') {
      response = false
      errors.value.phone = true
   }

   if (subAgent.value.commission == 0) {
      response = false
      errors.value.commission = true
   }

   return response 
}

const handleSubmit = async () => {

   resetErrors(errors.value)
   const isValid = validateForm()

   if (isValid && !isLoading.value) {
      
      isLoading.value = true

      if (!props.data) {

         const response = await createSubAgent(subAgent.value)
         if (response.status == 201) {
            emit('success')
         } else {
            const error = handleApiError(response)
            emit('error', error)
         }
      } else {

         const response = await updateSubAgent(subAgent.value)
         if (response.status == 200) {
            emit('success')
         } else {
            const error = handleApiError(response)
            emit('error', error)
         }
      }

      isLoading.value = false
   }
}

</script>