import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "sm:flex sm:items-center" }
const _hoisted_3 = { class: "mt-4 sm:ml-16 sm:mt-0 sm:flex-none" }
const _hoisted_4 = { class: "mt-8 flow-root" }
const _hoisted_5 = { class: "-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_6 = { class: "inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8" }
const _hoisted_7 = { class: "overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg" }
const _hoisted_8 = { class: "min-w-full divide-y divide-gray-300" }
const _hoisted_9 = { class: "divide-y divide-gray-200 bg-white" }
const _hoisted_10 = { class: "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" }
const _hoisted_11 = { class: "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" }
const _hoisted_12 = { class: "inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10" }
const _hoisted_13 = { class: "whitespace-nowrap px-3 py-4 text-sm text-gray-500" }
const _hoisted_14 = { class: "whitespace-nowrap px-3 py-4 text-sm text-gray-500" }

import { ref, onMounted } from 'vue'
import { Locality, NotificationType } from '@/types/index'
import Admin from '@/composables/admin/index'
import { handleApiError } from '@/utils/common'
import SlideOver from '@/widgets/SlideOver.vue'
import SaveLocation from '@/components/admin/SaveLocation.vue'
import Notification from '@/widgets/Notification.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const locations = ref<Locality[] | null>(null)
const { allLocations } = Admin()
const isLoading = ref<boolean>(false)
const openSaveDialog = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const locationType = ref<'chauffer' | 'transfer'>('chauffer')

onMounted(async () => {

   await fetchLocations()  
})

const fetchLocations = async () => {
   isLoading.value = true
   const response = await allLocations(locationType.value)
   isLoading.value = false
   if (response.status == 200) {
      
      locations.value = response.data
   } else {
      const error = handleApiError(response)
      console.log(error)
   }
}

const handleAddCar = () => {
   openSaveDialog.value = true
}

const handleSaveSuccess = async () => {
   openSaveDialog.value = false
   notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Your car has been added successfully'}
   setTimeout(() => { notifClosed() }, 4000)
   
   await fetchLocations()
}

const handleSaveError = (error: string) => {
   notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
   setTimeout(() => { notifClosed() }, 4000)
}

const notifClosed = () : void => {
   notif.value.show = false
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "sm:flex-auto" }, [
          _createElementVNode("h1", { class: "text-base font-semibold leading-6 text-gray-900" }, "Locations"),
          _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "A list of all car locations added in our database")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (handleAddCar())),
            type: "button",
            class: "block rounded-md bg-theme-active px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }, " Add Location ")
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("table", _hoisted_8, [
                _cache[4] || (_cache[4] = _createElementVNode("thead", { class: "bg-gray-50" }, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", {
                      scope: "col",
                      class: "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    }, "Name"),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    }, "Type"),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    }, "Country"),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    }, "Bounds"),
                    _createElementVNode("th", {
                      scope: "col",
                      class: "relative py-3.5 pl-3 pr-4 sm:pr-6"
                    }, [
                      _createElementVNode("span", { class: "sr-only" }, "Edit")
                    ])
                  ])
                ], -1)),
                _createElementVNode("tbody", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locations.value, (location, index) => {
                    return (_openBlock(), _createElementBlock("tr", { key: index }, [
                      _createElementVNode("td", _hoisted_10, _toDisplayString(location.name), 1),
                      _createElementVNode("td", _hoisted_11, [
                        _createElementVNode("span", _hoisted_12, _toDisplayString(location.type), 1)
                      ]),
                      _createElementVNode("td", _hoisted_13, _toDisplayString(location.country), 1),
                      _createElementVNode("td", _hoisted_14, _toDisplayString(location.bounds), 1),
                      _cache[3] || (_cache[3] = _createElementVNode("td", { class: "relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6" }, [
                        _createElementVNode("a", {
                          href: "#",
                          class: "text-indigo-600 hover:text-indigo-900"
                        }, "Delete")
                      ], -1))
                    ]))
                  }), 128))
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(SlideOver, {
      show: openSaveDialog.value,
      onClosed: _cache[1] || (_cache[1] = ($event: any) => (openSaveDialog.value = false))
    }, {
      content: _withCtx(() => [
        _createVNode(SaveLocation, {
          mode: "add",
          onSuccess: handleSaveSuccess,
          onError: handleSaveError
        })
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"])
  ], 64))
}
}

})