import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "flex flex-col space-y-1" }
const _hoisted_3 = {
  type: "button",
  class: "font-bold text-theme-gray-5 text-size-6 border-0 outline-none"
}
const _hoisted_4 = {
  key: 0,
  class: "z-10 max-h-[200px] overflow-y-auto origin-top-right absolute right-0 mt-4 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "relative" }

import { ref, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Dropdown',
  props: ['data', 'name'],
  emits: ['item'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const isDropOpen = ref<boolean>(false)
const selectedItem = ref<any>(props.data.length ? props.data[0] : '')

watch(selectedItem, (newValue, oldValue) => {
  emit('item', selectedItem, props.name)
})

const toggleDropdown = () => {
   isDropOpen.value = !isDropOpen.value;
}


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "inline-block text-left cursor-pointer",
        onClick: toggleDropdown
      }, [
        _createElementVNode("button", _hoisted_3, _toDisplayString(selectedItem.value.title), 1),
        (isDropOpen.value)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.data, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  onClick: ($event: any) => (selectedItem.value = item),
                  class: "py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer"
                }, _toDisplayString(item.title), 9, _hoisted_5))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_font_awesome_icon, {
            size: "lg",
            icon: "fa-solid fa-chevron-down",
            class: "text-theme-gray-2 absolute right-0 bottom-1"
          })
        ])
      ])
    ])
  ]))
}
}

})