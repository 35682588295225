import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "inline-flex divide-x divide-theme-active rounded-md shadow-sm" }
const _hoisted_3 = { class: "inline-flex items-center gap-x-1.5 rounded-l-md bg-theme-active px-3 py-2 text-white shadow-sm" }
const _hoisted_4 = { class: "text-sm font-semibold" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "flex justify-between" }

import { ref } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ListBox',
  props: ['data', 'selected', 'name'],
  emits: ['selected'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

const options = props.data
const selected = ref(props.selected ? props.selected : options[0])

const handleOptionSelect = (option: any) => {
   emit('selected', props.name, option.value)
}


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_unref(Listbox), {
    as: "div",
    modelValue: selected.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selected).value = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(ListboxLabel), { class: "sr-only" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Change option")
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_font_awesome_icon, {
              size: "1x",
              icon: "fa-solid fa-check -ml-0.5"
            }),
            _createElementVNode("p", _hoisted_4, _toDisplayString(selected.value.title), 1)
          ]),
          _createVNode(_unref(ListboxButton), { class: "inline-flex items-center rounded-l-none rounded-r-md bg-theme-active p-2 outline-none" }, {
            default: _withCtx(() => [
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sr-only" }, "Change option", -1)),
              _createVNode(_component_font_awesome_icon, {
                size: "1x",
                icon: "fa-solid fa-chevron-down",
                class: "text-white"
              })
            ]),
            _: 1
          })
        ]),
        _createVNode(_Transition, {
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(ListboxOptions), { class: "absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(options), (option) => {
                  return (_openBlock(), _createBlock(_unref(ListboxOption), {
                    onClick: ($event: any) => (handleOptionSelect(option)),
                    as: "template",
                    key: option.title,
                    value: option
                  }, {
                    default: _withCtx(({ active, selected }) => [
                      _createElementVNode("li", {
                        class: _normalizeClass([active ? 'bg-theme-active text-white' : 'text-gray-900', 'cursor-pointer select-none p-4 text-sm'])
                      }, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("p", {
                              class: _normalizeClass(selected ? 'font-semibold' : 'font-normal')
                            }, _toDisplayString(option.title), 3),
                            selected
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  class: _normalizeClass(active ? 'text-white' : 'text-theme-active')
                                }, [
                                  _createVNode(_component_font_awesome_icon, {
                                    size: "lg",
                                    icon: "fa-solid fa-check",
                                    class: _normalizeClass(active ? 'text-white' : 'text-theme-active')
                                  }, null, 8, ["class"])
                                ], 2))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("p", {
                            class: _normalizeClass([active ? 'text-theme-active' : 'text-gray-500', 'mt-2'])
                          }, _toDisplayString(option.description), 3)
                        ])
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["onClick", "value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})