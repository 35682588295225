import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col md:flex-row items-stretch gap-12 md:gap-8" }
const _hoisted_2 = { class: "flex-1 flex flex-col w-full" }
const _hoisted_3 = { class: "flex flex-col flex-1" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "flex-grow border-b border-l border-r border-theme-gray-7 grid grid-cols-2 divide-x divide-theme-gray-7 gap-4 md:gap-0 py-4" }
const _hoisted_6 = {
  key: 0,
  class: "col-span-2 md:col-span-1 flex flex-col md:flex-row items-center justify-center gap-2 md:gap-6"
}
const _hoisted_7 = { class: "flex flex-col items-start" }
const _hoisted_8 = { class: "flex flex-row theme-gray-5 text-head-md-3" }
const _hoisted_9 = {
  key: 1,
  class: "col-span-2 md:col-span-1 flex flex-col md:flex-row items-center justify-center gap-2 md:gap-6"
}
const _hoisted_10 = { class: "flex flex-col items-start" }
const _hoisted_11 = { class: "flex flex-row theme-gray-5 text-head-md-3" }
const _hoisted_12 = {
  key: 2,
  class: "col-span-2 flex flex-col md:flex-row items-center ml-8 gap-2 md:gap-6"
}
const _hoisted_13 = { class: "flex flex-col items-start" }
const _hoisted_14 = { class: "flex flex-row theme-gray-5 text-head-md-3" }
const _hoisted_15 = { class: "md:ml-auto mr-2" }

import { computed, onMounted, ref, watch } from 'vue'
import { Locality, LocationSchedule } from '@/types/index'
import { disableScroll, enableScroll } from '@/utils/common'
import Search from '@/components/Search.vue'
import GoogleMap from '@/components/GoogleMap.vue'
import { decodeFromQuery, saveUserTempSearch } from '@/services/search-service'
import Loader from '@/widgets/Loader.vue'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'RideDetails',
  props: ['data'],
  setup(__props) {

const props = __props
const origin = ref(null)
const destination = ref(null)
const distance = ref<string>('0 km')
const time = ref<string>('0h 0m')
const proceed = ref<boolean>(false)
const matchedLocation = ref<any>(null)
const router = useRouter()
const showLoader = ref<boolean>(false)

const handlePickUpChange = (value: LocationSchedule) => {
   origin.value = value.location.id as any
}

const handleDropOffChange = (value: LocationSchedule) => {
   destination.value = value.location.id as any
}

const handleMapData = (value: string, type: string) => {
   if (type == 'distance') {

      distance.value = value
   } else if(type == 'time') {

      time.value = value
   }
}

const handleMatchedLocation = (match: any) => {

   matchedLocation.value = match != 'not-found' ? match : { name: props.data.pickUp.location.name }
}

const handleBookingProceed = () => {
   proceed.value = true
}

const handleEditSearch = () => {
   const queryString = window.location.search.substring(1)
   const search = decodeFromQuery(queryString)
   saveUserTempSearch(search)
   router.push({name: 'Home'})
}

onMounted(() => {

   showLoader.value = true
   disableScroll()

   setTimeout(async () => {
      
      showLoader.value = false
      enableScroll()
   }, 2000)
})


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(Search, {
          layout: "full",
          type: __props.data.driveType,
          data: __props.data,
          proceed: proceed.value,
          onProceedInitiated: _cache[0] || (_cache[0] = ($event: any) => (proceed.value = false)),
          onPickUp: handlePickUpChange,
          onDropOff: handleDropOffChange
        }, null, 8, ["type", "data", "proceed"]),
        _createElementVNode("div", {
          onClick: handleEditSearch,
          class: "md:ml-auto hover:bg-white hover:text-theme-active text-center border-2 border-theme-active bg-theme-active text-white gap-3 md:w-fit md:ml-auto mt-12 py-3 px-7 transition ease-in-out duration-300 cursor-pointer rounded-full"
        }, "Edit Search")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(GoogleMap, {
            origin: origin.value,
            destination: destination.value,
            driveType: __props.data.driveType,
            onMatch: handleMatchedLocation,
            onDistance: handleMapData,
            onTime: handleMapData
          }, null, 8, ["origin", "destination", "driveType"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (__props.data.driveType == 'distance')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _cache[2] || (_cache[2] = _createElementVNode("div", null, [
                  _createElementVNode("span", { class: "font-icon theme-icon dual-maps-icon text-theme-active text-[48px]" })
                ], -1)),
                _createElementVNode("div", _hoisted_7, [
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "uppercase theme-gray-2 font-light text-small" }, "Total Distance", -1)),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(distance.value), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (__props.data.driveType == 'distance')
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _cache[4] || (_cache[4] = _createElementVNode("div", null, [
                  _createElementVNode("span", { class: "font-icon theme-icon clock-icon text-theme-active text-[48px]" })
                ], -1)),
                _createElementVNode("div", _hoisted_10, [
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "uppercase theme-gray-2 font-light text-small" }, "Total Time", -1)),
                  _createElementVNode("div", _hoisted_11, _toDisplayString(time.value), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (__props.data.driveType == 'hourly')
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _cache[6] || (_cache[6] = _createElementVNode("div", null, [
                  _createElementVNode("span", { class: "font-icon theme-icon map-search-icon text-theme-active text-[48px]" })
                ], -1)),
                _createElementVNode("div", _hoisted_13, [
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "uppercase theme-gray-2 font-light text-small" }, "Search Scope", -1)),
                  _createElementVNode("div", _hoisted_14, _toDisplayString(matchedLocation.value ? matchedLocation.value.name: ''), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", {
      onClick: handleBookingProceed,
      class: "flex flex-row items-center rounded-full justify-center cursor-pointer hover:bg-white hover:text-theme-active border-2 border-theme-active bg-theme-active text-white gap-3 md:w-fit md:ml-auto mt-12 py-3 px-7 transition ease-in-out duration-300"
    }, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "uppercase" }, "Choose a vehicle", -1)),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_font_awesome_icon, {
          size: "sm",
          icon: "fa-solid fa-chevron-right"
        })
      ])
    ]),
    _createVNode(Loader, { show: showLoader.value }, null, 8, ["show"])
  ], 64))
}
}

})