import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0" }
const _hoisted_2 = {
  key: 0,
  class: "hidden md:grid grid-cols-3 booking-steps"
}
const _hoisted_3 = {
  key: 0,
  class: "border-b border-theme-gray-7 absolute top-[30%] left-1/2 w-full"
}
const _hoisted_4 = { class: "flex flex-col items-center gap-3" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_8 = {
  key: 1,
  class: "md:hidden relative"
}
const _hoisted_9 = { class: "border border-theme-gray-7 px-3 py-5 font-bold" }
const _hoisted_10 = {
  key: 0,
  class: "z-10 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5"
}
const _hoisted_11 = {
  key: 2,
  class: "flex flex-col mt-12"
}
const _hoisted_12 = {
  key: 3,
  class: "flex flex-col mt-12"
}
const _hoisted_13 = {
  key: 4,
  class: "flex flex-col mt-12"
}
const _hoisted_14 = {
  key: 5,
  class: "flex flex-col mt-12"
}

import { ref, watch } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import RideDetails from '@/components/booking/RideDetails.vue'
import ChooseVehicle from '@/components/booking/ChooseVehicle.vue'
import ContactDetails from '@/components/booking/ContactDetails-back.vue'
import Finish from '@/components/booking/Finish.vue'
import { BookingStep, DriveType } from '@/types'
import { BOOKING_STEPS } from '@/utils/constants'
import { decodeFromQuery } from '@/services/search-service'


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingWizard',
  props: ['step'],
  setup(__props) {

const props = __props
const isStepsDropOpen = ref<boolean>(false)
const steps = ref<BookingStep[]>(BOOKING_STEPS)
const currentStep = ref<BookingStep | null>(null)
const searchData = ref<any>(null)

const toggleStepsDrop = () => {
   isStepsDropOpen.value = !isStepsDropOpen.value
}

const handleStepState = () => {
   steps.value.forEach((step : BookingStep) => {
      step.isActive = false
      if (step.uri === props.step) {
         step.isActive = true
         currentStep.value = step
         const rSteps = steps.value.filter((item: BookingStep) => item.id !== step.id)
         rSteps.forEach((rStep : BookingStep) => {
            if (rStep.id < step.id) {
               rStep.isCompleted = true
            }
         })
      }
   })

   if (props.step == 'ride-details') {
      
      const queryString = window.location.search.substring(1)
      searchData.value = decodeFromQuery(queryString)
   }
}

watch(() => props.step, (newVallue, oldValue) => {
   handleStepState()
})

handleStepState()


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TopBanner, {
      layout: "normal",
      currentPage: "Booking",
      heading: "Booking",
      bannerClass: "banner-booking"
    }),
    _createElementVNode("div", _hoisted_1, [
      (props.step !== 'finish')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(steps.value, (step, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "relative col-span-1"
              }, [
                (index + 1 != steps.value.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["z-10 min-w-[50px] min-h-[50px] font-bold text-size-6 p-3 text-center rounded-full", step.isActive ? 'bg-theme-active text-white' : 'text-theme-gray-2 bg-theme-gray-7'])
                  }, [
                    (step.isActive || !step.isCompleted)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(index+1), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                          _createVNode(_component_font_awesome_icon, {
                            size: "lg",
                            icon: "fa-solid fa-check",
                            class: "text-theme-active"
                          })
                        ]))
                  ], 2),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(step.title), 1)
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (props.step !== 'finish')
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", {
              class: "flex flex-col",
              onClick: toggleStepsDrop
            }, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(currentStep.value?.title), 1),
              (isStepsDropOpen.value)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(steps.value, (step, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: index,
                        class: "py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer"
                      }, _toDisplayString(index + 1) + ". " + _toDisplayString(step.title), 1))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_font_awesome_icon, {
                size: "lg",
                icon: "fa-solid fa-chevron-down",
                class: "text-theme-active absolute right-2 bottom-[25px]"
              })
            ])
          ]))
        : _createCommentVNode("", true),
      (__props.step == 'ride-details')
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(RideDetails, { data: searchData.value }, null, 8, ["data"])
          ]))
        : (__props.step == 'choose-vehicle')
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(ChooseVehicle, { currentStep: currentStep.value }, null, 8, ["currentStep"])
            ]))
          : (__props.step == 'contact-details' )
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createVNode(ContactDetails, { currentStep: currentStep.value }, null, 8, ["currentStep"])
              ]))
            : (__props.step == 'finish')
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createVNode(Finish, { currentStep: currentStep.value }, null, 8, ["currentStep"])
                ]))
              : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})