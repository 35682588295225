<template>
   <div class="w-full mb-5">
      <label for="document-file" :class="errors.banner.media == '' ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium"> Banner Media </label>
      <div :class="errors.banner.media == '' ? 'border-gray-300' : 'border-theme-error'" class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md">
         <div class="space-y-1 text-center">
            <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
               <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            <div class="flex justify-center text-sm text-gray-600">
               <label @click="handleChoosePhoto" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 outline-none">
                  <button type="button">Click to upload a file</button>
                  <input class="hidden" ref="fileInput" type="file" @change="uploadPhoto" accept="image/*, video/*">
               </label>
            </div>
            <p class="text-xs text-gray-500"><span>(Files up to 3MB)</span></p>
            <p v-if="fileName != ''" class="text-xs text-gray-500"><span>{{ fileName }}</span></p>
         </div>
      </div>
      <p v-if="errors.banner.media != ''" class="text-theme-error mt-1">{{ errors.banner.media }}</p>
   </div>
   <div class="w-full mb-5">
      <label for="name" :class="!errors.name ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium">Page Name</label>
      <input v-model="form.name" type="text" :class="!errors.name ? 'border-gray-300' : 'border-theme-error'" class="mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
   </div>
   <div class="w-full mb-5">
      <label for="name" :class="!errors.banner.heading ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium">Banner Heading</label>
      <textarea v-model="form.banner.heading" class="mt-1 outline-none border rounded-sm h-24 w-full block p-2 text-gray-500 text-sm border-gray-30"></textarea>
   </div>
   <div class="w-full mb-5">
      <label for="name" class="block text-sm font-medium text-gray-700">Banner Sub Heading</label>
      <input v-model="form.banner.subHeading" type="text" class="mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm border-gray-300">
   </div>
   <div class="w-full mb-5">
      <label for="name" class="block text-sm font-medium text-gray-700">Banner Text</label>
      <textarea v-model="form.banner.text" type="text" class="mt-1 outline-none border rounded-sm h-24 w-full block p-2 text-gray-500 text-sm border-gray-30"></textarea>
   </div>
   <div class="w-full mb-5">
      <label for="name" class="block text-sm font-medium text-gray-700">Banner Button Text</label>
      <input v-model="form.banner.button.title" type="text" class="border-gray-300 mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
   </div>
   <div class="w-full mb-5">
      <label for="name" class="text-gray-700 block text-sm font-medium">Banner Button URL</label>
      <input v-model="form.banner.button.url" type="text" class="mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm border-gray-300">
   </div>
   <div class="w-full mb-5">
      <label for="name" class="text-gray-700 block text-sm font-medium">Section title</label>
      <input v-model="form.section.title" type="text" class="mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm border-gray-300">
   </div>
   <div class="w-full mb-5">
      <label for="name" class="block text-sm font-medium text-gray-700">Section Text</label>
      <ckeditor :editor="editor" v-model="form.section.text" :config="editorConfig"></ckeditor>
     <!-- <textarea v-model="form.section.text" type="text" class="mt-1  outline-none border rounded-sm h-24 w-full block p-2 text-gray-500 text-sm border-gray-30"></textarea> -->
   </div>
   <div class="w-full mb-5">
      <label class="block text-sm font-medium text-gray-700">Show partner form</label>
      <select v-model="form.showSupplierForm" class="block overflow-hidden mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6">
         <option value="1">Yes</option>
         <option value="0">No</option>
      </select>
   </div>
   <div class="flex">
      <button @click="handleSubmit()" type="button" class="ml-auto mt-20 bg-indigo-600 py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 outline-none">
         Save
      </button>
   </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import Admin from '@/composables/admin/index'
import { resetErrors, handleApiError, isImageFile, isVideoFile } from '@/utils/common'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const props = defineProps(['page'])
const emit = defineEmits(['success', 'error'])
const { createCar, updateCar } = Admin()
const isLoading = ref<boolean>(false)
const fileInput = ref<HTMLInputElement | null>(null)
const fileName = ref<string>('')
const { createPage, updatePage } = Admin()
const editorConfig : any = {
   
}
const editor = ClassicEditor
const form = ref({
   name: '',
   banner: {
      media: {
         type: '',
         data: ''
      },
      heading: '',
      subHeading: '',
      text: '',
      button: {
         title: '',
         url: ''
      }
   },
   section: {
      title: '',
      text: ''
   },
   showSupplierForm: '1'
})
const errors = ref({
   name: false,
   banner: {
      media: '',
      heading: false
   }
})

onMounted(() => {
   mounted() 
})

const mounted = async () => {

   if (props.page) {
      
      fileName.value = props.page.media_source ? props.page.media_source : ''
      form.value = {
         name: props.page.title,
         banner: {
            media: {
               type: '',
               data: ''
            },
            heading: props.page.banner_heading,
            subHeading: props.page.banner_sub_heading,
            text: props.page.banner_text,
            button: {
               title: props.page.banner_button_text,
               url: props.page.banner_button_url
            }
         },
         section: {
            title: props.page.section_title,
            text: props.page.section_info_text
         },
         showSupplierForm: props.page.show_supplier_form
      }
   }
}

const handleChoosePhoto = () => {
   fileInput.value?.click()
}

const uploadPhoto = (e: Event) => {

   form.value.banner.media.data = ''
   form.value.banner.media.type = ''
   fileName.value = ''
   errors.value.banner.media = ''

   const inputElement = e.target as HTMLInputElement

   if (!inputElement.files || inputElement.files.length === 0) {
      return
   }

   const file = inputElement.files[0]
   const reader = new FileReader()

   if (!isImageFile(file) && !isVideoFile(file)) {
      errors.value.banner.media = 'File is not a valid image or video'
   } else if (isImageFile(file) && file.size > 10485760) {
      errors.value.banner.media = 'Image must be under 10MB in size'
   } else if (isVideoFile(file) && file.size > 20971520) {
      errors.value.banner.media = 'Video must be under 20MB in size'
   }

   fileName.value = file.name

   reader.onloadend = () => {
      form.value.banner.media.data = reader.result as string
      form.value.banner.media.type = isImageFile(file) ? 'image' : 'video'
   }

   reader.readAsDataURL(file)
}

const validateForm = () : boolean => {

   let response = true

   if (form.value.name === '') {
      errors.value.name = true
      response = false
   }

   if (form.value.banner.heading === '') {
      errors.value.banner.heading = true
      response = false
   }
   
   if (form.value.banner.media.type == '' && props.page == null) {
      errors.value.banner.media = 'true'
      response = false
   }

   return response 
}

const handleSubmit = async () => {

   resetErrors(errors.value)
   const isValid = validateForm()

   if (isValid) {
      
      if (props.page == null) {

         isLoading.value = true
         const response = await createPage(form.value)
         isLoading.value = false
         if (response.status == 201) {
            emit('success')
         } else {
            const error = handleApiError(response)
            emit('error', error)
         }
      } else {

         isLoading.value = true
         const response = await updatePage(props.page.id, form.value)
         isLoading.value = false
         if (response.status == 200) {
            emit('success')
         } else {
            const error = handleApiError(response)
            emit('error', error)
         }
      }
   }
}

</script>

<style>

.ck-editor__editable {
   min-height: 100px;
}

</style>