import { getUserToken, getBackendBaseUrl } from "@/utils/common"
import axios from "axios"
import { Corporate as CorporateType } from "@/types/admin"


const baseUrl : string = getBackendBaseUrl()

const Corporate = () => {

   const getHeaders = () => { 
      return {
         'Authorization': `Bearer ${getUserToken()}`,
         'Content-Type': 'application/json',
      }
   }

   const allCorporateBookings = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/corporate/bookings/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const corporateProfile = async () : Promise<any> => {
      try {
         const response = await axios.get(`${baseUrl}api/corporate/profile`, {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const corporateProfileSave = async (data: any, type: 'personal' | 'password') : Promise<any> => {
      let response
      try {
         if (type == 'personal') {
            response = await axios.post(baseUrl + 'api/corporate/profile', {name: data.fullName, phone: data.phone, commission: data.commission, company: data.company, photo: data.photo}, {headers: getHeaders()})
         } else {
            response = await axios.post(baseUrl + 'api/corporate/password', {currentPassword: data.current, newPassword: data.new, passwordConfirmation: data.confirmNew}, {headers: getHeaders()})
         }

         return response
      } catch (err : any) {
         return err
      }
   }

   const subUsers = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/corporate/sub-users/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const createSubUser = async (data: CorporateType) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + 'api/corporate/sub-users/create', {name: data.name, email: data.email, phone: data.phone, commission: data.commission, origin: window.location.origin}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const updateSubUser = async (data: CorporateType) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/corporate/sub-users/update', {id: data.id, name: data.name, phone: data.phone, commission: data.commission}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const removeSubUser = async (id: number) : Promise<any> => {
      try {
         const response = await axios.delete(baseUrl + 'api/corporate/sub-users/delete', { headers: getHeaders(), data: { id: id } })
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      allCorporateBookings,
      corporateProfile,
      corporateProfileSave,
      subUsers,
      createSubUser,
      updateSubUser,
      removeSubUser
   }
}

export default Corporate