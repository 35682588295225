import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-1" }
const _hoisted_2 = {
  type: "button",
  class: "font-bold text-theme-gray-5 text-size-6 border-0 outline-none"
}
const _hoisted_3 = {
  key: 0,
  class: "z-10 max-h-[200px] overflow-y-auto origin-top-right absolute right-0 mt-4 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5"
}
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = {
  key: 1,
  class: "font-bold text-theme-gray-5 text-size-6"
}

import { ref, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TransferTypeField',
  props: ['layout'],
  emits: ['transferTypeSelected'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const isDropOpen = ref<boolean>(false)
const selectedTransferType = ref<string>('One Way')

const toggleDrop = () => {
   isDropOpen.value = !isDropOpen.value
}

watch(selectedTransferType, (newValue, oldValue) => {
  emit('transferTypeSelected', newValue)
})


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative p-5", __props.layout == 'simple' ? 'col-span-7 md:col-span-2' : 'col-span-8'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "uppercase text-small text-theme-gray-2" }, "Transfer Type", -1)),
      (__props.layout == 'simple')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "inline-block text-left cursor-pointer",
            onClick: toggleDrop
          }, [
            _createElementVNode("button", _hoisted_2, _toDisplayString(selectedTransferType.value), 1),
            (isDropOpen.value)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                  _createElementVNode("li", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (selectedTransferType.value = 'One Way')),
                    class: "py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer"
                  }, " One Way ")
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_font_awesome_icon, {
                size: "lg",
                icon: "fa-solid fa-chevron-down",
                class: "text-theme-active absolute right-0 bottom-1"
              })
            ])
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(selectedTransferType.value), 1))
    ])
  ], 2))
}
}

})