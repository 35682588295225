import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-12 md:w-[80%] mx-auto"
}

import { onMounted, ref } from 'vue'
import Voucher from '@/components/booking/Voucher.vue'
import { Booking } from '@/types'
import BookingService from '@/services/booking-service'
import { getBookingNumber, getSessionPrefix } from '@/utils/common'
import router from '@/router'
// @ts-ignore
import html2pdf from 'html2pdf.js'


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingVoucher',
  props: ['bookingNumber'],
  setup(__props) {

const data = ref<Booking | null>(null)
const isLoading = ref<boolean>(true)
const { readBooking } = BookingService()
const pdfContent = ref(null)
const props = __props

onMounted(async () => {

   const response = await readBooking(props.bookingNumber as string)
   isLoading.value = false
   
   if (response.success) {
      data.value = response.data.data
   } else {
      console.log(response.error)
   }
})

const downloadPDF = () => {

   const content = pdfContent.value
   html2pdf(content).from(content).save()
}


return (_ctx: any,_cache: any) => {
  return (!isLoading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          ref_key: "pdfContent",
          ref: pdfContent
        }, [
          _createVNode(Voucher, { data: data.value }, null, 8, ["data"])
        ], 512),
        _createElementVNode("div", {
          onClick: downloadPDF,
          class: "uppercase w-48 ml-auto text-size-2 py-4 px-8 text-center rounded-full bg-theme-active border-2 text-white cursor-pointer hover:bg-white hover:border-theme-active hover:text-theme-active transition ease-in-out duration-300"
        }, " Download PDF ")
      ]))
    : _createCommentVNode("", true)
}
}

})