<template>
   <div class="flex flex-col h-full">
      <TopBar @openSidebar="handleSideBarOpen" />
      <main class="py-10 bg-gray-50 flex-grow">
         <div class="px-4 sm:px-6 lg:px-8">
            <slot name="content" />
         </div>
      </main>
   </div>
   <Modal :show="openModal" :canClose="false">
      <template v-slot:content>
         <div class="flex flex-col gap-12 py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
            <div class="flex flex-col w-full mx-auto gap-5">
               <div class="flex flex-col gap-2">
                  <label for="reset-password" class="uppercase text-small text-theme-gray-4">New password *</label>
                  <div class="flex flex-row items-center border border-theme-gray-7">
                     <input v-model="form.password.value" type="password" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
                  </div>
                  <span v-if="form.password.error" class="text-ef-error">{{ form.password.errorMessage }}</span>
               </div>
               <div class="flex flex-col gap-2">
                  <label for="reset-password-repeat" class="uppercase text-small text-theme-gray-4">Repeat password *</label>
                  <div class="flex flex-row items-center border border-theme-gray-7">
                     <input v-model="form.passwordConfirmation.value" type="password" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
                  </div>
                  <span v-if="form.passwordConfirmation.error" class="text-ef-error">{{ form.passwordConfirmation.errorMessage }}</span>
               </div>
               <div class="flex flex-row items-center gap-4">
                  <div @click="updatePassword" class="gap-4 uppercase rounded-full px-4 w-full md:w-auto md:px-8 py-2 md:py-4 border-2 border-theme-active text-white bg-theme-active hover:text-theme-active hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer ml-auto transition-all ease-in-out duration-500">
                     Update password
                  </div>
               </div>
               <p v-if="error" class="text-ef-error">{{ error }}</p>
            </div>
         </div>
      </template>
   </Modal>
 </template>
 
<script setup lang="ts">
import { onMounted, ref } from 'vue'
import TopBar from '@/components/account/TopBar.vue'
import SideBar from '@/components/account/SideBar.vue'
import { checkUserPasswordSetup, handleApiError, updateAuthUser, resetErrors } from '@/utils/common'
import Modal from '@/widgets/Modal.vue'
import Auth from '@/composables/Auth'

const sidebarOpen = ref<boolean>(false)
const openModal = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const error = ref<string>('')
const { setupPassword }  = Auth()
const form = ref<any>({
   password: { value: '', error: false, errorMessage: '' },
   passwordConfirmation: { value: '', error: false, errorMessage: '' }
})

const handleSideBarOpen = () => {
   sidebarOpen.value = true
}

const updatePassword = async () => {

   const isValid = validateForm()
   if (isValid) {

      isLoading.value = true
      const response = await setupPassword(form.value.password.value as string, form.value.passwordConfirmation.value as string)
      isLoading.value = false

      if (response.status == 200) {
         updateAuthUser(response.data.user)
         openModal.value = false
      } else {
         error.value = handleApiError(response)
      }
   }
}

const validateForm = () : boolean => {

   let response = true
   form.value.password.error = false
   form.value.passwordConfirmation.error = false

   if (form.value.password.value == '') {
      form.value.password.error = true
      form.value.password.errorMessage = 'Required'
      response = false
   }

   if (form.value.passwordConfirmation.value == '') {
      form.value.passwordConfirmation.error = true
      form.value.passwordConfirmation.errorMessage = 'Required'
      response = false
   }
   
   if (form.value.password.value != '' && form.value.password.value.length < 6) {
      form.value.password.error = true
      form.value.password.errorMessage = 'Must be at least 6 characters long'
      response = false
   } else if (form.value.password.value != '' && form.value.passwordConfirmation.value != '' && form.value.password.value != form.value.passwordConfirmation.value) {
      form.value.passwordConfirmation.error = true
      form.value.passwordConfirmation.errorMessage = 'Password confirmation is not the same as password'
      response = false
   }

   return response
}

onMounted(() => {

   const hasSetupPassword = checkUserPasswordSetup()

   if (hasSetupPassword === 0) {
      openModal.value = true
   }
})

</script>