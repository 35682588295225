import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["onClick", "index"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text-theme-gray-2" }
const _hoisted_5 = { class: "text-theme-gray-2 font-light" }

import { ref, computed, onMounted, onUnmounted } from 'vue'
import Country from '@/composables/Country'

interface CountryType {
   id: number;
   name: string;
   flag: string;
   code: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CountrySearchablePhoneField',
  props: ['class', 'sleek'],
  emits: ['phoneNumber', 'number'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const { countriesList, getUserCountry } = Country()
const dropdownWrapper = ref()
const phoneNumber = ref<string>('')
const countries = ref({
   open: false,
   search: '',
   list: []
})

const fillteredCountryList = computed(() => {
   return countries.value.list.filter((country: CountryType) => country.name.toLowerCase().includes(countries.value.search)) as CountryType[];
})

const selectedCountry = ref<CountryType| null>(null)

const toggleCountryList = () => {
   countries.value.open = !countries.value.open
   countries.value.search = ''
}

const handleCountrySelect = (country: any) => {
   selectedCountry.value = country
   countries.value.open = false
}

const handleCountrySearch = (event:any) => {
   const input = event.target.value.toLowerCase()
   countries.value.search = input
}

const handlePhoneNumberInput = (event: any) => {
   phoneNumber.value = event.target.value.replace(/\D/g, '')

   if (phoneNumber.value.length > 10) {
      phoneNumber.value = phoneNumber.value.toString().slice(0, 10)
   }

   if (phoneNumber.value.length) {
      emit('phoneNumber', selectedCountry.value?.code + phoneNumber.value)
   } else {
      emit('phoneNumber', false)
   }
}

const handleClickOutside = (event: any) => {
   
   if (dropdownWrapper.value && !dropdownWrapper.value.contains(event.target as Node)) {
      countries.value.open = false
      countries.value.search = ''
   }
}

onMounted(async () => {
   const response = await countriesList()

   if (response.status == 200) {
      countries.value.list = response.data.data
   }

   const currentCountry = await getUserCountry()
   if (currentCountry.status == 200) {
      selectedCountry.value = currentCountry.data.country
   }

   document.addEventListener('click', handleClickOutside);
})

onUnmounted(() => {
   document.removeEventListener('click', handleClickOutside);
})


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "dropdownWrapper",
    ref: dropdownWrapper
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-row items-center gap-2 w-full bg-white", __props.class])
    }, [
      _createElementVNode("div", {
        onClick: toggleCountryList,
        class: "flex flex-row items-center gap-2 cursor-pointer"
      }, [
        _createElementVNode("span", null, [
          (selectedCountry.value)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "min-w-[20px] max-w-[20px]",
                src: `/assets/flags/${selectedCountry.value.flag.toLowerCase()}.svg`,
                alt: `sweden flag`
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true)
        ]),
        (selectedCountry.value)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(__props.sleek ? 'text-sm' : '')
            }, "+" + _toDisplayString(selectedCountry.value.code), 3))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, [
          _createVNode(_component_font_awesome_icon, {
            size: "sm",
            icon: "fa-solid fa-chevron-down",
            class: "text-theme-active"
          })
        ])
      ]),
      _withDirectives(_createElementVNode("input", {
        onInput: handlePhoneNumberInput,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((phoneNumber).value = $event)),
        placeholder: "Enter your Phone Number",
        type: "text",
        class: _normalizeClass([__props.sleek ? 'text-sm' : '', "outline-none w-full h-30 font-bold text-size-6 border-none w-full bg-transparent"])
      }, null, 34), [
        [_vModelText, phoneNumber.value]
      ])
    ], 2),
    (countries.value.open)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
          class: "z-10 max-h-[200px] overflow-y-auto top-full absolute right-0 p-4 flex flex-col gap-2 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        }, [
          _createElementVNode("li", null, [
            _createElementVNode("input", {
              class: "w-full border bordet-theme-gray-7 text-size-4 text-gray-5 outline-none px-2 py-1",
              placeholder: "Search country",
              type: "text",
              onInput: handleCountrySearch
            }, null, 32)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fillteredCountryList.value, (country) => {
            return (_openBlock(), _createElementBlock("li", {
              onClick: ($event: any) => (handleCountrySelect(country)),
              index: country.id,
              class: "flex flex-row items-center gap-3 cursor-pointer hover:bg-gray-100 p-1"
            }, [
              _createElementVNode("img", {
                class: "min-w-[20px] max-w-[20px]",
                src: `/assets/flags/${country.flag.toLowerCase()}.svg`,
                alt: "sweden flag"
              }, null, 8, _hoisted_3),
              _createElementVNode("span", _hoisted_4, _toDisplayString(country.name), 1),
              _createElementVNode("span", _hoisted_5, "+" + _toDisplayString(country.code), 1)
            ], 8, _hoisted_2))
          }), 256))
        ]))
      : _createCommentVNode("", true)
  ], 512))
}
}

})