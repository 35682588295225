<template>
   <div class="w-full mb-5">
      <label for="document-file" class="block text-sm font-medium text-gray-700"> Car photo </label>
      <div class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
         <div class="space-y-1 text-center">
            <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
               <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            <div class="flex justify-center text-sm text-gray-600">
               <label @click="handleChoosePhoto" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 outline-none">
                  <button type="button">Click to upload a file</button>
                  <input class="hidden" ref="fileInput" type="file" @change="uploadPhoto" accept="image/*">
               </label>
            </div>
            <p class="text-xs text-gray-500"><span>(Files up to 3MB)</span></p>
            <p v-if="photoName != ''" class="text-xs text-gray-500"><span>{{ photoName }}</span></p>
         </div>
      </div>
   </div>
   <div class="w-full mb-5">
      <label for="name" :class="!errors.name ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium">Name</label>
      <input v-model="form.name" type="text" :class="!errors.name ? 'border-gray-300' : 'border-theme-error'" class="mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
   </div>
   <div class="w-full mb-5">
      <label for="stripe-id" class="block text-sm font-medium text-gray-700">Car type</label>
      <select v-model="form.type" class="block mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6">
         <option v-for="(carType, index) in carTypes" :key="index" :value="carType.id">{{ carType.name }}</option>
      </select>
   </div>
   <div v-for="eachSupplier in form.suppliers" class="w-full mb-5">
      <label class="block text-sm font-medium text-gray-700">Supplier</label>
      <select v-model="eachSupplier.id" class="block overflow-hidden mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6">
         <option v-for="(supplier, index) in suppliers" :key="index" :value="supplier.id">{{ truncateText(supplier.name, 40) }}</option>
      </select>
   </div>
   <div class="flex items-center justify-between">
      <button type="button" class="group ml-auto flex items-center rounded-md bg-white p-1 outline-none">
         <span class="flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400">
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path></svg>
         </span>
         <span @click="addSupplier" class="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">Add supplier</span>
      </button>
   </div>
   <div class="w-full mb-5">
      <label for="stripe-id" :class="!errors.displacement ? 'text-gray-700' : 'text-theme-error'"  class="block text-sm font-medium">Power (cc)</label>
      <input v-model="form.displacement" type="number" :class="!errors.name ? 'border-gray-300' : 'border-theme-error'" class="mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
   </div>
   <div class="w-full mb-5">
      <label for="stripe-id" class="block text-sm font-medium text-gray-700">Transmission type</label>
      <select v-model="form.transmission" class="block overflow-hidden mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6">
         <option value="manual">Manual</option>
         <option value="automatic">Automatic</option>
      </select>
   </div>
   <div class="w-full mb-5">
      <label for="stripe-id" class="block text-sm font-medium text-gray-700">Meet and greet</label>
      <select v-model="form.meet_greet" class="block overflow-hidden mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6">
         <option value="1">Yes</option>
         <option value="0">No</option>
      </select>
   </div>
   <div class="flex">
      <button @click="handleSubmit()" type="button" class="ml-auto mt-20 bg-indigo-600 py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 outline-none">
         Save
      </button>
   </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { CarType, Supplier } from '@/types/index'
import Admin from '@/composables/admin/index'
import { truncateText, resetErrors, handleApiError, isImageFile } from '@/utils/common'

const props = defineProps(['car', 'carTypes', 'suppliers'])
const emit = defineEmits(['success', 'error'])
const { createCar, updateCar } = Admin()
const isLoading = ref<boolean>(false)
const fileInput = ref<HTMLInputElement | null>(null)
const photoName = ref<string>('')
const form = ref({
   name: '',
   photo: '',
   type: 1,
   suppliers: [
      {id: 1}
   ],
   displacement: '',
   transmission: 'manual',
   meet_greet: '1'
})
const errors = ref({
   name: false,
   displacement: false,
   photo: ''
})

onMounted(() => {
   mounted() 
})

const mounted = async () => {

   if (props.car) {
      photoName.value = props.car.imageName ? props.car.imageName : ''
      form.value = {
         name: props.car.name,
         photo: '',
         type: props.car.type.id,
         suppliers: [
            {id: props.car.supplier.id}
         ],
         displacement: props.car.displacement,
         transmission: props.car.transmission,
         meet_greet: props.car.meetGreet
      }
   }
}

const handleChoosePhoto = () => {
   fileInput.value?.click()
}

const addSupplier = () => {

   const newSupplier = {id: 1}
   form.value.suppliers.push(newSupplier)
}

const uploadPhoto = (e: Event) => {

   form.value.photo = ''
   photoName.value = ''
   errors.value.photo = ''

   const inputElement = e.target as HTMLInputElement

   if (!inputElement.files || inputElement.files.length === 0) {
      return
   }

   const file = inputElement.files[0]
   const reader = new FileReader()

   if (!isImageFile(file)) {
      errors.value.photo = 'Upload a valid image file'
      return
   } else if (file.size > 3145728) {
      errors.value.photo = 'Image must be of size under 3MB'
      return
   }

   photoName.value = file.name

   reader.onloadend = () => {
      form.value.photo = reader.result as string
   }

   reader.readAsDataURL(file)
}

const validateForm = () : boolean => {

   let response = true

   if (form.value.name === '') {
      errors.value.name = true
      response = false
   }

   if (form.value.displacement === '') {
      errors.value.displacement = true
      response = false
   }

   return response 
}

const handleSubmit = async () => {

   resetErrors(errors.value)
   const isValid = validateForm()

   if (isValid) {
      
      if (props.car == null) {

         isLoading.value = true
         const response = await createCar(form.value)
         isLoading.value = false
         if (response.status == 201) {
            emit('success')
         } else {
            const error = handleApiError(response)
            emit('error', error)
         }  
      } else {

         isLoading.value = true
         const response = await updateCar(props.car.id, form.value)
         isLoading.value = false
         if (response.status == 200) {
            emit('success')
         } else {
            const error = handleApiError(response)
            emit('error', error)
         }
      }
   }
}

</script>