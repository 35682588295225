<template>

   <div id="map" style="height: 530px; width: 100%; z-index: 2;"></div>

</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'

const icon = new (window as any).google.maps.MarkerImage('/assets/marker.png')

async function initMap() {
   const { Map } = await (window as any).google.maps.importLibrary("maps") as any

   const map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 26.2407283, lng: 50.5909309 },
      zoom: 15,
      mapId: '8f3ea1e16085205e',
   })

   const marker = new (window as any).google.maps.Marker({
      map,
      position: { lat: 26.2407283, lng: 50.5909309 },
      icon: icon,
   })
}

onMounted(function(){

   initMap()
});
</script>