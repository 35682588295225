<template>

   <div class="relative p-5" :class="layout == 'simple' ? 'col-span-7 md:col-span-2' : 'col-span-8'">
      <div class="flex flex-col space-y-1">
         <div class="uppercase text-small text-theme-gray-2">Transfer Type</div>
         <div v-if="layout == 'simple'" class="inline-block text-left cursor-pointer" @click="toggleDrop">
            <button type="button" class="font-bold text-theme-gray-5 text-size-6 border-0 outline-none">{{ selectedTransferType }}</button>
            <ul v-if="isDropOpen" class="z-10 max-h-[200px] overflow-y-auto origin-top-right absolute right-0 mt-4 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5">
               <li @click="selectedTransferType = 'One Way'" class="py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer">
                  One Way
               </li>
            </ul>
            <div class="relative">
               <font-awesome-icon size="lg" icon="fa-solid fa-chevron-down" class="text-theme-active absolute right-0 bottom-1" />
            </div>
         </div>
         <span v-else class="font-bold text-theme-gray-5 text-size-6">{{ selectedTransferType }}</span>
   </div>
   </div>

</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

const props = defineProps(['layout'])
const emit = defineEmits(['transferTypeSelected'])
const isDropOpen = ref<boolean>(false)
const selectedTransferType = ref<string>('One Way')

const toggleDrop = () => {
   isDropOpen.value = !isDropOpen.value
}

watch(selectedTransferType, (newValue, oldValue) => {
  emit('transferTypeSelected', newValue)
})

</script>