<template>
   <div class="py-10 px-4 sm:px-6 lg:px-24">
      <div class="sm:flex sm:items-center">
         <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">Bookings</h1>
            <p class="mt-2 text-sm text-gray-700">A list of all the bookings created under your account</p>
         </div>
      </div>
      <div class="mt-8 flow-root">
         <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
               <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <BookingsTable :subAgents="mySubAgents" :bookings="bookings" :isLoading="isLoading" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import BookingsTable from '@/components/account/BookingsTable.vue'
import Agent from '@/composables/admin/agent';
import Corporate from '@/composables/admin/corporate';
import { handleApiError, isAdminLoggedIn, isLoggedIn, logout } from '@/utils/common';
import router from '@/router';

const { allAgentBookings, subAgentBookings, subAgents } = Agent()
const { allCorporateBookings } = Corporate()
const props = defineProps(['subUserId']) 
const isLoading = ref<boolean>(true)
const bookings = ref<any>(null)
const mySubAgents = ref([])

onMounted(async () => {

   const authUser = isLoggedIn()
   await fetchBookings(authUser ? authUser.user.type : '')
})

const fetchBookings = async (userType: string) => {

   isLoading.value = true
   let response : any

   if (userType == 'corporate') {

      response = await allCorporateBookings()
   } else if (userType == 'agent') {

      response = await allAgentBookings()
      await fetchSubAgents()
   }

   isLoading.value = false

   if (response.status == 200) {
      
      bookings.value = response.data
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const fetchSubAgents = async () => {

   const response = await subAgents()

   if (response.status == 200) {
      mySubAgents.value = response.data
   } else {
      const error = handleApiError(response)
      console.log(error)
   }
}

</script>