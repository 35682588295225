<template>
   <div class="space-y-10 divide-y divide-gray-900/10 py-10 px-4 sm:px-6 lg:px-24">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
         <div class="px-4 sm:px-0">
            <h2 class="text-base font-semibold leading-7 text-gray-900">Site Settings</h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">Configure commissions, discounts</p>
         </div>
         <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div class="px-4 py-6 sm:p-8">
               <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-3">
                     <label for="full-name" class="block text-sm font-medium leading-6 text-gray-900">Free Cancellation Time *</label>
                     <p class="text-gray-500 text-xs">Hours before customer can cancel their booking</p>
                     <div class="mt-2">
                        <input v-model="cancellationHours" type="number" id="full-name" :class="errors.cancellationHours ? 'ring-red-600' : 'ring-gray-300'" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2" />
                     </div>
                     <p v-if="errors.cancellationHours" class="mt-2 text-sm text-red-600">This field is required</p>
                  </div>
               </div>
            </div>
            <div class="flex items-center justify-between gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
               <p class="mt-2 text-sm text-gray-900">* required field</p>
               <button @click="handleSubmit" type="button" :class="isLoading ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer'" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 outlinne-none">Save</button>
            </div>
         </form>
      </div>
   </div>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import SiteSettings from '@/composables/SiteSettings'
import { handleApiError, isImageFile, isLoggedIn, resetErrors, saveAuthCredentials, truncateText, updateAuthUser } from '@/utils/common'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'

const { loadSiteSettings, saveSiteSettings } = SiteSettings()
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const isLoading = ref<boolean>(true)
const cancellationHours = ref<number>(0)
const errors = ref<any>({
   cancellationHours: false
})

onMounted(async () => {

   await fetchSiteSettings()
})

const fetchSiteSettings = async () => {

   isLoading.value = true
   const response = await loadSiteSettings()
   isLoading.value = false

   if (response.status == 200) {
      cancellationHours.value = response.data.cancellation_hours
   } else {
      const error = handleApiError(response)
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      setTimeout(() => { notifClosed() }, 4000)
   }
}

const handleSubmit = async () => {

   if (!isLoading.value) {

      resetErrors(errors.value)
      const isValid = validateForm()
   
      if (isValid) {
   
         isLoading.value = true
         let response : any

         response = await saveSiteSettings(cancellationHours.value)

         isLoading.value = false
         if (response.status == 200) {
            notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Settings Updated'}
            setTimeout(() => { notifClosed() }, 4000)

            fetchSiteSettings()
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
            setTimeout(() => { notifClosed() }, 4000)
         } 
      }
   }
}

const notifClosed = () : void => {
   notif.value.show = false
}

const validateForm = () : boolean => {

   let response = true
   return response
}

</script>