<template>

   <div class="p-5" :class="layout == 'simple' ? 'col-span-7 md:col-span-1' : 'col-span-8 md:col-span-4'">
      <div class="flex flex-col space-y-2">
         <div :class="hasError ? 'text-theme-error' : 'text-theme-gray-2'" class="flex gap-2 uppercase text-small">
            <span>PickUp Date</span>
         </div>
         <div class="flex flex-row items-center">
            <flat-pickr v-if="layout=='simple'" v-model="date" :config="getDateDisplayFormat()" class="font-bold text-theme-gray-5 text-size-6 border-0 outline-none w-[90%]" placeholder="" />
            <span v-else class="font-bold text-theme-gray-5 text-size-6">{{ date }}</span>
            <div v-if="hasError" class="ml-auto">
               <font-awesome-icon size="1x" icon="fa-solid fa-circle-exclamation" class="text-theme-error" />
            </div>
         </div>
      </div>
   </div>

</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { getDateDisplayFormat } from '@/utils/date'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'

const props = defineProps(['layout', 'hasError', 'data'])
const emit = defineEmits(['dateChanged'])
const date = ref<string | null>(null)

onMounted(() => {

   if (props.data) {
      date.value = props.data
   }
})

watch(date, (newValue, oldValue) => {

   let val : string | null = newValue

   if (newValue === '') {
      val = null
   }

  emit('dateChanged', val)
})

</script>