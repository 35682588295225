import { getUserToken, getBackendBaseUrl } from "@/utils/common"
import { LeadForm } from '@/types/lead'
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const Deal = () => {

   const getHeaders = () => { 
      return {
         'Authorization': `Bearer ${getUserToken()}`,
         'Content-Type': 'application/json',
      }
   }

   const createDeal = async (bookingNumber: number) : Promise<any> => {
      try {
         let response

         if (getUserToken() == null) {
            response = await axios.post(baseUrl + 'api/crm/register/deal', {booking: bookingNumber}, {headers: getHeaders()})
         } else {
            response = await axios.post(baseUrl + 'api/crm/protected/register/deal', {booking: bookingNumber}, {headers: getHeaders()})
         }

         return response
      } catch (err : any) {
         return err
      }
   }
   
   const updateDeal = async (dealId: number, bookingNumber: number) : Promise<any> => {
      try {

         const response = await axios.put(`${baseUrl}api/crm/protected/update/deal/${dealId}`, {booking: bookingNumber}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const createContact = async (bookingNumber: number) : Promise<any> => {
      try {

         let response

         if (getUserToken() == null) {
            response = await axios.post(baseUrl + 'api/crm/register/contact', {booking: bookingNumber}, {headers: getHeaders()})
         } else {
            response = await axios.post(baseUrl + 'api/crm/protected/register/contact', {booking: bookingNumber}, {headers: getHeaders()})
         }

         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      createDeal,
      updateDeal,
      createContact
   }
}

export default Deal