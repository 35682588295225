<template>
   <div class="py-10 px-4 sm:px-6 lg:px-24">
      <div class="sm:flex sm:items-center">
         <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">Sub-Users</h1>
            <p class="mt-2 text-sm text-gray-700">A list of all sub users under your account</p>
         </div>
         <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button @click="handleAdd" type="button" class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
               Add Sub User
            </button>
         </div>
      </div>
      <div class="mt-8 flow-root">
         <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
               <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <div v-if="isLoading" class="text-center my-8 font-medium">...loading data. please wait</div>
                  <table v-else class="min-w-full divide-y divide-gray-300">
                     <thead class="bg-gray-50">
                        <tr>
                           <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                           <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                           <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Phone</th>
                           <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span class="sr-only">View</span>
                           </th>
                        </tr>
                     </thead>
                     <tbody class="divide-y divide-gray-200 bg-white">
                        <tr v-for="item in subs" :key="item.id">
                           <td @click="$router.push({name: 'AccountDashboard', params: {'agent': item.id}})" class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 capitalize cursor-pointer text-indigo-600 hover:text-indigo-900 ">{{ item.name }}</td>
                           <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.email }}</td>
                           <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.phone }}</td>
                           <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <span @click="$router.push({name: 'AccountDashboard', params: {'agent': item.id}})" class="text-indigo-600 hover:text-indigo-900 cursor-pointer">User Report</span> | 
                              <span @click="$router.push({name: 'UserBookings', params: {'userType' : 'corporate', 'subUserId' : item.id}})" class="text-indigo-600 hover:text-indigo-900 cursor-pointer">Bookings</span> | 
                              <span @click="handleEdit(item)" class="text-indigo-600 hover:text-indigo-900 cursor-pointer">Edit</span> | 
                              <span @click="handleProceedDelete(item)" class="text-indigo-600 hover:text-indigo-900 cursor-pointer">Delete</span> 
                           </td>
                        </tr>
                        <tr v-if="subs && subs.length == 0">
                           <td colspan="4" class="text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              No sub users created yet   
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
   </div>
   <ConfirmationModal :show="openConfirmModal" @closed="handleConfirmClose">
      <template v-slot:buttons>
         <button @click="askingConfirmed" type="button" :class="isLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer'" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto">Remove</button>
         <button @click="openConfirmModal = false" type="button" class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto">Cancel</button>
      </template>
   </ConfirmationModal>
   <SlideOver :show="openSaveDialog" @closed="openSaveDialog = false">
      <template v-slot:content>
         <SaveSubUser :data="selectedSubUser" @success="handleSaveSuccess" @error="handleSaveError" />
      </template>
   </SlideOver>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { handleApiError } from '@/utils/common'
import SlideOver from '@/widgets/SlideOver.vue'
import { NotificationType } from '@/types/index'
import Notification from '@/widgets/Notification.vue'
import SaveSubUser from '@/components/corporate/SaveSubUser.vue'
import { Corporate as CorporateType } from '@/types/admin'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'
import Corporate from '@/composables/admin/corporate'

const isLoading = ref<boolean>(false)
const openSaveDialog = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const subs = ref<CorporateType[]>([])
const selectedSubUser = ref<CorporateType | null>(null)
const openConfirmModal = ref<boolean>(false)
const deleteItem = ref<CorporateType | null>(null)
const { subUsers, removeSubUser } = Corporate()

onMounted(() => {
   mounted() 
})

const mounted = async () => {

   await fetchSubUsers()
}

const handleSaveSuccess = async () => {
   openSaveDialog.value = false
   notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'A sub user has been added successfully'}
   setTimeout(() => { notifClosed() }, 4000)
   
   await fetchSubUsers()
}

const handleSaveError = (error: string) => { 
   notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
   setTimeout(() => { notifClosed() }, 4000)
}

const handleAdd = () => {
   selectedSubUser.value = null
   openSaveDialog.value = true
}

const handleEdit = (user: CorporateType) => {

   selectedSubUser.value = user
   openSaveDialog.value = true
}

const notifClosed = () : void => {
   notif.value.show = false
}

const handleProceedDelete = (item: CorporateType) => {

   deleteItem.value = item
   openConfirmModal.value = true
}

const askingConfirmed = async () => {
   if (!isLoading.value && deleteItem.value) {
      isLoading.value = true
      const response = await removeSubUser(parseInt(deleteItem.value.id))

      if (response.status == 204) {
         await fetchSubUsers()
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
         setTimeout(() => { notifClosed() }, 4000)
      }

      isLoading.value = false
      openConfirmModal.value = false
   }  
}

const handleConfirmClose = () => openConfirmModal.value = false

const fetchSubUsers = async () => {

   isLoading.value = true
   const response = await subUsers()
   isLoading.value = false

   if (response.status == 200) {
      subs.value = response.data
   } else {
      const error = handleApiError(response)
      console.log(error)
   }
}

</script>