<template>
   <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-40" @close="closed">
         <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
         </TransitionChild>
   
         <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
               <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
                  <DialogPanel class="pointer-events-auto relative w-96">
                     <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                        <div class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                           <button type="button" class="relative rounded-md text-gray-300 hover:text-white outline-none" @click="closed">
                           <span class="absolute -inset-2.5" />
                           <span class="sr-only">Close panel</span>
                              <font-awesome-icon size="lg" icon="fa-solid fa-times" class="text-black" />
                           </button>
                        </div>
                     </TransitionChild>
                     <div class="h-full overflow-y-auto bg-white p-8">
                        <slot name="content" />
                     </div>
                  </DialogPanel>
               </TransitionChild>
            </div>
            </div>
         </div>
      </Dialog>
   </TransitionRoot>
 </template>
 
<script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

const props = defineProps(['open'])
const emit = defineEmits(['closed'])

const closed = () => emit('closed')
</script>