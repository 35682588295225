import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LoaderIcon',
  props: ['mode'],
  setup(__props) {

const props = __props


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass([__props.mode == 'standard' ? 'h-10 w-10' : (__props.mode == 'large' ? 'h-20 w-20' : 'h-5 w-5'), "animate-spin -ml-1 text-red"]),
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24"
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      className: "opacity-25 bg-white",
      cx: "12",
      cy: "12",
      r: "10",
      stroke: "#0d7217",
      strokeWidth: "4"
    }, null, -1),
    _createElementVNode("path", {
      className: "opacity-75",
      fill: "#0d7217",
      d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    }, null, -1)
  ]), 2))
}
}

})