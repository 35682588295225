<template>

   <div @click="isMenuOpen=false" v-if="isMenuOpen" class="bg-black opacity-70 fixed top-0 left-0 w-full h-screen z-10"></div>
   <header class="flex flex-col bg-white main-header">
      <div class="hidden md:block bg-theme-gray-1 text-theme-gray-2">
         <div class="flex flex-col md:flex-row md:items-center md:mx-auto w-full lg:w-theme-dw pt-5 pb-2 md:space-y-0 space-y-3 md:px-0 px-11 md:space-x-12">
            <div class="flex flex-row items-start space-x-4">
               <span class="font-icon theme-icon location-icon text-theme-gray-3"></span>
               <span class="font-light text-size-4">312 Falcon Tower Diplomatic Area, Manama, Kingdom of Bahrain</span>
            </div>
            <div class="flex flex-row items-start space-x-4">
               <span class="text-theme-gray-2">
                  <font-awesome-icon size="sm" icon="fa-solid fa-mobile-screen" />
               </span>
               <span class="text-size-3">+973 3214 1440</span>
            </div>
            <div class="flex flex-row items-start space-x-4">
               <span class="font-icon theme-icon clock-icon text-theme-gray-3 text-[16px]"></span>
               <span class="text-size-3">24/7 Available</span>
            </div>
         </div>
      </div>
      <div class="flex flex-row items-center mx-auto w-full lg:w-theme-dw py-5 md:px-0 px-11 z-20">
         <div @click="router.push({name: 'Home'})" class="w-[235px] cursor-pointer">
            <img src="/assets/logo.png" alt="Site logo">
         </div>
         <div class="hidden md:flex flex-row items-center ml-auto text-theme-gray-4 text-size-4 uppercase space-x-7 cursor-pointer">
            <div @click="handleTopNav(item)" v-for="item in menuItems" :index="item.id" class="group relative">
               {{ item.title }}
               <span v-if="item.children">
                  <font-awesome-icon icon="fa-solid fa-chevron-down" class="text-theme-gray-4 cursor-pointer ml-auto mr-1" />
               </span>
               <div class="h-[1px] opacity-0 w-1 bg-theme-active mb-4 group-hover:w-full group-hover:opacity-100 transition-all ease-in-out duration-500"></div>
               <div v-if="item.children && expandedItemId === item.id" class="flex flex-col absolute left-0 top-6 w-[150%] pt-2">
                  <div @click="handleSubNav(child)" v-for="child in item.children" :index="child.id" class="border-b border-gray-100 bg-white py-4 pl-2 text-sm hover:bg-gray-50">{{ child.title }}</div>
               </div>
            </div>
            <div class="group relative">
               <button class="bg-theme-active py-1 px-5 text-white rounded border-none" @click="router.push({ name: 'Signup', params: { type: 'agent' } })">Partner</button>
               <div class="h-[1px] opacity-0 w-1 mb-4 group-hover:w-full group-hover:opacity-100 transition-all ease-in-out duration-500"></div>  
            </div>
         </div>
         <div class="w-full text-center absolute top-0 left-0 w-full" :class="isMenuOpen ? 'animate__animated animate__slideInDown' : 'hidden'">
            <div class="z-20 relative bg-white h-fit flex flex-col items-start divide-y divide-theme-gray-7">
               <div @click="isMenuOpen=false" class="uppercase text-size-4 text-theme-gray-4 px-2 py-3 w-full text-left flex flex-row items-center justify-center">
                  <font-awesome-icon size="lg" icon="fa-solid fa-chevron-up" class="bottom-1" />
               </div>
               <div @click="handleMenuItemClick(item)" v-for="item in menuItems" :index="item.id" class="group uppercase text-size-4 text-theme-gray-4 px-2 py-3 w-full text-left">
                  {{ item.title }}
                  <div class="h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500"></div>
               </div>
            </div>
         </div>
         <div @click="isMenuOpen=true" class="md:hidden ml-auto">
            <div class="p-3 rounded-full flex flex-row items-center justify-center border">
               <svg width="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g stroke-width="0"></g>
                  <g stroke-linecap="round" stroke-linejoin="round"></g>
                  <g><path d="M1 12C1 11.4477 1.44772 11 2 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H2C1.44772 13 1 12.5523 1 12Z" fill="#556677"></path> <path d="M1 4C1 3.44772 1.44772 3 2 3H22C22.5523 3 23 3.44772 23 4C23 4.55228 22.5523 5 22 5H2C1.44772 5 1 4.55228 1 4Z" fill="#556677"></path> <path d="M1 20C1 19.4477 1.44772 19 2 19H22C22.5523 19 23 19.4477 23 20C23 20.5523 22.5523 21 22 21H2C1.44772 21 1 20.5523 1 20Z" fill="#556677"></path> </g>
               </svg>
            </div>
         </div>
      </div>
   </header>

</template>

<script setup lang="ts">
import { ref } from 'vue'
import 'animate.css'
import router from '@/router'
import { isLoggedIn, logout, getFirstName } from '@/utils/common'

const isMenuOpen = ref(false)
const auth = isLoggedIn()
const expandedItemId = ref<number | null>(null)

const menuItems : any = [
   { id: 1, title: 'Home', component: 'Home' },
   { id: 2, title: 'About us', component: 'AboutUs' },
   { id: 3, title: 'Our services', component: 'OurServices' },
   { id: 4, title: 'Contact us', component: 'ContactUs' }
]

if (auth) {

   menuItems.push({
      id: 6, title: 'Hello ' + getFirstName(auth.user.name), component: null, children: [
         {id: 1, title: 'My Dashboard', href: { name: 'AccountDashboard' }},
         {id: 2, title: 'Logout', href: 'logout'}
      ]
   })
} else {
   menuItems.push(
      { id: 5, title: 'Log in', component: 'Login' },
      {
         id: 6, title: 'Signup', component: null, children: [
            {id: 1, title: 'User', href: { name: 'Signup', params: { type: 'web' } }},
            {id: 2, title: 'Agent', href: { name: 'Signup', params: { type: 'agent' } }}
         ]
      }
   )
}

const handleTopNav = (item: any) => {

   if (item.component) {
      router.push({name: item.component})
   } else if (item.children && item.children.length) {
      expandedItemId.value = expandedItemId.value === item.id ? null : item.id
      console.log(expandedItemId.value)
   }
}

const handleSubNav = (child: any) => {

   if (child.href == 'logout') {
      logout(router)
   } else {
      router.push(child.href)
   }
}

const handleMenuItemClick = (item: any) => {

   if (item.component) {
      router.push({name: item.component})
      isMenuOpen.value = false
   }
}

</script>