import { getUserToken, getBackendBaseUrl } from "@/utils/common"
import { LeadForm } from '@/types/lead'
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const Lead = () => {

   const getHeaders = () => { 
      return {
         'Authorization': `Bearer ${getUserToken()}`,
         'Content-Type': 'application/json',
      }
   }

   const createLead = async (data: LeadForm) : Promise<any> => {
      try {
         let response

         if (getUserToken() == null) {
            response = await axios.post(baseUrl + 'api/lead/create', {data: data}, {headers: getHeaders()})
         } else {
            response = await axios.post(baseUrl + 'api/lead/protected/create', {data: data}, {headers: getHeaders()})
         }

         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      createLead
   }
}

export default Lead