<template>
   <TransitionRoot as="template" :show="show">
      <Dialog as="div" class="relative z-50" @close="closed">
         <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
         </TransitionChild>
   
         <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center xs:items-center xs:p-0 mx-4 font-inherit">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 xs:translate-y-0 xs:scale-95" enter-to="opacity-100 translate-y-0 xs:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 xs:scale-100" leave-to="opacity-0 translate-y-4 xs:translate-y-0 xs:scale-95">
               <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all xs:my-8 xs:w-full xs:max-w-lg xs:p-6">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 xs:block">
                     <button v-if="canClose == true" type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 outline-none" @click="closed">
                        <span class="sr-only">Close</span>
                        <font-awesome-icon icon="fa-solid fa-xmark" class="icon text-gray-500" />
                     </button>
                  </div>
                  <div class="font-inherit">
                     <div class="my-5 xs:mt-4 xs:flex xs:flex-row-reverse">
                        <slot name="content" />
                     </div>
                  </div>
               </DialogPanel>
            </TransitionChild>
            </div>
         </div>
      </Dialog>
   </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
const props = defineProps(['show', 'canClose'])
const emit = defineEmits(['closed'])

const closed = () => emit('closed')
</script>