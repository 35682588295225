import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 z-10 w-screen overflow-y-auto" }
const _hoisted_2 = { class: "flex min-h-full items-end justify-center p-4 text-center xs:items-center xs:p-0 mx-4 font-inherit" }
const _hoisted_3 = { class: "absolute right-0 top-0 hidden pr-4 pt-4 xs:block" }
const _hoisted_4 = { class: "font-inherit" }
const _hoisted_5 = { class: "xs:flex xs:items-start" }
const _hoisted_6 = { class: "mt-3 text-center xs:ml-4 xs:mt-0 xs:text-left text-head-3" }
const _hoisted_7 = { class: "mt-5 xs:mt-4 xs:flex xs:flex-row-reverse" }

import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmationModal',
  props: ['show', 'confirmationText'],
  emits: ['closed'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

const closed = () => emit('closed')

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_unref(TransitionRoot), {
    as: "template",
    show: __props.show
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Dialog), {
        as: "div",
        class: "relative z-50",
        onClose: closed
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(TransitionChild), {
            as: "template",
            enter: "ease-out duration-300",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "ease-in duration-200",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(TransitionChild), {
                as: "template",
                enter: "ease-out duration-300",
                "enter-from": "opacity-0 translate-y-4 xs:translate-y-0 xs:scale-95",
                "enter-to": "opacity-100 translate-y-0 xs:scale-100",
                leave: "ease-in duration-200",
                "leave-from": "opacity-100 translate-y-0 xs:scale-100",
                "leave-to": "opacity-0 translate-y-4 xs:translate-y-0 xs:scale-95"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DialogPanel), { class: "relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all xs:my-8 xs:w-full xs:max-w-lg xs:p-6" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "rounded-md bg-white text-gray-400 hover:text-gray-500 outline-none",
                          onClick: closed
                        }, [
                          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "sr-only" }, "Close", -1)),
                          _createVNode(_component_font_awesome_icon, {
                            icon: "fa-solid fa-xmark",
                            class: "icon text-gray-500"
                          })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, _toDisplayString(__props.confirmationText && __props.confirmationText != '' ? __props.confirmationText : 'Are you sure to continue?'), 1)
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _renderSlot(_ctx.$slots, "buttons")
                        ])
                      ])
                    ]),
                    _: 3
                  })
                ]),
                _: 3
              })
            ])
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["show"]))
}
}

})