import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0" }
const _hoisted_2 = { class: "grid grid-cols-12 gap-4 md:gap-10" }

import ContactGoogleMap from '@/components/ContactGoogleMap.vue'
import ContactForm from '@/components/ContactForm.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactUs',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ContactGoogleMap),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"col-span-12 md:col-span-4 flex flex-col mb-4 md:mb-0\"><div class=\"flex flex-col bg-theme-gray-1 px-4 py-8\"><div class=\"text-theme-gray-5 text-size-6 font-bold mb-3\">Vehitra Tech W.L.L</div><div class=\"flex flex-col divide-y divide-theme-gray-9 gap-5\"><div class=\"flex flex-col gap-2 pt-4\"><div class=\"text-theme-gray-2 uppercase text-small\">Address</div><div class=\"text-theme-gray-5 text-size-3\">312 Falcon Tower<br>Road 1701 Block 317 Bldg 60<br>Diplomatic Area Manama<br>kingdom of Bahrain</div></div><div class=\"flex flex-col gap-2 pt-4\"><div class=\"text-theme-gray-2 uppercase text-small\">Book a ride</div><div class=\"text-theme-gray-5 text-size-3\">+973 3214 1440</div></div><div class=\"flex flex-col gap-2 pt-4\"><div class=\"text-theme-gray-2 uppercase text-small\">Office (Time: 24/7)</div><div class=\"text-theme-gray-5 text-size-3\">+973 17001550</div></div><div class=\"flex flex-col gap-2 pt-4\"><div class=\"text-theme-gray-2 uppercase text-small\">Contact us via email</div><div class=\"text-theme-gray-5 text-size-3\">bookings@vehitraworld.com</div></div></div></div></div>", 1)),
        _createVNode(ContactForm)
      ])
    ])
  ], 64))
}
}

})