import { getBackendBaseUrl, getUserToken } from "@/utils/common"
import { LocationSchedule } from '@/types/index'
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const Country = () => {

   const getHeaders = () => { 
      return {
         'Content-Type': 'application/json'
      }
   }
   
   const countriesList = async () : Promise<any> => {
      try {
         
         const response = await axios.get(baseUrl + 'api/countries/list', {params: {},headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const getUserCountry = async () : Promise<any> => {
      try {
         
         const response = await axios.get(baseUrl + 'api/countries/current', {params: {},headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      countriesList,
      getUserCountry
   }
}

export default Country