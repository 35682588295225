import { getUserToken, getBackendBaseUrl } from "@/utils/common"
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const BookingCredit = () => {

   const getHeaders = () => { 
      return {
         'Authorization': `Bearer ${getUserToken()}`,
         'Content-Type': 'application/json',
      }
   }

   const getCreditInfo = async (bookingNumber: number) : Promise<any> => {
      try {
         let response

         if (getUserToken() !== null) {
            response = await axios.get(`${baseUrl}api/credit/get-booking-credit/${bookingNumber}`, {headers: getHeaders()})
         }

         return response
      } catch (err : any) {
         return err
      }
   }
   
   const withdrawFromCredit = async (bookingNumber: number) : Promise<any> => {
      try {
         let response

         if (getUserToken() !== null) {
            response = await axios.put(baseUrl + 'api/credit/withdraw', { booking: bookingNumber }, {headers: getHeaders()})
         }

         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      getCreditInfo,
      withdrawFromCredit
   }
}

export default BookingCredit