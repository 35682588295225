<template>
   <section id="mainbanner" class="bg-center bg-no-repeat bg-cover banner-img-planmytrip">
         <div class="flex flex-col mx-auto w-full lg:w-theme-dw py-24 px-11 md:px-0">
            <h2 class="font-bold text-size-4 md:text-size-6 uppercase text-white mb-4">Tailored Travel Experiences: Build Your Journey, Choose Your Transfers, Select Your Chauffeurs.</h2>
            <div class="flex flex-col text-white mb-12">
               <div class="text-head-md-3 md:text-head-lg">Seamless Mobility, Your Way</div>
               <div class="text-head-md-3 md:text-head-lg">Craft Your Perfect Trip with Custom Transfers and Chauffeurs. Request a Quote Today!</div>
            </div>
         </div>
   </section>
   <div v-if="submited" class="flex flex-col pb-12 px-11 lg:w-theme-dw md:mx-auto md:px-0">
      <div class="col-span-12 md:pb-12 flex flex-col items-center justify-center gap-6 mt-16">
         <div class="relative">
            <div class="flex items-center justify-center absolute -left-3 w-[100px] h-[100px] rounded-full border border-theme-gray-7">
               <font-awesome-icon size="2xl" icon="fa-solid fa-check" class="text-theme-active" />
            </div>
            <div class="bg-theme-gray-1 w-[100px] h-[100px] rounded-full"></div>
         </div>
         <h3 class="text-theme-gray-5 text-head-md-2 md:text-head-xl text-center flex flex-col gap-3">
            <div>Thank You</div>
            <div>Your details have been sent to our team</div>
         </h3>
         <h5 class="text-head-sm text-center text-theme-gray-5">Our representative will contact you shortly</h5>
      </div>
   </div>

   <div v-else class="flex flex-col pb-12 px-11 lg:w-theme-dw md:mx-auto md:px-0">
      
      <div v-if="service == ''" class="rounded-md bg-yellow-50 py-8 px-4 mb-12">
         <div class="flex flex-col md:flex-row items-center ml-3 w-full">
               <div class="flex flex-col ">
                  <h3 class="text-sm font-medium text-yellow-800">The route you entered is not available at the moment</h3>
                  <div class="mt-2 text-sm text-yellow-700 flex w-full items-center">
                        <p>Please fill out the form below. Our operations team will try their best to help you</p>
                  </div>
               </div>
               <div @click="handleEditSearch" class="rounded-lg text-sm mr-auto mt-4 md:mt-0 md:ml-auto md:mr-4 whitespace-nowrap font-medium text-yellow-800 hover:text-yellow-900 cursor-pointer p-2 border border-yellow-800">
                  Edit My Search
               </div>
         </div>
      </div>

      <div class="pb-24 pt-12 sm:px-6 sm:pb-32 sm:pt-18 lg:px-8 bg-gray-100">
         <div class="space-y-10 divide-y divide-gray-900/10">
            <div class="grid grid-cols-1 gap-x-8 gap-y-8  md:grid-cols-3">
               <div class="px-4 sm:px-0">
                  <h2 class="text-base font-semibold leading-7 text-gray-900">Multiple Requests</h2>
                  <p class="mt-1 text-sm leading-6 text-gray-600">Choose what services you want.</p>
               </div>
         
               <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                  <div class="px-4 py-6 sm:p-8">
                     
                     
                     <div class="mt-10">
                        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                           <div class="sm:col-span-3">
                              <label class="block text-sm font-medium leading-6 text-gray-900">No of people *</label>
                              <div class="mt-2">
                                 <input v-model="leadForm.people" type="number" min="0" :class="errors.people ? 'text-red-900 ring-red-300' : 'text-gray-900 ring-gray-300'" class="block pl-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                              </div>
                              <p v-if="errors.people" class="mt-1 text-sm text-red-600">Required</p>
                           </div>
                           <div class="sm:col-span-3">
                              <label class="block text-sm font-medium leading-6 text-gray-900">Estimated luggage bags</label>
                              <div class="mt-2">
                                 <input v-model="leadForm.luggage" type="number" min="0" class="block pl-2 w-full text-gray-900 ring-gray-300 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="flex flex-col gap-3 mt-5 md:mt-10">
                        <label class="block text-sm font-medium leading-6 text-gray-900">Service type</label>
                        <div class="flex items-center gap-6">
                           <div class="relative flex items-start gap-2">
                              <div class="flex h-6 items-center">
                                 <input id="transfer" v-model="leadForm.subServices" value="transfer" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                              </div>
                              <div class="text-sm leading-6">
                                 <label for="transfer" class="font-medium text-gray-500">Transfer</label>
                              </div>
                           </div>
                           <div class="relative flex items-start gap-2">
                              <div class="flex h-6 items-center">
                                 <input id="chauffer" v-model="leadForm.subServices" value="chauffeur" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                              </div>
                              <div class="text-sm leading-6">
                                 <label for="chauffer" class="font-medium text-gray-500">Chauffeur</label>
                              </div>
                           </div>
                           <div class="relative flex items-start gap-2">
                              <div class="flex h-6 items-center">
                                 <input id="buses_coaches" v-model="leadForm.subServices" value="buses_coaches" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                              </div>
                              <div class="text-sm leading-6">
                                 <label for="buses_coaches" class="font-medium text-gray-500">Buses and coaches</label>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div v-if="leadForm.subServices?.includes('transfer') || leadForm.subServices?.includes('chauffer')" class="mt-5 md:mt-10">
                        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-5 md:mb-10">
                           <div v-if="leadForm.subServices?.includes('transfer')" class="sm:col-span-3">
                              <label class="block text-sm font-medium leading-6 text-gray-900">No of transfers</label>
                              <div class="mt-2">
                                 <input v-model="trip.transfers" type="text" min="0" class="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                                 <p v-if="errors.trip.transfers" class="mt-1 text-sm text-red-600">Required</p>
                              </div>
                           </div>
                           <div v-if="leadForm.subServices?.includes('chauffer')" class="sm:col-span-3">
                              <label class="block text-sm font-medium leading-6 text-gray-900">No of chauffeurs</label>
                              <div class="mt-2">
                                 <input v-model="trip.chauffers" type="text" min="0" class="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                                 <p v-if="errors.trip.chauffers" class="mt-1 text-sm text-red-600">Required</p>
                              </div>
                           </div>
                        </div>
                        <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 xl:gap-x-8">
                           <li v-for="(item, index) in trip.items" :key="index" class="rounded-xl border border-gray-200">
                              <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                 <span :class="item.type == 'transfer' ? 'dual-maps-icon text-[30px] ' : 'clock-icon text-[25px]'" class="font-icon theme-icon text-theme-active"></span>
                                 <div class="text-lg uppercase font-medium leading-6 text-gray-900 capitalize">{{ item.type }} service</div>
                              </div>
                              <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                 <div class="flex justify-between gap-x-4 py-3">
                                    <div class="flex text-gray-500 w-full">
                                       <div :class="item.errors.pickUpName ? 'text-red-600' : 'font-medium'">Pick-up *: </div>
                                       <div class="mt-[1px] flex-1 relative">
                                          <input v-model="item.pickUp.name" @input="handleAddressSearch(item, 'pick-up')" class="w-full outline-none pl-2 w-full placeholder-gray-300" type="text" placeholder="Add pick-up address e.g dubai international airport">
                                          <ul v-if="item.pickUp.isAddressDropOpen && item.pickUp.addresses && item.pickUp.addresses.length" class="z-10 !mt-0 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                             <li @click="handleAddressSelect(item, address, 'pick-up')" v-for="(address, index) in item.pickUp.addresses" :key="index" class="flex flex-row items-start gap-2 py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer">
                                                <span v-if="address.isAirport" class="mt-1">
                                                   <font-awesome-icon size="1x" icon="fa-solid fa-plane" />
                                                </span>
                                                <span v-else class="mt-[0.13rem]">
                                                   <font-awesome-icon size="1x" icon="fa-solid fa-location-dot" />
                                                </span>
                                                <span>{{ address.name }}</span>
                                             </li>
                                          </ul>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="flex justify-between gap-x-4 py-3">
                                    <div class="flex text-gray-500 w-full">
                                       <div :class="item.errors.pickUpDate ? 'text-red-600' : 'font-medium'">Date *: </div>
                                       <div class="mt-[1px] flex-1">
                                          <flat-pickr v-model="item.pickUp.date" :config="dateConfig" class="w-full outline-none pl-2 w-full placeholder-gray-300" placeholder="Select pick-up date" />
                                       </div>
                                    </div>
                                 </div>
                                 <div class="flex justify-between gap-x-4 py-3">
                                    <div class="flex text-gray-500 w-full">
                                       <div :class="item.errors.pickUpTime ? 'text-red-600' : 'font-medium'">Time *: </div>
                                       <div class="mt-[1px] flex-1">
                                          <flat-pickr v-model="item.pickUp.time" :config="timeConfig" class="w-full outline-none pl-2 w-full placeholder-gray-300" placeholder="Select pick-up time" />
                                       </div>
                                    </div>
                                 </div>
                                 <div v-if="item.type == 'transfer'" class="flex justify-between gap-x-4 py-3">
                                    <div class="flex text-gray-500 w-full">
                                       <div :class="item.errors.dropOffName ? 'text-red-600' : 'font-medium'">Drop-off *: </div>
                                       <div class="mt-[1px] flex-1 relative">
                                          <input v-model="item.dropOff.name" @input="handleAddressSearch(item, 'drop-off')" class="w-full outline-none pl-2 w-full placeholder-gray-300" type="text" placeholder="Add drop-off e.g 123-B, bakers street, dubai">
                                          <ul v-if="item.dropOff.isAddressDropOpen && item.dropOff.addresses && item.dropOff.addresses.length" class="z-10 !mt-0 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                             <li @click="handleAddressSelect(item, address, 'drop-off')" v-for="(address, index) in item.dropOff.addresses" :key="index" class="flex flex-row items-start gap-2 py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer">
                                                <span v-if="address.isAirport" class="mt-1">
                                                   <font-awesome-icon size="1x" icon="fa-solid fa-plane" />
                                                </span>
                                                <span v-else class="mt-[0.13rem]">
                                                   <font-awesome-icon size="1x" icon="fa-solid fa-location-dot" />
                                                </span>
                                                <span>{{ address.name }}</span>
                                             </li>
                                          </ul>
                                       </div>
                                    </div>
                                 </div>
                                 <div v-else-if="item.type == 'chauffer'" class="flex justify-between gap-x-4 py-3">
                                    <div class="flex text-gray-500 w-full">
                                       <div class="font-medium">Days : </div>
                                       <div class="mt-[1px] flex-1">
                                          <select v-model="item.days" class="ml-2 w-full cursor-pointer rounded-md border-0 pl-2 pr-10 text-gray-500 bg-transparent ring-gray-300 outline-none sm:text-sm sm:leading-6" placeholder="No of days">
                                             <option v-for="(day, index) in days" :key="index" :value="day">{{ day }} day(s)</option>
                                          </select>
                                       </div>
                                    </div>
                                 </div>
                              </dl>
                           </li>
                        </ul>                     
                     </div>
                  </div>
               </div>
            </div>
            <div v-if="leadForm.subServices?.includes('buses_coaches')" class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
               <div class="px-4 sm:px-0">
                  <h2 class="text-base font-semibold leading-7 text-gray-900">Pick up</h2>
                  <p class="mt-1 text-sm leading-6 text-gray-600">Provide pick up details</p>
               </div>
         
               <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                  <div class="px-4 py-6 sm:p-8">
                     <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div class="sm:col-span-3">
                           <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Flight Number <span v-if="leadForm.service != 'meet_greet'">(optional)</span><span v-else> *</span></label>
                           <div class="mt-2">
                              <input v-model="leadForm.pickUp.flightNumber" type="text" class="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="grid grid-cols-1 gap-x-8 pt-10 gap-y-8 md:grid-cols-3">
               <div class="px-4 sm:px-0">
                  <h2 class="text-base font-semibold leading-7 text-gray-900">Guest Details</h2>
                  <p class="mt-1 text-sm leading-6 text-gray-600">Provide the guest details</p>
               </div>
         
               <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                  <div class="px-4 py-6 sm:p-8">
                     <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div class="sm:col-span-3">
                           <label class="block text-sm font-medium leading-6 text-gray-900">Full name *</label>
                           <div class="mt-2">
                              <input v-model="leadForm.guest.name" type="text" :class="errors.guest.name ? 'text-red-900 ring-red-300' : 'text-gray-900 ring-gray-300'" class="block pl-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                           </div>
                           <p v-if="errors.guest.name" class="mt-1 text-sm text-red-600">Required</p>
                        </div>
            
                        <div class="sm:col-span-3">
                           <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Contact Number *</label>
                           <div class="mt-2">
                              <input v-model="leadForm.guest.contactNumber" type="text" :class="errors.guest.contactNumber ? 'text-red-900 ring-red-300' : 'text-gray-900 ring-gray-300'" class="block pl-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                           </div>
                           <p v-if="errors.guest.contactNumber" class="mt-1 text-sm text-red-600">Required</p>
                        </div>
            
                        <div class="sm:col-span-3">
                           <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address *</label>
                           <div class="mt-2">
                              <input v-model="leadForm.guest.email" type="email" :class="errors.guest.email ? 'text-red-900 ring-red-300' : 'text-gray-900 ring-gray-300'" class="block pl-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                           </div>
                           <p v-if="errors.guest.email" class="mt-1 text-sm text-red-600">{{ errors.guest.email }}</p>
                        </div>
            
                        <div class="sm:col-span-6">
                           <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Message</label>
                           <div class="mt-2">
                              <textarea v-model="leadForm.guest.message" rows="6" :class="errors.guest.message ? 'text-red-900 ring-red-300' : 'text-gray-900 ring-gray-300'" class="block pl-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6" />
                           </div>
                           <p v-if="errors.guest.message" class="mt-1 text-sm text-red-600">Required</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="w-fit ml-auto mt-6">
         <button @click="handleSubmit" type="button" :class="isBusy ? 'opacity-70 cursor-not-allowed' : 'hover:bg-indigo-500'" class="rounded-md bg-indigo-600 px-12 py-2 text-sm font-semibold text-white shadow-sm outline-none">Submit</button>
      </div>
      <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
   </div>

</template>

<script setup lang="ts">
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import Location from '@/composables/Location'
import { GoogleLocationType } from '@/types'
import { handleApiError, isValidEmail, sortPlacePredictions } from '@/utils/common'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import { LeadForm } from '@/types/lead'
import Lead from '@/composables/Lead'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'
import { useRouter, parseQuery } from 'vue-router'
import { retrieveUserSearch, saveUserTempSearch } from '@/services/search-service'
import { TripleDES } from 'crypto-js'

const props = defineProps(['service'])
const router = useRouter()
let searchTimeout: ReturnType<typeof setTimeout> | null = null
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const { searchLocation } = Location()
const isBusy = ref<boolean>(false)
const { createLead } = Lead()
const SEARCH_DELAY = 400
const days : number[] = [1,2,3,4,5,6,7,8,9,10]
const error = ref<string | false>(false)
const submited = ref<boolean>(false)
const trip = ref<any>({
   transfers: '',
   chauffers: '',
   items: []
})
const leadForm = ref<LeadForm>({
   service: 'plan_trip',
   subServices: ['transfer', 'chauffer'],
   trip: trip.value,
   pickUp: {
      name: '',
      date: '',
      time: '',
      flightNumber: '',
      addresses: null,
      isAddressDropOpen: false
   },
   guest: {
      name: '',
      contactNumber: '',
      email: '',
      message: ''
   },
   people: 0,
   luggage: 0 
})

const errors = ref<any>({
   trip: {
      transfers: false,
      chauffers: false
   },
   pickUp: {
      name: false,
      date: false,
      time: false,
      flightNumber: false
   },
   guest: {
      name: false,
      contactNumber: false,
      email: false,
      message: false
   },
   people: false
})

const dateConfig : any = {
   enableTime: false,
   altFormat: 'd-m-Y',
   altInput: true,
   dateFormat: 'd-m-Y'
}

const timeConfig : any = {
   enableTime: true,
   noCalendar: true,
   dateFormat: 'h:i K'
}

const handleEditSearch = () => {
   const search = retrieveUserSearch()
   saveUserTempSearch(search)
   router.push({name: 'Home'})
}

const handleServiceChange = () => {
   
}

const addItems = (type: string, diff: number) => {
   for (let i = 0; i < diff; i++) {
      let item = { type: type, pickUp: { name: '', date: '', time: '' }, dropOff: {name: ''}, days: 1, errors: {pickUpName: false, pickUpDate: false, pickUpTime: false, dropOffName: false} }
      trip.value.items.push(item)
   }
}

const removeItems = (type: string, diff: number) => {
   const excessTransferItems = trip.value.items.filter((item: any) => item.type === type).slice(diff)
   excessTransferItems.forEach((item: any) => {
      const indexToRemove = trip.value.items.indexOf(item)
      trip.value.items.splice(indexToRemove, 1)
   })
}

watch(() => trip.value.transfers, (newValue, oldValue) => {
   const transferItems = trip.value.items.filter((item: any) => item.type === 'transfer')
   const diff = newValue - transferItems.length

   if (diff > 0) {
      addItems('transfer', diff)
   } else if (diff < 0) {
      removeItems('transfer', diff)
   }
})

watch(() => trip.value.chauffers, (newValue, oldValue) => {
   const transferItems = trip.value.items.filter((item: any) => item.type === 'chauffer')
   const diff = newValue - transferItems.length

   if (diff > 0) {
      addItems('chauffer', diff)
   } else if (diff < 0) {
      removeItems('chauffer', diff)
   }
})

watch(() => leadForm.value.subServices, (newValue, oldValue) => {
   if (!leadForm.value.subServices?.includes('transfer')) {
      trip.value.transfers = ''
   }

   if (!leadForm.value.subServices?.includes('chauffer')) {
      trip.value.chauffers = ''
   }
})

const handleAddressSearch = async (item: any, type: 'pick-up' | 'drop-off') => {
   const target = type == 'pick-up' ? item.pickUp : item.dropOff
   const addr = target.name.toLowerCase().trim()

   if (addr.length > 2) {

      if (searchTimeout) {
         clearTimeout(searchTimeout)
      }

      searchTimeout = setTimeout(async () => {

         const response = await searchLocation(addr)
         if (response.status == 200) {

            let adds : GoogleLocationType[] = []
            const predictions = sortPlacePredictions(response.data.data)
            predictions.forEach((item: any) => adds.push({ id: item.id, name: item.name, isAirport: item.isAirport, coordinates: {lat: null, lng: null} }))
            target.addresses = adds
            target.isAddressDropOpen = adds.length ? true : false
         } else {
            error.value = handleApiError(response)
         }
         
      }, SEARCH_DELAY)
   }
}

const handleAddressSelect = (item: any, address: GoogleLocationType, type: 'pick-up' | 'drop-off') => {
   const target = type == 'pick-up' ? item.pickUp : item.dropOff

   target.name = address.name

   if (target.isAddressDropOpen) {
      target.isAddressDropOpen = false
   }

   if (item.isAddressDropOpen) {
      item.isAddressDropOpen = false
   }
}

const validateForm = () => {

   let valid = true

   if (leadForm.value.guest.name == '') {
      valid = false
      errors.value.guest.name = true
   }

   if (leadForm.value.guest.contactNumber == '') {
      valid = false
      errors.value.guest.contactNumber = true
   }

   if (leadForm.value.guest.message == '') {
      valid = false
      errors.value.guest.message = true
   }

   if (leadForm.value.guest.email == '') {
      valid = false
      errors.value.guest.email = 'Required'
   } else if (!isValidEmail(leadForm.value.guest.email)) {
      valid = false
      errors.value.guest.email = 'Email is invalid'
   }

   if ((leadForm.value.service == 'meet_greet' || leadForm.value.service == 'buses_coaches') && (leadForm.value.people <= 0)) {
      valid = false
      errors.value.people = true
   }

   if (leadForm.value.service == 'plan_trip') {
      valid = validateTrips(valid)
   }

   if (leadForm.value.subServices?.includes('transfer') && (trip.value.transfers == '' || trip.value.transfers.length == 0)) {
      errors.value.trip.transfers = true
      valid = false
   }
   
   if (leadForm.value.subServices?.includes('chauffer') && (trip.value.chauffers == '' || trip.value.chauffers.length == 0)) {
      errors.value.trip.chauffers = true
      valid = false
   }

   return valid
}

const validateTrips = (isValid: boolean) : boolean => {

   let valid = isValid

   trip.value.items.forEach((item: any) => {

      let dropOffName = false
      let pickUpDate = false
      let pickUpTime = false
      let pickUpName = false

      if (item.pickUp.name == '') {
         valid = false
         pickUpName = true
      } 

      if (item.pickUp.date == null || item.pickUp.date == '') {
         valid = false
         pickUpDate = true
      }

      if (item.pickUp.time == null || item.pickUp.time == '') {
         valid = false
         pickUpTime = true
      }

      if (item.type == 'transfer' && item.dropOff.name == '') {
         valid = false
         dropOffName = true
      }

      item.errors = { pickUpName: pickUpName, pickUpDate: pickUpDate, pickUpTime: pickUpTime, dropOffName: dropOffName }
   })

   return valid
}

const handleSubmit = async () => {

   resetErrors()
   const isValid = validateForm()

   if (isValid && !isBusy.value) {

      isBusy.value = true
      const response = await createLead(leadForm.value)
      isBusy.value = false

      if (response.status == 201) {
         submited.value = true
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      }
   }
}

const notifClosed = () : void => {
   notif.value.show = false
}

const resetErrors = () => {
   errors.value = {
      pickUp: { name: false, date: false, time: false },
      guest:  {name: false, contactNumber: false, email: false},
      trip:  {transfers: false, chauffers: false},
      people: false,
      luggage: false
   }
}

const listener = () => {
   trip.value.items.forEach((item: any) => {
      if (item.type == 'transfer') {
         item.pickUp.isAddressDropOpen = false
         item.dropOff.isAddressDropOpen = false
      } else if (item.type == 'chauffer') {
         item.pickUp.isAddressDropOpen = false
      }
   })

   leadForm.value.pickUp.isAddressDropOpen = false
}

onMounted(() => {
   document.addEventListener('click', listener)
})

onBeforeUnmount(() => {
   document.removeEventListener('click', listener)
})

</script>