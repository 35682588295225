
export const BOOKING_STEPS = [
   { id: 1, title: 'Enter Ride Details', uri: 'ride-details', isActive: false, isCompleted: false },
   { id: 2, title: 'Choose a Vehicle', uri: 'choose-vehicle', isActive: false, isCompleted: false },
   { id: 3, title: 'Enter Contact Details', uri: 'contact-details', isActive: false, isCompleted: false }
]

export const PASSENGERS = [
   { title: 'Any', value: '' },
   { title: '1', value: 1 },
   { title: '2', value: 2 },
   { title: '3', value: 3 },
   { title: '4', value: 4 },
   { title: '5', value: 5 },
   { title: '6', value: 6 },
]

export const SUITCASES = [
   { title: 'Any', value: '' },
   { title: '1', value: 1 },
   { title: '2', value: 2 },
   { title: '3', value: 3 },
   { title: '4', value: 4 },
   { title: '5', value: 5 },
   { title: '6', value: 6 },
]

export const STANDARDS = [
   { title: '1', value: 1 },
   { title: '2', value: 2 },
   { title: '3', value: 3 },
   { title: '4', value: 4 }
]

export const DOORS = [
   { title: 'Any', value: '' },
   { title: '2', value: 2 },
   { title: '4', value: 4 },
   { title: '6', value: 6 },
]

export const TRANSMISSION_TYPES = [
   { title: 'Any', value: '' },
   { title: 'Automatic', value: 'automatic' },
   { title: 'Manual', value: 'manual' }
]

export const VEHICLE_TYPES = [
   { title: '- All vehicles -', value: 'all' },
   { title: 'Limousine', value: 'limousine' },
   { title: 'Stretch Limousine', value: 'stretch-limousine' },
   { title: 'Van', value: 'van' }
]

export const STATUSES = [
   { id: 1, name: 'incomplete', title: 'Incomplete' },
   { id: 2, name: 'confirmed', title: 'Confirmed' },
   { id: 3, name: 'awaiting_payment', title: 'Awaiting Payment' },
   { id: 4, name: 'need_confirmation', title: 'Need Confirmation' },
   { id: 5, name: 'cancelled', title: 'Cancelled' },
   { id: 6, name: 'need_confirmation', title: 'Unconfirmed' },
]

export const credimax = {
   'base_url' : 'https://credimax.gateway.mastercard.com/api/rest/version/77',
   'sdk' : 'https://credimax.gateway.mastercard.com/static/threeDS/1.3.0/three-ds.min.js',
   'merchant_id' : 'E11742953',
   'password' : '3305f8de5c06a8efa770d8d5f8ad80a6'
}

export const LOADER_MINIMUM_TIME = 2000

export const APPLE_PAY_MERCHANT = 'merchant.com.vehitraworld.payment'
