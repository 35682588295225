import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-0 md:w-[50%] mx-auto"
}

import { onMounted, ref } from 'vue'
import Invoice from '@/components/booking/Invoice.vue'
import { Booking } from '@/types'
import BookingService from '@/services/booking-service'
import { getSessionPrefix } from '@/utils/common'
import router from '@/router'
// @ts-ignore
import html2pdf from 'html2pdf.js'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UserBookingInvoice',
  props: ['number'],
  setup(__props) {

const props = __props
const data = ref<Booking | null>(null)
const isLoading = ref<boolean>(true)
const { readBooking } = BookingService()
const pdfContent = ref(null)
const openConfirmModal = ref<boolean>(false)

onMounted(async () => {
   
   const prefix = getSessionPrefix()

   const response = await readBooking(props.number)
   isLoading.value = false
   
   if (response.success) {
      data.value = response.data.data
   } else {
      console.log(response.error)
   }
})

const downloadPDF = () => {

   const content = pdfContent.value
   html2pdf(content).from(content).save()
}

const handleCancelBooking = () => {

   openConfirmModal.value = true
}

const handleMakePayment = () => {

   openConfirmModal.value = true
}

const handleConfirmClose = () => openConfirmModal.value = false


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            ref_key: "pdfContent",
            ref: pdfContent,
            class: "px-6 mx-auto"
          }, [
            _createVNode(Invoice, { data: data.value }, null, 8, ["data"])
          ], 512),
          _createElementVNode("div", { class: "flex flex-row items-center gap-3 justify-end" }, [
            _createElementVNode("button", {
              onClick: downloadPDF,
              type: "button",
              class: "rounded-md bg-theme-button2-bg px-2.5 py-1.5 text-sm font-semibold text-theme-button2-txt shadow-sm"
            }, " Download PDF ")
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(ConfirmationModal, {
      show: openConfirmModal.value,
      onClosed: handleConfirmClose
    }, {
      buttons: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass([isLoading.value ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer', "inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto"])
        }, "Yes, proceed", 2),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (openConfirmModal.value = false)),
          type: "button",
          class: "mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto"
        }, "Cancel")
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}
}

})