import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 2 }

import { ref, computed } from 'vue'
import Main from '@/layouts/Main.vue'
import Account from '@/layouts/Account.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { useRoute } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute()
const layout = computed(() => {
   return route.meta.layout
})


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (layout.value == 'web')
    ? (_openBlock(), _createBlock(Main, { key: 0 }, {
        header: _withCtx(() => [
          _createVNode(Header)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        footer: _withCtx(() => [
          _createVNode(Footer)
        ]),
        _: 1
      }))
    : (layout.value == 'account' || (layout.value == 'admin' && (_ctx.$route.name != 'AdminLogin')))
      ? (_openBlock(), _createBlock(Account, { key: 1 }, {
          content: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_router_view)
        ]))
}
}

})