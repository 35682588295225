
export const getDateDisplayFormat = () => {

   return {
      altFormat: 'd-m-Y',
      altInput: true,
      dateFormat: 'd-m-Y',
      enable: [{from:formatDate(new Date()),to:new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())}]  
   }
}

export function formatDate(date: any) {
   const day = String(date.getDate()).padStart(2, '0');
   const month = String(date.getMonth() + 1).padStart(2, '0');
   const year = date.getFullYear();

   return `${day}-${month}-${year}`;
}