import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-2" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 1,
  class: "font-bold text-theme-gray-5 text-size-6"
}
const _hoisted_5 = {
  key: 2,
  ref: "dropdownRef",
  class: "z-10 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 3,
  class: "ml-auto"
}

import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { generateTimeArray, validateTimeValue } from '@/utils/common'


export default /*@__PURE__*/_defineComponent({
  __name: 'TimeField',
  props: ['layout', 'hasError', 'data'],
  emits: ['timeChanged'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const isTimeDropOpen = ref<boolean>(false)
const times : string[] = generateTimeArray()
const selectedTime = ref<string>('')
const componentRef = ref()

watch(selectedTime, (newValue, oldValue) => {
   let val : string | null = newValue
   
   if (newValue === '') {
      val = null
   }

  emit('timeChanged', val)
})

const toggleTimeDrop = () => {
   isTimeDropOpen.value = !isTimeDropOpen.value;
}

const listener = (event: any) => {
   if (event.target !== componentRef.value) {
      isTimeDropOpen.value = false
   }
}

onMounted(() => {
   document.addEventListener('click', listener)

   if (props.data) {
      selectedTime.value = props.data
   }
})

onBeforeUnmount(() => {
   document.removeEventListener('click', listener)
})


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["p-5 relative", __props.layout == 'simple' ? 'col-span-7 md:col-span-1' : 'col-span-8 md:col-span-4'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([__props.hasError ? 'text-theme-error' : 'text-theme-gray-2', "flex gap-2 uppercase text-small"])
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("span", null, "Pickup time", -1)
      ]), 2),
      _createElementVNode("div", _hoisted_2, [
        (__props.layout=='simple')
          ? (_openBlock(), _createElementBlock("input", {
              key: 0,
              onClick: toggleTimeDrop,
              ref_key: "componentRef",
              ref: componentRef,
              onKeypress: _cache[0] || (_cache[0] = ($event: any) => (_unref(validateTimeValue)($event))),
              type: "text",
              class: "w-[90%] font-bold text-theme-gray-5 text-size-6 border-0 outline-none text-left",
              value: selectedTime.value
            }, null, 40, _hoisted_3))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(selectedTime.value), 1)),
        (isTimeDropOpen.value)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(times), (time, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  onClick: ($event: any) => (selectedTime.value=time),
                  class: "py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer"
                }, _toDisplayString(time), 9, _hoisted_6))
              }), 128))
            ], 512))
          : _createCommentVNode("", true),
        (__props.hasError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_font_awesome_icon, {
                size: "1x",
                icon: "fa-solid fa-circle-exclamation",
                class: "text-theme-error"
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}
}

})