import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 overflow-hidden" }
const _hoisted_2 = { class: "absolute inset-0 overflow-hidden" }
const _hoisted_3 = { class: "pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10" }
const _hoisted_4 = { class: "absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4" }
const _hoisted_5 = { class: "h-full overflow-y-auto bg-white p-8" }

import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SlideOver',
  props: ['open'],
  emits: ['closed'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

const closed = () => emit('closed')

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_unref(TransitionRoot), {
    as: "template",
    show: __props.open
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Dialog), {
        as: "div",
        class: "relative z-40",
        onClose: closed
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(TransitionChild), {
            as: "template",
            enter: "ease-in-out duration-500",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "ease-in-out duration-500",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(TransitionChild), {
                  as: "template",
                  enter: "transform transition ease-in-out duration-500 sm:duration-700",
                  "enter-from": "translate-x-full",
                  "enter-to": "translate-x-0",
                  leave: "transform transition ease-in-out duration-500 sm:duration-700",
                  "leave-from": "translate-x-0",
                  "leave-to": "translate-x-full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(DialogPanel), { class: "pointer-events-auto relative w-96" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(TransitionChild), {
                          as: "template",
                          enter: "ease-in-out duration-500",
                          "enter-from": "opacity-0",
                          "enter-to": "opacity-100",
                          leave: "ease-in-out duration-500",
                          "leave-from": "opacity-100",
                          "leave-to": "opacity-0"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_4, [
                              _createElementVNode("button", {
                                type: "button",
                                class: "relative rounded-md text-gray-300 hover:text-white outline-none",
                                onClick: closed
                              }, [
                                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "absolute -inset-2.5" }, null, -1)),
                                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sr-only" }, "Close panel", -1)),
                                _createVNode(_component_font_awesome_icon, {
                                  size: "lg",
                                  icon: "fa-solid fa-times",
                                  class: "text-black"
                                })
                              ])
                            ])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_5, [
                          _renderSlot(_ctx.$slots, "content")
                        ])
                      ]),
                      _: 3
                    })
                  ]),
                  _: 3
                })
              ])
            ])
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["show"]))
}
}

})