
import { getBackendBaseUrl } from "@/utils/common"
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const AdminAuth = () => {

   const login = async (email : string, password: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/admin/login', { email: email, password: password })
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      login
   }
}

export default AdminAuth