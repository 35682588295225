import { getBackendBaseUrl, getUserToken } from "@/utils/common"
import { LocationSchedule } from '@/types/index'
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const Contact = () => {

   const sendContactNotification = async (name: string, email: string, subject: string, message: string, recaptcha: any) : Promise<any> => {
      try {
         
         const response = await axios.post(baseUrl + 'api/contact/send-notification', {name: name, email: email, subject: subject, message: message, recaptcha: recaptcha})
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      sendContactNotification
   }
}

export default Contact