import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref, watch } from 'vue'
import { isAdminLoggedIn, isLoggedIn } from '@/utils/common'
import Dashboard from '@/components/account/dashboard.vue'
import { Nav } from '@/types/admin'


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminDashboard',
  setup(__props) {

const adminAuth = isAdminLoggedIn()
const breadcrump = ref<Nav[] | null>([
   { title: 'Admin Dashboard', link: null }
])


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Dashboard, {
    user: _unref(adminAuth).admin,
    breadcrump: breadcrump.value
  }, null, 8, ["user", "breadcrump"]))
}
}

})