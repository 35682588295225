<template>

   <Dashboard :user="auth.user" :agent="agent" :breadcrump="breadcrump" />
   
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue'
import Dashboard from '@/components/account/dashboard.vue'
import { isAdminLoggedIn, isLoggedIn } from '@/utils/common'
import Agent from '@/composables/admin/agent'
import { Nav } from '@/types/admin';
import { useRouter } from 'vue-router'

const props = defineProps(['agent'])
const auth = isLoggedIn()
const { agentProfile } = Agent()
const breadcrump = ref<Nav[] | null>(null)
const router = useRouter()

onMounted(async () => {

   await prepareBreadcrump()
})

const prepareBreadcrump = async () : Promise<void> => {

   let nav : Nav[] = []

   if (auth.user.type == 'agent') {
      
      nav.push({ title: 'Agent Dashboard', link: null })
      const response = await agentProfile()
      if (response.status == 200) {
         
         const company = response.data.data.company.charAt(0).toUpperCase() + response.data.data.company.slice(1)
         nav.push({ title: company, link: router.resolve({ name: 'AccountDashboard' }).href })
         if (props.agent != '') {
            
            for (const [key, value] of Object.entries(response.data.data.sub_agents)) {
               if (value == props.agent) {
                  nav.push({ title: key, link: null })
               }
            }
         }
      }
   } else if (auth.user.type == 'corporate') {
      nav.push({ title: 'Corporate Dashboard', link: null })
   }

   breadcrump.value = nav
}

watch(() => props.agent, (newVallue, oldValue) => {
   prepareBreadcrump()
})

</script>