import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "py-10 px-4 sm:px-6 lg:px-24" }
const _hoisted_2 = { class: "mt-8 flow-root" }
const _hoisted_3 = { class: "-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_4 = { class: "inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8" }
const _hoisted_5 = { class: "overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg" }
const _hoisted_6 = {
  key: 0,
  class: "text-center my-8 font-medium"
}
const _hoisted_7 = {
  key: 1,
  class: "min-w-full divide-y divide-gray-300"
}
const _hoisted_8 = { class: "divide-y divide-gray-200 bg-white" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "whitespace-nowrap px-3 py-4 text-sm text-gray-500" }
const _hoisted_11 = { class: "whitespace-nowrap px-3 py-4 text-sm text-gray-500" }
const _hoisted_12 = { class: "relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { key: 0 }

import { ref, onMounted } from 'vue'
import { handleApiError } from '@/utils/common'
import SlideOver from '@/widgets/SlideOver.vue'
import { NotificationType } from '@/types/index'
import Notification from '@/widgets/Notification.vue'
import SaveSubUser from '@/components/corporate/SaveSubUser.vue'
import { Corporate as CorporateType } from '@/types/admin'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'
import Corporate from '@/composables/admin/corporate'


export default /*@__PURE__*/_defineComponent({
  __name: 'SubUsers',
  setup(__props) {

const isLoading = ref<boolean>(false)
const openSaveDialog = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const subs = ref<CorporateType[]>([])
const selectedSubUser = ref<CorporateType | null>(null)
const openConfirmModal = ref<boolean>(false)
const deleteItem = ref<CorporateType | null>(null)
const { subUsers, removeSubUser } = Corporate()

onMounted(() => {
   mounted() 
})

const mounted = async () => {

   await fetchSubUsers()
}

const handleSaveSuccess = async () => {
   openSaveDialog.value = false
   notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'A sub user has been added successfully'}
   setTimeout(() => { notifClosed() }, 4000)
   
   await fetchSubUsers()
}

const handleSaveError = (error: string) => { 
   notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
   setTimeout(() => { notifClosed() }, 4000)
}

const handleAdd = () => {
   selectedSubUser.value = null
   openSaveDialog.value = true
}

const handleEdit = (user: CorporateType) => {

   selectedSubUser.value = user
   openSaveDialog.value = true
}

const notifClosed = () : void => {
   notif.value.show = false
}

const handleProceedDelete = (item: CorporateType) => {

   deleteItem.value = item
   openConfirmModal.value = true
}

const askingConfirmed = async () => {
   if (!isLoading.value && deleteItem.value) {
      isLoading.value = true
      const response = await removeSubUser(parseInt(deleteItem.value.id))

      if (response.status == 204) {
         await fetchSubUsers()
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
         setTimeout(() => { notifClosed() }, 4000)
      }

      isLoading.value = false
      openConfirmModal.value = false
   }  
}

const handleConfirmClose = () => openConfirmModal.value = false

const fetchSubUsers = async () => {

   isLoading.value = true
   const response = await subUsers()
   isLoading.value = false

   if (response.status == 200) {
      subs.value = response.data
   } else {
      const error = handleApiError(response)
      console.log(error)
   }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", { class: "sm:flex sm:items-center" }, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "sm:flex-auto" }, [
          _createElementVNode("h1", { class: "text-base font-semibold leading-6 text-gray-900" }, "Sub-Users"),
          _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "A list of all sub users under your account")
        ], -1)),
        _createElementVNode("div", { class: "mt-4 sm:ml-16 sm:mt-0 sm:flex-none" }, [
          _createElementVNode("button", {
            onClick: handleAdd,
            type: "button",
            class: "block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }, " Add Sub User ")
        ])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (isLoading.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, "...loading data. please wait"))
                : (_openBlock(), _createElementBlock("table", _hoisted_7, [
                    _cache[7] || (_cache[7] = _createElementVNode("thead", { class: "bg-gray-50" }, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", {
                          scope: "col",
                          class: "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        }, "Name"),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        }, "Email"),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        }, "Phone"),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "relative py-3.5 pl-3 pr-4 sm:pr-6"
                        }, [
                          _createElementVNode("span", { class: "sr-only" }, "View")
                        ])
                      ])
                    ], -1)),
                    _createElementVNode("tbody", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subs.value, (item) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: item.id
                        }, [
                          _createElementVNode("td", {
                            onClick: ($event: any) => (_ctx.$router.push({name: 'AccountDashboard', params: {'agent': item.id}})),
                            class: "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 capitalize cursor-pointer text-indigo-600 hover:text-indigo-900"
                          }, _toDisplayString(item.name), 9, _hoisted_9),
                          _createElementVNode("td", _hoisted_10, _toDisplayString(item.email), 1),
                          _createElementVNode("td", _hoisted_11, _toDisplayString(item.phone), 1),
                          _createElementVNode("td", _hoisted_12, [
                            _createElementVNode("span", {
                              onClick: ($event: any) => (_ctx.$router.push({name: 'AccountDashboard', params: {'agent': item.id}})),
                              class: "text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            }, "User Report", 8, _hoisted_13),
                            _cache[3] || (_cache[3] = _createTextVNode(" | ")),
                            _createElementVNode("span", {
                              onClick: ($event: any) => (_ctx.$router.push({name: 'UserBookings', params: {'userType' : 'corporate', 'subUserId' : item.id}})),
                              class: "text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            }, "Bookings", 8, _hoisted_14),
                            _cache[4] || (_cache[4] = _createTextVNode(" | ")),
                            _createElementVNode("span", {
                              onClick: ($event: any) => (handleEdit(item)),
                              class: "text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            }, "Edit", 8, _hoisted_15),
                            _cache[5] || (_cache[5] = _createTextVNode(" | ")),
                            _createElementVNode("span", {
                              onClick: ($event: any) => (handleProceedDelete(item)),
                              class: "text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            }, "Delete", 8, _hoisted_16)
                          ])
                        ]))
                      }), 128)),
                      (subs.value && subs.value.length == 0)
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_17, _cache[6] || (_cache[6] = [
                            _createElementVNode("td", {
                              colspan: "4",
                              class: "text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                            }, " No sub users created yet ", -1)
                          ])))
                        : _createCommentVNode("", true)
                    ])
                  ]))
            ])
          ])
        ])
      ])
    ]),
    _createVNode(ConfirmationModal, {
      show: openConfirmModal.value,
      onClosed: handleConfirmClose
    }, {
      buttons: _withCtx(() => [
        _createElementVNode("button", {
          onClick: askingConfirmed,
          type: "button",
          class: _normalizeClass([isLoading.value ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer', "inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto"])
        }, "Remove", 2),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (openConfirmModal.value = false)),
          type: "button",
          class: "mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto"
        }, "Cancel")
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(SlideOver, {
      show: openSaveDialog.value,
      onClosed: _cache[1] || (_cache[1] = ($event: any) => (openSaveDialog.value = false))
    }, {
      content: _withCtx(() => [
        _createVNode(SaveSubUser, {
          data: selectedSubUser.value,
          onSuccess: handleSaveSuccess,
          onError: handleSaveError
        }, null, 8, ["data"])
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"])
  ], 64))
}
}

})