
import { handleApiError } from "@/utils/common"
import Location from '@/composables/Location'

const { placeDetails, placeLocality } = Location()

const LocationService = () => {

   const getCoordinates = async (placeId : string) => {
      try {

         const response = await placeDetails(placeId)

         if (response.status === 200) {
            return { success: true, data: response.data.data.result.geometry.location }
         } else {
            return { success: false, error: handleApiError(response) }
         }
      } catch (err) {
         return { success: false, error: err }
      }
   }

   const getPlaceLocality = async (placeId : string, lat: number, lng: number) => {
      try {

         const response = await placeLocality(placeId, lat, lng)

         if (response.status === 200) {
            return { success: true, data: response.data }
         } else {
            return { success: false, error: handleApiError(response) }
         }
      } catch (err) {
         return { success: false, error: err }
      }
   }

   return {
      getCoordinates,
      getPlaceLocality
   }
}

export default LocationService