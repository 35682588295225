import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "map",
  style: {"height":"530px","width":"100%","z-index":"2"}
}

import { ref, onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactGoogleMap',
  setup(__props) {

const icon = new (window as any).google.maps.MarkerImage('/assets/marker.png')

async function initMap() {
   const { Map } = await (window as any).google.maps.importLibrary("maps") as any

   const map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 26.2407283, lng: 50.5909309 },
      zoom: 15,
      mapId: '8f3ea1e16085205e',
   })

   const marker = new (window as any).google.maps.Marker({
      map,
      position: { lat: 26.2407283, lng: 50.5909309 },
      icon: icon,
   })
}

onMounted(function(){

   initMap()
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}
}

})