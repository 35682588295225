<template>

   <div v-if="!isLoading" class="mb-12 md:w-[80%] mx-auto ">
      <div ref="pdfContent">
         <Voucher :data="data" />
      </div>
      <div @click="downloadPDF" class="uppercase w-48 ml-auto text-size-2 py-4 px-8 text-center rounded-full bg-theme-active border-2 text-white cursor-pointer hover:bg-white hover:border-theme-active hover:text-theme-active transition ease-in-out duration-300">
         Download PDF
      </div>
   </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import Voucher from '@/components/booking/Voucher.vue'
import { Booking } from '@/types'
import BookingService from '@/services/booking-service'
import { getBookingNumber, getSessionPrefix } from '@/utils/common'
import router from '@/router'
// @ts-ignore
import html2pdf from 'html2pdf.js'

const data = ref<Booking | null>(null)
const isLoading = ref<boolean>(true)
const { readBooking } = BookingService()
const pdfContent = ref(null)
const props = defineProps(['bookingNumber'])

onMounted(async () => {

   const response = await readBooking(props.bookingNumber as string)
   isLoading.value = false
   
   if (response.success) {
      data.value = response.data.data
   } else {
      console.log(response.error)
   }
})

const downloadPDF = () => {

   const content = pdfContent.value
   html2pdf(content).from(content).save()
}

</script>