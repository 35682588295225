<template>

   <div class="col-span-12 md:col-span-8 flex flex-col gap-6">
      <p class="text-head-sm font-light text-theme-gray-4">Please fill out the form below with questions or comments</p>
      <div class="flex flex-col gap-2">
         <label for="contact-name" class="text-size-6 font-light text-theme-gray-4">Your name</label>
         <div :class="form.name.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
            <input v-model="form.name.value" type="text" id="contact-name" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
         </div>
         <span v-if="form.name.error" class="text-ef-error">{{ form.name.errorMessage }}</span>
      </div>
      <div class="flex flex-col gap-2">
         <label for="contact-email" class="text-size-6 font-light text-theme-gray-4">Your email</label>
         <div :class="form.email.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
            <input v-model="form.email.value" type="text" id="contact-email" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
         </div>
         <span v-if="form.email.error" class="text-ef-error">{{ form.email.errorMessage }}</span>
      </div>
      <div class="flex flex-col gap-2">
         <label for="contact-subject" class="text-size-6 font-light text-theme-gray-4">Your subject</label>
         <div :class="form.subject.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
            <input v-model="form.subject.value" type="text" id="contact-subject" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
         </div>
         <span v-if="form.subject.error" class="text-ef-error">{{ form.subject.errorMessage }}</span>
      </div>
      <div class="flex flex-col gap-2">
         <label for="contact-name" class="text-size-6 font-light text-theme-gray-4">Your message (optional)</label>
         <div class="flex flex-row items-center border border-theme-gray-7">
            <textarea v-model="form.message.value" class="w-full outline-none text-theme-gray-5 p-2 h-[250px] font-bold text-size-4 md:text-size-6"></textarea>
         </div>
      </div>
      <div class="flex flex-col justify-end gap-2">
         <div class="mt-6 ml-auto google-recaptcha"></div>
         <p v-if="recaptchaError" class="text-ef-error ml-auto">{{ recaptchaError }}</p>
      </div>
      <div @click="handleSubmit" :class="isLoading ? 'cursor-not-allowed opacity-40' : 'cursor-pointer hover:text-theme-active hover:bg-white transition-all ease-in-out duration-500'" class="gap-4 uppercase rounded-full w-full md:w-auto px-4 md:px-8 py-4 border-2 border-theme-active text-white bg-theme-active text-theme-gray-2 text-size-2 flex items-center justify-center ml-auto">
         Submit
      </div>
   </div>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { NotificationType } from '@/types'
import { handleApiError, isValidEmail, loadScript } from '@/utils/common'
import Notification from '@/widgets/Notification.vue'
import Contact from '@/composables/Contact'
// @ts-ignore
import $ from 'jquery'

const isLoading = ref<boolean>(false)
const recaptchaError = ref<any>(null)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const form = ref<any>({
   name: { value: '', error: false, errorMessage: '' },
   email: { value: '', error: false, errorMessage: '' },
   subject: { value: '', error: false, errorMessage: '' },
   message: { value: '', error: false, errorMessage: '' },
})
const { sendContactNotification } = Contact()

const validateForm = () : boolean => {
   let error : boolean = false
   const recaptcha = $('textarea[name="g-recaptcha-response"]')

   if (!recaptcha.length || recaptcha.val() == '' || !recaptcha.val()) {
      error = true
      recaptchaError.value = 'Please validate the recaptcha'
   } else {
      recaptchaError.value = null
   }

   if (form.value.name.value == '') {
      form.value.name.error = true
      form.value.name.errorMessage = 'Name is required'
      error = true
   }

   if (form.value.subject.value == '') {
      form.value.subject.error = true
      form.value.subject.errorMessage = 'Subject is required'
      error = true
   }

   if (form.value.email.value == '') {
      form.value.email.error = true
      form.value.email.errorMessage = 'Email is required'
      error = true
   } else if (!isValidEmail(form.value.email.value)) {
      form.value.email.error = true
      form.value.email.errorMessage = 'Email is invalid'
      error = true
   }

   return error
}

const resetFormErrors = () :void => {
   Object.keys(form.value).forEach((key: string) => {
      form.value[key].error = false
      form.value[key].errorMessage = ''
   })
}

const resetForm = () :void => {
   Object.keys(form.value).forEach((key: string) => {
      form.value[key].value = ''
   })
}

const handleSubmit = async () => {

   resetFormErrors()
   const error = validateForm()
   if (!error && !isLoading.value) {
      isLoading.value = true
      const recaptcha = $('textarea[name="g-recaptcha-response"]').val()

      const response = await sendContactNotification(form.value.name.value, form.value.email.value, form.value.subject.value, form.value.message.value, recaptcha)
   
      if (response.status == 201) {
         notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'We have notified our support team. You will hear from us soon'}
         setTimeout(() => { notifClosed() }, 4000)
         resetForm()
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: error, messageDetails: ''}
      }

      isLoading.value = false
   }
}

const notifClosed = () => {
   notif.value.show = false
}

onMounted(() => {

   var recaptchaScriptUrl = 'https://www.google.com/recaptcha/api.js'
   $('.google-recaptcha').html('<div class="g-recaptcha" data-sitekey="6LdgLVspAAAAAH4Y109CAIfw26hm_onZFQsycv1v"></div>')
   loadScript(recaptchaScriptUrl, function () {})
})

</script>