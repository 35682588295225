<template>
   <div @click="resetDropdowns" class="flex flex-col">
      <div class="w-full mb-5 relative">
         <div class="w-full">
            <label for="location" :class="!errors.location ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium">
               {{ locationType == 'chauffer' ? 'Pick up (city/town)' : 'Pick up (downtown/airport)' }}
            </label>
            <div :class="!errors.location ? 'border-gray-300' : 'border-theme-error'" class="flex flex-row items-center h-10 border">
               <input v-model="locationName" @input="handleSearch(1, locationType == 'chauffer' ? 'city_town' : 'city_town_airport')" type="text" class="mt-1 pb-1 outline-none rounded-sm w-full block pl-2 text-gray-500 text-sm">
               <div v-if="isLoading" class="ml-auto mr-1">
                  <LoaderIcon />
               </div>
            </div>
         </div>
         <ul v-if="isDropOpen" class="z-10 !mt-0 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <li @click="handleSelect(1, item)" v-for="(item, index) in locations" :key="index" class="flex flex-row items-start gap-2 py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer">
               <span>{{ item.name }}</span>
            </li>
            <li v-if="locations.length == 0" class="flex flex-row items-start py-2 justify-center border-b border-solid text-theme-gray-4">No results</li>
         </ul>
      </div>
      <div v-if="locationType == 'transfer'" class="w-full mb-5 relative">
         <div class="w-full">
            <label for="location" :class="!errors.locationTwo ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium">
               {{ 'Drop off (downtown/airport)' }}
            </label>
            <div :class="!errors.locationTwo ? 'border-gray-300' : 'border-theme-error'" class="flex flex-row items-center h-10 border">
               <input v-model="locationTwoName" @input="handleSearch(2, 'city_town_airport')" type="text" class="mt-1 pb-1 outline-none rounded-sm w-full block pl-2 text-gray-500 text-sm">
               <div v-if="isLoadingTwo" class="ml-auto mr-1">
                  <LoaderIcon />
               </div>
            </div>
         </div>
         <ul v-if="isDropTwoOpen" class="z-10 !mt-0 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <li @click="handleSelect(2, item)" v-for="(item, index) in locationsTwo" :key="index" class="flex flex-row items-start gap-2 py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer">
               <span>{{ item.name }}</span>
            </li>
            <li v-if="locationsTwo.length == 0" class="flex flex-row items-start py-2 justify-center border-b border-solid text-theme-gray-4">No results</li>
         </ul>
      </div>
      <div class="w-full mb-5">
         <label for="rate" :class="!errors.rate ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium">
            {{ locationType == 'chauffer' ? 'Rate per hour' : 'Rate' }}
         </label>
         <input v-model="rate" type="number" :class="!errors.rate ? 'border-gray-300' : 'border-theme-error'" class="mt-1 h-10 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
      </div>
      <div class="flex">
         <button @click="handleSubmit()" type="button" class="ml-auto mt-20 bg-theme-active py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white outline-none">
            Save
         </button>
      </div>
   </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { Locality } from '@/types/index'
import Admin from '@/composables/admin/index'
import { handleApiError, resetErrors } from '@/utils/common'
import Location from '@/composables/Location'
import LoaderIcon from '@/components/LoaderIcon.vue'

const props = defineProps(['mode', 'carId', 'locationType'])
const emit = defineEmits(['success', 'error'])
const isLoading = ref<boolean>(false)
const isLoadingTwo = ref<boolean>(false)
const isDropOpen = ref<boolean>(false)
const isDropTwoOpen = ref<boolean>(false)
const locationName = ref<string>('')
const locationTwoName = ref<string>('')
const locations = ref<Locality[]>([])
const locationsTwo = ref<Locality[]>([])
const selectedLocation = ref<Locality | null>(null)
const selectedLocationTwo = ref<Locality | null>(null)
const rate = ref<number | null>(null)
const SEARCH_DELAY = 400
let searchTimeout: ReturnType<typeof setTimeout> | null = null
const { searchLocality } = Location()
const { createCarChaufferLocation, createCarTransferLocation } = Admin()

onMounted(() => {
   mounted() 
})

const mounted = () => {
   
}

const errors = ref({
   location: false,
   locationTwo: false,
   rate: false
})

const handleSelect = (index: number, value: Locality) => {

   if (index ==1) {

      locationName.value = value.name
      selectedLocation.value = value
      isDropOpen.value = false
   } else if (index == 2) {

      locationTwoName.value = value.name
      selectedLocationTwo.value = value
      isDropTwoOpen.value = false
   }
}

const validateForm = () : boolean => {

   let response = true

   if (selectedLocation.value === null) {
      errors.value.location = true
      response = false
   }

   if (selectedLocationTwo.value === null && props.locationType == 'transfer') {
      errors.value.locationTwo = true
      response = false
   }

   return response
}

const handleSubmit = async () => {

   resetErrors(errors.value)
   const isValid = validateForm()

   if (isValid) {

      isLoading.value = true

      let response
      if (props.locationType == 'chauffer') {

         response = await createCarChaufferLocation(props.carId, selectedLocation.value as Locality, rate.value as number)
      } else if (props.locationType == 'transfer') {

         response = await createCarTransferLocation(props.carId, selectedLocation.value as Locality, selectedLocationTwo.value as Locality, rate.value as number)
      }
      isLoading.value = false

      if (response.status == 201) {
         emit('success')
      } else {
         const error = handleApiError(response)
         emit('error', error)
      }
   }
}

const handleSearch = async (index: number, mode: string) => {

   let addr = ''

   if (index == 1) {
      addr = locationName.value.toLowerCase().trim()
   } else if (index == 2) {
      addr = locationTwoName.value.toLowerCase().trim()
   }

   if (addr.length > 2) {

      if (searchTimeout) {
         clearTimeout(searchTimeout);
      }

      searchTimeout = setTimeout(async () => {

         if (index == 1) {
            isLoading.value = true
         } else if (index == 2) {
            isLoadingTwo.value = true
         }

         const response = await searchLocality(addr, mode)
         
         if (index == 1) {
            isLoading.value = false
         } else if (index == 2) {
            isLoadingTwo.value = false
         }

         if (response.status == 200) {
            let adds : Locality[] = []
            response.data.data.forEach((item: any) => {
               adds.push({
                  id: item.id,
                  name: item.name,
                  type: item.type,
                  bounds: item.bounds,
               })
            })

            if (index == 1) {
               locations.value = adds
               isDropOpen.value = true
            } else if (index == 2) {
               locationsTwo.value = adds
               isDropTwoOpen.value = true
            }
         } else {
            
         }
         
      }, SEARCH_DELAY)

   }
}

const resetDropdowns = () => {
   isDropOpen.value = false
   isDropTwoOpen.value = false
}

</script>