import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "mt-8 flow-root" }
const _hoisted_3 = { class: "-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_4 = { class: "inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8" }
const _hoisted_5 = { class: "overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg" }

import { ref, onMounted } from 'vue'
import BookingsTable from '@/components/account/BookingsTable.vue'
import Admin from '@/composables/admin'
import { isAdminLoggedIn, logout } from '@/utils/common'
import router from '@/router'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const { allBookings, agentSubAgents } = Admin()
const isLoading = ref<boolean>(true)
let authAdminUser = ref<any>(null)
const bookings = ref<any>(null)
const mySubAgents = ref([])

onMounted(async () => {

   authAdminUser = isAdminLoggedIn()
   await fetchBookings()
})

const fetchBookings = async () => {

   isLoading.value = true
   let response : any

   response = await allBookings()
   isLoading.value = false

   if (response.status == 200) {
      
      bookings.value = response.data
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "sm:flex sm:items-center" }, [
      _createElementVNode("div", { class: "sm:flex-auto" }, [
        _createElementVNode("h1", { class: "text-base font-semibold leading-6 text-gray-900" }, "Bookings"),
        _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "A list of all the bookings created in our database")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(BookingsTable, {
              subAgents: mySubAgents.value,
              bookings: bookings.value,
              isLoading: isLoading.value
            }, null, 8, ["subAgents", "bookings", "isLoading"])
          ])
        ])
      ])
    ])
  ]))
}
}

})