import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "flex flex-wrap items-center justify-end gap-6 sm:flex-nowrap mb-6 my-12" }
const _hoisted_3 = { class: "order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:leading-7" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "mt-8 flow-root" }
const _hoisted_6 = { class: "-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_7 = { class: "flex flex-col min-w-full py-2 align-middle sm:px-6 lg:px-8" }
const _hoisted_8 = { class: "flex gap-2 w-full justify-end" }
const _hoisted_9 = { class: "p-input-icon-left" }
const _hoisted_10 = { class: "flex items-center gap-3" }
const _hoisted_11 = ["onClick"]

import { ref, onMounted, watch, computed } from 'vue'
import { logout } from '@/utils/common'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import router from '@/router'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import BookingsTable from '@/components/account/BookingsTable.vue'
import Agent from '@/composables/admin/agent'


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentReports',
  setup(__props) {

const { subAgentPerformance } = Agent()
const subAgents = ref<any>(null)
const isLoading = ref<boolean>(false)
const filters = ref()
const loading = ref<boolean>(false)
const dates = ref<string | null>(null)
const dt = ref()
const currentScope = ref<any>()
const dateRangeConfig : any = {
   mode: 'range',
   altFormat: 'd-m-Y',
   altInput: true,
   dateFormat: 'd-m-Y'
}
const scopes = ref([
   { id: 'today', name: 'Today', isCurrent: false },
   { id: 'yesterday', name: 'Yesterday', isCurrent: false },
   { id: 'last_7_days', name: 'Last 7 Days', isCurrent: false },
   { id: 'this_month', name: 'This Month', isCurrent: false },
   { id: 'last_month', name: 'Last Month', isCurrent: false },
   { id: 'all_time', name: 'All Time', isCurrent: true },
   { id: 'custom', name: 'Custom Duration', isCurrent: false }
])

watch(scopes, (newScopes) => {
  currentScope.value = newScopes.find((scope) => scope.isCurrent)
}, { immediate: true })

onMounted(async () => {

   await fetchSubAgentPerformance()
})

const fetchSubAgentPerformance = async () => {
   isLoading.value = true
   const response = await subAgentPerformance(currentScope.value)
   isLoading.value = false

   if (response.status == 200) {      
      subAgents.value = response.data
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const handleScopeUpdate = async (item: any) => {

   scopes.value.forEach((scope: any) => {
      scope.isCurrent = false
   })

   const foundScope = scopes.value.find((scope: any) => scope.id === item.id)
   if (foundScope) {
      foundScope.isCurrent = true
      currentScope.value = item
   }

   dates.value = ''

   fetchSubAgentPerformance()
}

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "sub_agent_name": { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "sub_agent_email": { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
   }
}

watch(dates, (newValue, oldValue) => {

   if (newValue?.includes('to')) {
      
      scopes.value.forEach((scope: any) => {
         if (!scope.id.includes('custom') && !scope.id.includes('month_range')) {
            scope.isCurrent = false
         } else if (scope.id.includes('custom')) {
            scope.isCurrent = true
            const dateRange = `${newValue.split(' to ')[0]}_${newValue.split(' to ')[1]}`
            scope.id = `custom_${dateRange}`
            currentScope.value = scope
         }
      })

      fetchSubAgentPerformance()
   }
})

initFilters()

const clearFilter = () => {
   initFilters()
}

const exportCSV = (event: any) => {
   dt.value.exportCSV()
}


return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "sm:flex sm:items-center" }, [
      _createElementVNode("div", { class: "sm:flex-auto" }, [
        _createElementVNode("h1", { class: "text-base font-semibold leading-6 text-gray-900" }, "Sub Agents Performance Report"),
        _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "View / download agent performace report")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scopes.value, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            class: _normalizeClass([item.isCurrent ? 'text-theme-active' : 'text-gray-700', "cursor-pointer"])
          }, [
            (!item.id.includes('custom') && !item.id.includes('month_range'))
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  onClick: ($event: any) => (handleScopeUpdate(item))
                }, _toDisplayString(item.name), 9, _hoisted_4))
              : (item.id.includes('custom'))
                ? (_openBlock(), _createBlock(_unref(flatPickr), {
                    key: 1,
                    modelValue: dates.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dates).value = $event)),
                    config: dateRangeConfig,
                    class: _normalizeClass([item.isCurrent ? 'text-theme-gray-5' : 'text-theme-active', "border px-3 outline-none"]),
                    placeholder: "Custom date range"
                  }, null, 8, ["modelValue", "class"]))
                : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_DataTable, {
            exportFilename: "agent_performance_report",
            ref_key: "dt",
            ref: dt,
            class: "text-sm",
            filters: filters.value,
            "onUpdate:filters": _cache[4] || (_cache[4] = ($event: any) => ((filters).value = $event)),
            value: subAgents.value,
            paginator: "",
            showGridlines: "",
            rows: 10,
            dataKey: "id",
            filterDisplay: "menu",
            loading: loading.value,
            globalFilterFields: ['sub_agent_name','sub_agent_email']
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, [
                  _createVNode(_component_InputText, {
                    modelValue: filters.value['global'].value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filters.value['global'].value) = $event)),
                    placeholder: "Keyword Search"
                  }, null, 8, ["modelValue"])
                ]),
                _createVNode(_component_Button, {
                  type: "button",
                  icon: "pi pi-filter-slash",
                  label: "Clear",
                  outlined: "",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (clearFilter()))
                }),
                _createVNode(_component_Button, {
                  icon: "pi pi-external-link",
                  label: "Export",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (exportCSV($event)))
                })
              ])
            ]),
            empty: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" No records to show ")
            ])),
            loading: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" Loading data. Please wait. ")
            ])),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "sub_agent_name",
                header: "Name"
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.sub_agent_name), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "sub_agent_email",
                header: "Email",
                class: "w-[12rem]"
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.sub_agent_email), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "sub_agent_booking_count",
                header: "No of Bookings",
                class: "w-[12rem]"
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.sub_agent_booking_count), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "sub_agent_booking_total",
                header: "Total Booking Amount",
                class: "w-[12rem]"
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.sub_agent_booking_total), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "sub_agent_commission_total",
                header: "Total Commission",
                class: "w-[12rem]"
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.sub_agent_commission_total), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                header: "Actions",
                exportable: false,
                class: ""
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("button", {
                      onClick: ($event: any) => (_unref(router).push({name: 'subAgentBookingsReport', params: {subAgentId: data.sub_agent_id}})),
                      type: "button",
                      class: "whitespace-nowrap rounded rounded-bg bg-theme-button1-bg text-theme-button1-txt text-center px-2 py-2 uppercase text-xs"
                    }, " View Bookings ", 8, _hoisted_11)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["filters", "value", "loading"])
        ])
      ])
    ])
  ]))
}
}

})