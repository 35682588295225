<template>
   <div v-if="action == 'loyalty'" class="w-full mb-5">
      <label for="threshold" :class="!errors.loyaltyThreshold ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium">Loyalty Threshold</label>
      <input v-model="form.loyaltyThreshold" type="text" :class="!errors.loyaltyThreshold ? 'border-gray-300 text-gray-500' : 'border-theme-error text-theme-error'" id="threshold" class="mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-sm">
   </div>
   <div v-if="action == 'commission'" class="w-full mb-5">
      <label for="admin-commission" class="block text-sm font-medium text-gray-700">Agent Commission</label>
      <input v-model="form.commission.admin" type="text" id="admin-commission" class="mt-1 border-gray-300 text-gray-500 outline-none border rounded-sm h-10 w-full block pl-2 text-sm">
   </div>
   <div v-if="action == 'credit'" class="w-full mb-5">
      <label for="status" class="block text-sm font-medium text-gray-700">Has Credit Permission</label>
      <select v-model="form.credit.permission" class="block mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6">
         <option value="1">Yes</option>
         <option value="0">No</option>
      </select>
   </div>
   <div v-if="action == 'credit' && form.credit.permission == 1" class="w-full mb-5">
      <label for="credit-amount" class="block text-sm font-medium text-gray-700">Total Credit Amount</label>
      <input v-model="form.credit.amount" type="text" id="credit-amount" class="mt-1 border-gray-300 text-gray-500 outline-none border rounded-sm h-10 w-full block pl-2 text-sm">
   </div>

   <div v-if="action == 'credit' && form.credit.permission == 1" class="w-full mb-5">
      <label for="credit-days" class="block text-sm font-medium text-gray-700">Total No of days</label>
      <input v-model="form.credit.days" type="number" id="credit-days" class="mt-1 border-gray-300 text-gray-500 outline-none border rounded-sm h-10 w-full block pl-2 text-sm">
   </div>

   <div v-if="action == 'credit' && form.credit.permission == 1" class="w-full mb-5">
      <div className="flex items-center justify-between">
         <label for="credit-reset" class="block text-sm font-medium text-gray-700">Reset Days Consumed</label>
         <input id="credit-reset" v-model="form.credit.reset" value="yes" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
      </div>
      <p class="text-gray-500 text-xs">It will reset the number of days consumed by agent</p>
   </div>
   
   <div v-if="action == 'credit' && form.credit.permission == 1" class="w-full mb-5">
      <div className="flex items-center justify-between">
         <label for="credit-used-reset" class="block text-sm font-medium text-gray-700">Reset Credit Used</label>
         <input id="credit-used-reset" v-model="form.credit.resetCreditUsed" value="yes" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
      </div>
      <p class="text-gray-500 text-xs">It will reset the credit consumed by agent to 0</p>
   </div>

   <div v-if="action == 'credit' && form.credit.permission == 1" class="w-full mb-5">
      <label for="credit-amount" class="block text-sm font-medium text-gray-700">Total Credit Left : {{ form.credit.amount - form.credit.used }}</label>
      <label for="credit-amount" class="block text-sm font-medium text-gray-700">Total Days Left : {{ form.credit.daysLeft }}</label>
   </div>

   <div v-if="action == 'status'" class="w-full mb-5">
      <label for="status" class="block text-sm font-medium text-gray-700">Agent Status</label>
      <select v-model="form.status" class="block mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6">
         <option value="1">Active</option>
         <option value="0">Inactive</option>
      </select>
   </div>
   
   <div class="flex">
      <button @click="handleSubmit()" type="button" class="ml-auto mt-20 bg-indigo-600 py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 outline-none">
         Save
      </button>
   </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import Admin from '@/composables/admin/index'
import { resetErrors, handleApiError } from '@/utils/common'

const props = defineProps(['agent', 'action'])
const emit = defineEmits(['success', 'error'])
const { updateAgent } = Admin()
const isLoading = ref<boolean>(false)
const form = ref({
   loyaltyThreshold: 0,
   status: 0,
   credit: {
      permission: 0,
      amount: 0,
      used: 0,
      days: 0,
      reset: '',
      resetCreditUsed: '',
      daysLeft: 0
   },
   commission: {
      agent: 0,
      admin: 0
   }
})
const errors = ref({
   loyaltyThreshold: false
})

watch(() => form.value.credit.permission, (newValue : any, oldValue: any) => {
   if (newValue === '1') {
      
   } else if (newValue === '0') {
      
   }
})

onMounted(() => {
   mounted() 
})

const mounted = async () => {

   if (props.agent) {
      form.value = {
         loyaltyThreshold: props.agent.loyalty.threshold,
         status: props.agent.status,
         credit: {...props.agent.credit, reset: '', resetCreditUsed: ''},
         commission: {
            agent: props.agent.commission,
            admin: props.agent.admin_commission,
         },
      }
   }
}

const validateForm = () : boolean => {

   let response = true
   
   if (props.action == 'loyalty' && form.value.loyaltyThreshold == 0) {
      errors.value.loyaltyThreshold = true
      response = false
   }

   return response 
}

const handleSubmit = async () => {

   resetErrors(errors.value)
   const isValid = validateForm()

   if (isValid) {
      
      isLoading.value = true
      const response = await updateAgent(props.agent.id, form.value, props.action)
      isLoading.value = false
      if (response.status == 200) {
         emit('success')
      } else {
         const error = handleApiError(response)
         emit('error', error)
      }
   }
}

</script>