<template>

   <div class="relative p-5" :class="layout == 'simple' ? 'col-span-7 md:col-span-2' : 'col-span-8'">
      <div class="flex flex-col space-y-1">
         <div class="uppercase text-small text-theme-gray-2">Days</div>
         <div v-if="layout == 'simple'" ref="componentRef" class="inline-block text-left cursor-pointer" @click="toggleDrop">
            <button type="button" class="font-bold text-theme-gray-5 text-size-6 border-0 outline-none">{{ selectedDay }} day(s)</button>
            <ul v-if="isDropOpen" class="z-10 max-h-[200px] overflow-y-auto origin-top-right absolute right-0 mt-4 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5">
               <li v-for="(day, index) in days" :key="index" @click="selectedDay=day" class="py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer">
                  {{ day }} day(s)
               </li>
            </ul>
            <div class="relative">
               <font-awesome-icon size="lg" icon="fa-solid fa-chevron-down" class="text-theme-active absolute right-0 bottom-1" />
            </div>
         </div>
         <span v-else class="font-bold text-theme-gray-5 text-size-6">{{ selectedDay }} Day(s)</span>
      </div>
   </div>

</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { getHoursPerDay } from '@/utils/common'

const props = defineProps(['layout', 'type', 'data'])
const emit = defineEmits(['selected'])
const isDropOpen = ref<boolean>(false)
const days : number[] = [1,2,3,4,5,6,7,8,9,10]
const selectedDay = ref<number>(days[0])
const componentRef = ref()
const hoursPerDay : number = getHoursPerDay()

const toggleDrop = () => {
   isDropOpen.value = !isDropOpen.value
}

onMounted(() => {

   if (props.data && (props.data % 10 === 0)) {
      selectedDay.value = props.data / hoursPerDay
   }
})

watch(selectedDay, (newValue, oldValue) => {
   emit('selected', newValue * hoursPerDay)
})

const listener = (event: any) => {
   if (event.target !== componentRef.value) {
      //isDropOpen.value = false
   }
}

onMounted(() => {
   document.addEventListener('click', listener)
})

onBeforeUnmount(() => {
   document.removeEventListener('click', listener)
})

</script>