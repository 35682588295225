<template>
   <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
         <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">Sub Agents Performance Report</h1>
            <p class="mt-2 text-sm text-gray-700">View / download agent performace report</p>
         </div>
      </div>
      <div class="flex flex-wrap items-center justify-end gap-6 sm:flex-nowrap mb-6 my-12">
         <div class="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:leading-7">
            <div v-for="item in scopes" :key="item.id" :class="item.isCurrent ? 'text-theme-active' : 'text-gray-700'" class="cursor-pointer">
               <span v-if="!item.id.includes('custom') && !item.id.includes('month_range')" @click="handleScopeUpdate(item)">{{ item.name }}</span>
               <flat-pickr v-else-if="item.id.includes('custom')" v-model="dates" :config="dateRangeConfig" :class="item.isCurrent ? 'text-theme-gray-5' : 'text-theme-active'" class="border px-3 outline-none" placeholder="Custom date range" />
            </div>
         </div>
      </div>
      <div class="mt-8 flow-root">
         <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="flex flex-col min-w-full py-2 align-middle sm:px-6 lg:px-8">
               <DataTable
                  exportFilename="agent_performance_report"
                  ref="dt"
                  class="text-sm"
                  v-model:filters="filters" 
                  :value="subAgents"
                  paginator showGridlines :rows="10" 
                  dataKey="id"
                  filterDisplay="menu" 
                  :loading="loading"
                  :globalFilterFields="['sub_agent_name','sub_agent_email']"
               >
                  <template #header>
                     <div class="flex gap-2 w-full justify-end">
                        <span class="p-input-icon-left">
                           <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </span>
                        <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined @click="clearFilter()" />
                        <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                     </div>
                  </template>
                  <template #empty> No records to show </template>
                  <template #loading> Loading data. Please wait. </template>
                  <Column field="sub_agent_name" header="Name">
                     <template #body="{ data }">
                        {{ data.sub_agent_name }}
                     </template>
                  </Column>
                  <Column field="sub_agent_email" header="Email" class="w-[12rem]">
                     <template #body="{ data }">
                        {{ data.sub_agent_email }}
                     </template>
                  </Column>
                  <Column field="sub_agent_booking_count" header="No of Bookings" class="w-[12rem]">
                     <template #body="{ data }">
                        {{ data.sub_agent_booking_count }}
                     </template>
                  </Column>
                  <Column field="sub_agent_booking_total" header="Total Booking Amount" class="w-[12rem]">
                     <template #body="{ data }">
                        {{ data.sub_agent_booking_total }}
                     </template>
                  </Column>
                  <Column field="sub_agent_commission_total" header="Total Commission" class="w-[12rem]">
                     <template #body="{ data }">
                        {{ data.sub_agent_commission_total }}
                     </template>
                  </Column>
                  <Column header="Actions" :exportable="false" class="">
                     <template #body="{ data }">
                        <div class="flex items-center gap-3">
                           <button @click="router.push({name: 'subAgentBookingsReport', params: {subAgentId: data.sub_agent_id}})" type="button" class="whitespace-nowrap rounded rounded-bg bg-theme-button1-bg text-theme-button1-txt text-center px-2 py-2 uppercase text-xs">
                              View Bookings
                           </button>
                        </div>
                     </template>
                  </Column>
               </DataTable> 
            </div>
         </div>
      </div>
   </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import { logout } from '@/utils/common'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import router from '@/router'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import BookingsTable from '@/components/account/BookingsTable.vue'
import Agent from '@/composables/admin/agent'

const { subAgentPerformance } = Agent()
const subAgents = ref<any>(null)
const isLoading = ref<boolean>(false)
const filters = ref()
const loading = ref<boolean>(false)
const dates = ref<string | null>(null)
const dt = ref()
const currentScope = ref<any>()
const dateRangeConfig : any = {
   mode: 'range',
   altFormat: 'd-m-Y',
   altInput: true,
   dateFormat: 'd-m-Y'
}
const scopes = ref([
   { id: 'today', name: 'Today', isCurrent: false },
   { id: 'yesterday', name: 'Yesterday', isCurrent: false },
   { id: 'last_7_days', name: 'Last 7 Days', isCurrent: false },
   { id: 'this_month', name: 'This Month', isCurrent: false },
   { id: 'last_month', name: 'Last Month', isCurrent: false },
   { id: 'all_time', name: 'All Time', isCurrent: true },
   { id: 'custom', name: 'Custom Duration', isCurrent: false }
])

watch(scopes, (newScopes) => {
  currentScope.value = newScopes.find((scope) => scope.isCurrent)
}, { immediate: true })

onMounted(async () => {

   await fetchSubAgentPerformance()
})

const fetchSubAgentPerformance = async () => {
   isLoading.value = true
   const response = await subAgentPerformance(currentScope.value)
   isLoading.value = false

   if (response.status == 200) {      
      subAgents.value = response.data
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const handleScopeUpdate = async (item: any) => {

   scopes.value.forEach((scope: any) => {
      scope.isCurrent = false
   })

   const foundScope = scopes.value.find((scope: any) => scope.id === item.id)
   if (foundScope) {
      foundScope.isCurrent = true
      currentScope.value = item
   }

   dates.value = ''

   fetchSubAgentPerformance()
}

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "sub_agent_name": { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "sub_agent_email": { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
   }
}

watch(dates, (newValue, oldValue) => {

   if (newValue?.includes('to')) {
      
      scopes.value.forEach((scope: any) => {
         if (!scope.id.includes('custom') && !scope.id.includes('month_range')) {
            scope.isCurrent = false
         } else if (scope.id.includes('custom')) {
            scope.isCurrent = true
            const dateRange = `${newValue.split(' to ')[0]}_${newValue.split(' to ')[1]}`
            scope.id = `custom_${dateRange}`
            currentScope.value = scope
         }
      })

      fetchSubAgentPerformance()
   }
})

initFilters()

const clearFilter = () => {
   initFilters()
}

const exportCSV = (event: any) => {
   dt.value.exportCSV()
}

</script>