<template>

   <div class="grid grid-cols-1 gap-4 w-full my-12">
      <div class="col-span-1 flex flex-row items-end">
         <img class="w-[235px]" src="/assets/logo.png" alt="">
         <div class="flex flex-col items-end ml-auto">
            <div>312 Falcon Tower, Displomatic Area</div>
            <div>Bahrain - Manama</div>
            <div v-if="data.refNumber">Reference Number: {{ data.refNumber }}</div>
         </div>
      </div>
      <div class="bg-gray-400 pt-1 pb-4 px-3 border-t-2 border-black">
         <div class="flex flex-row items-start justify-between">
            <div class="flex flex-col items-start">
               <div class="font-bold">Order ID</div>
               <div>{{ data.number }}</div>
            </div>
            <div class="flex flex-col items-center">
               <div class="font-bold">Date</div>
               <div>{{ formattedDateTime(data.createdAt) }}</div>
            </div>
            <div class="flex flex-col items-end">
               <div class="font-bold">Currency</div>
               <div>USD</div>
            </div>
         </div>
      </div>
      <div class="flex flex-row items-start justify-between px-3">
         <div class="flex flex-col">
            <div class="font-bold mb-3">Invoice To:</div>
            <div v-if="data.bookedBy" class="flex flex-col items-start">
               <div>{{ data.bookedBy.name }}</div>
               <div>{{ data.bookedBy.email }}</div>
               <div>{{ data.bookedBy.phone }}</div>
            </div>
         </div>
         <div class="flex flex-col">
            <div class="font-bold mb-3 text-right">Client Details:</div>
            <div v-if="data.contact" class="flex flex-col items-end">
               <div>{{ data.contact.name }}</div>
               <div>{{ data.contact.email }}</div>
               <div>{{ data.contact.phone }}</div>
            </div>
         </div>
      </div>
      <div v-if="data.comments" class="flex flex-row items-start justify-between px-3">
         <div class="flex flex-col">
            <div class="font-bold mb-3">Comments:</div>
            <div class="flex flex-col items-start" v-html="data.comments"></div>
         </div>
      </div>
      <div class="flex flex-col border-t-2 border-black gap-3">
         <div class="flex flex-row items-start bg-gray-400 px-3 py-1">
            <div class="w-1/3 text-left">
               <div class="font-bold">Date & Time</div>
            </div>
            <div class="w-1/3 text-center">
               <div class="font-bold">Pick up</div>
            </div>
            <div class="w-1/3 text-right">
               <div class="font-bold">
                  {{ data.search.type == 'hourly' ? 'Hours' : 'Drop off' }}
               </div>
            </div>
         </div>
         <div class="flex flex-row items-start">
            <div class="w-1/3 text-left">
               <div class="font-normal">{{ data.search.pickUp.date + ' ' + data.search.pickUp.time }}</div>
            </div>
            <div class="w-1/3 text-center">
               <div class="font-normal">{{ data.search.pickUp.location.name }}</div>
            </div>
            <div class="w-1/3 text-right">
               <div class="font-normal">
                  {{ data.search.type == 'hourly' ? data.search.hours + ' Hrs' : data.search.dropOff.location.name }}
               </div>
            </div>
         </div>
      </div>
      <div class="flex flex-col gap-1 border-t-2 border-black mt-4">
         <div class="flex flex-row items-center justify-end gap-4 mt-2">
            <div class="font-bold">Subtotal (VAT inclusive)</div>
            <div>{{ data.totalAmount }} USD</div>
         </div>
         <div class="flex flex-row items-center justify-end gap-4">
            <div class="font-bold">Total</div>
            <div>{{ data.totalAmount }} USD</div>
         </div>
      </div>

      <div class="col-span-1 flex flex-col gap-4 pt-16">
         <div class="grid grid-cols-2">
            <div class="flex flex-col gap-4 col-span-2">
               <h2 class="font-bold">Bank Transfer Details (For Wire Transfer)</h2>
               <div class="flex flex-col gap-1">
                  <div class="flex flex-row items-center gap-2">
                     <div class="font-bold">Account Name:</div>
                     <div>Vehitra Tech WLL</div>
                  </div>
                  <div class="flex flex-row items-center gap-2">
                     <div class="font-bold">Bank name:</div>
                     <div>AHLI UNITED BANK</div>
                  </div>
                  <div class="flex flex-row items-center gap-2">
                     <div class="font-bold">Branch Location:</div>
                     <div>Seef Mall Manama Bahrain</div>
                  </div>
                  <div class="flex flex-row items-center gap-2">
                     <div class="font-bold">IBAN:</div>
                     <div>BH14 AUBB 0001 0578 8900 01</div>
                  </div>
                  <div class="flex flex-row items-center gap-2">
                     <div class="font-bold">Account Number:</div>
                     <div>0010-578890-001</div>
                  </div>
                  <div class="flex flex-row items-center gap-2">
                     <div class="font-bold">Swift:</div>
                     <div>AUB BB HBM</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

</template>

<script setup lang="ts">
import { ref } from 'vue'
import { formattedDateTime } from '@/utils/common'

const props = defineProps(['data'])

console.log(props.data)
</script>