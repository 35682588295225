
<template>
   <div class="flex flex-col" :class="layout == 'simple' ? 'md:space-y-3' : ''">
      <NavTabs :layout=layout :driveType="drive" @driveType=handleDriveTypeChange />
      <div class="grid divide-x divide-y divide-theme-gray-7 gap-0 w-full bg-white" :class="layout == 'simple' ? 'grid-cols-7' : 'grid-cols-8 border-r border-b border-l border-theme-gray-7'">
         <div v-if="layout == 'full'" class="uppercase bg-theme-gray-7 text-theme-gray-2 text-size-1 col-span-8 p-5">Ride Details</div>
         <DateField :layout=layout :hasError=errors.pickUp.date :data="data && data.pickUp ? data.pickUp.date : null" @dateChanged="handleDateChanged" />
         <TimeField :layout=layout :hasError=errors.pickUp.time :data="data && data.pickUp ? data.pickUp.time : null" @timeChanged="handleTimeChanged" />
         <LocationField :layout=layout :drive=drive :hasError=errors.pickUp.location :data="data && data.pickUp ? data.pickUp : null" type="pickUp" label="Pick up location" @addressSelected="handleAddressSelected" />
         <LocationField v-if="drive == 'distance'" :drive=drive :hasError=errors.dropOff.location :layout=layout :data="data && data.dropOff ? data.dropOff : null" type="dropOff" label="Drop-off location" @addressSelected="handleAddressSelected" />
         <DaysField :layout=layout v-else-if="drive == 'hourly'" :data="data ? data.hours : null" @selected="handleDaysChanged" />
         <TransferTypeField v-if="layout == 'full' && drive == 'distance'" :layout=layout />
         <!--
            <div v-if="layout == 'full'" class="uppercase bg-theme-gray-7 text-theme-gray-2 text-size-1 col-span-8 p-5">Extra Options</div>
            <ExtraTimeField v-if="layout == 'full' && drive == 'distance'" :layout=layout @selected="handleExtraTimeChanged" />
            <LocationField v-if="layout == 'full' && drive == 'hourly'" type="dropOff" label="Drop-off location" @addressSelected="handleAddressSelected" />
         -->
         <div v-if="layout == 'simple'" class="flex items-center justify-center col-span-7 md:col-span-1 py-4 md:py-0">
            <div class="flex flex-col space-y-2">
               <div @click="proceedInitBooking" class="uppercase text-size-2 py-4 px-8 text-center rounded-full bg-theme-active border-2 text-white cursor-pointer hover:bg-white hover:border-theme-active hover:text-theme-active transition ease-in-out duration-300">
                  Book Now
               </div>
            </div>
         </div>
      </div>
   </div>
   <ConfirmationModal :show="openConfirmModal" :confirmationText="lastMinuteBookText" @closed="handleConfirmClose">
      <template v-slot:buttons>
         <button @click="askingConfirmed" type="button" class="inline-flex cursor-pointer w-full justify-center rounded-md bg-theme-active px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto">
            Request Quote
         </button>
         <button @click="openConfirmModal = false" type="button" class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto">
            Cancels
         </button>
      </template>
   </ConfirmationModal>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { LocationSchedule, DriveType, UserSearch, GoogleLocationType, Locality } from '@/types/index'
import router from '@/router'
import NavTabs from '@/components/search/NavTabs.vue'
import DateField from '@/components/search/DateField.vue'
import TimeField from '@/components/search/TimeField.vue'
import LocationField from '@/components/search/LocationField.vue'
import DaysField from '@/components/search/DaysField.vue'
import TransferTypeField from '@/components/search/TransferTypeField.vue'
import ExtraTimeField from '@/components/search/ExtraTimeField.vue'
import { resetErrors, getHoursPerDay, getDiffInHours } from '@/utils/common'
import { encodeIntoQuery, saveUserSearch } from '@/services/search-service'
import { parseQuery } from 'vue-router'
import LocationService from '@/services/location-service'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'

const props = defineProps(['layout', 'type', 'data', 'proceed'])
const emit = defineEmits(['pickUp', 'dropOff', 'proceedInitiated'])
const drive = ref<DriveType>(props.type)
const { getCoordinates }  = LocationService()
const chaufferHours = ref<number | null>(getHoursPerDay())
const openConfirmModal = ref<boolean>(false)
const extraTime = ref<number | null>(null)
const lastMinuteBookText = ref<string>('System accepts orders not less than 24 hours. For last minute orders please use multiple requests and will provide you with the confirmation even few hours in advance.')
const pickUp = ref<LocationSchedule>({
   location: {
      id: '',
      name: '',
      isAirport: false,
      coordinates: {
         lat: null,
         lng: null
      }
   },
   date: null,
   time: ''
})
const dropOff = ref<LocationSchedule>({
   location: {
      id: '',
      name: '',
      isAirport: false,
      coordinates: {
         lat: null,
         lng: null
      }
   }
})

const errors = ref({
   pickUp: {
      location: false,
      date: false,
      time: false
   },
   dropOff: {
      location: false
   }
})

const proceedInitBooking = async () => {

   await setAddressCoordinates()
   resetErrors(errors.value)
   let isValid = validateSearchForm()

   if (props.layout == 'simple') {
      if (isValid) {
         if (pickUp.value && pickUp.value.date) {
            const date = `${pickUp.value.date} ${pickUp.value.time}`
            const hours = getDiffInHours(date)
            if (hours < 24) {
               openConfirmModal.value = true
            } else{
               const userSearch : UserSearch = {
                  type: drive.value,
                  mode: 'one-way',
                  pickUp: pickUp.value,
                  dropOff: dropOff.value,
                  hours: chaufferHours.value as number,
                  extraTime: extraTime.value as number
               }
               saveUserSearch(userSearch)

               router.push({name: 'BookingWizard', params: { step: 'choose-vehicle' }})
               //router.push({name: 'BookingWizard', params: { step: 'ride-details' }, query: parseQuery(encodeIntoQuery(drive.value, pickUp.value, dropOff.value, chaufferHours.value))})
            }
         }
      }
   } else {
      if (isValid) {

         const userSearch : UserSearch = {
            type: drive.value,
            mode: 'one-way',
            pickUp: pickUp.value,
            dropOff: dropOff.value,
            hours: chaufferHours.value as number,
            extraTime: extraTime.value as number
         }
         saveUserSearch(userSearch)

         router.push({name: 'BookingWizard', params: { step: 'choose-vehicle' }})
      }
   }
}

const handleDriveTypeChange = (value: string) => {
   drive.value = value as DriveType
}

const handleAddressSelected = (address: GoogleLocationType | null, type: string) => {

   let add
   if (address != null) {
      add = address
   } else {
      add = {id: '', name: '', coordinates: {lat: null, lng: null}}
   }

   if (type == 'pickUp') {
      const updatedPickUp = { ...pickUp.value, location: add }
      pickUp.value = updatedPickUp
   } else if (type == 'dropOff') {
      const updatedDropOff = { ...dropOff.value, location: add }
      dropOff.value = updatedDropOff
   }
}

const handleDateChanged = (date: string) => {
   pickUp.value.date = date
}

const handleExtraTimeChanged = (value: number) => {
   extraTime.value = value
}

const handleConfirmClose = () => openConfirmModal.value = false

const handleDaysChanged = (value: number) => {
   chaufferHours.value = value
}

const handleTimeChanged = (time: string) => {
   pickUp.value.time = time
}

const askingConfirmed = async () => {
   router.push({name: 'Planmytrip'})  
}

const validateSearchForm = () : boolean => {

   let response = true
   
   if (pickUp.value.date === null) {
      errors.value.pickUp.date = true
      response = false
   }
   
   if (pickUp.value.time == '') {
      errors.value.pickUp.time = true
      response =  false
   }
   
   if (pickUp.value.location.id === '' || pickUp.value.location.coordinates.lat === null || pickUp.value.location.coordinates.lng === null) {
      errors.value.pickUp.location = true
      response =  false
   }
   
   if (drive.value == 'distance' && dropOff.value.location.id === '') {
      errors.value.dropOff.location = true
      response =  false
   }

   return response 
}

const setAddressCoordinates = async () : Promise<void> => {

   let response
   if (pickUp.value && pickUp.value.location.id !== '') {
      response = await getCoordinates(pickUp.value.location.id)

      if (response.success) {
         const updatedPickUp = { ...pickUp.value, location: { ...pickUp.value.location, coordinates: { ...response.data } } }
         pickUp.value = updatedPickUp
      }
   }

   if (drive.value == 'distance' && dropOff.value && dropOff.value.location.id !== '') {
      response = await getCoordinates(dropOff.value.location.id)

      if (response.success) {
         const updatedDropOff = { ...dropOff.value, location: { ...dropOff.value.location, coordinates: { ...response.data } } }
         dropOff.value = updatedDropOff
      }
   }
}

watch(() => pickUp.value, (newValue, oldValue) => {
   emit('pickUp', newValue)
})

watch(() => dropOff.value, (newValue, oldValue) => {
   emit('dropOff', newValue)
})

watch(() => props.proceed, (newVallue, oldValue) => {
   
   if (newVallue) {
      proceedInitBooking()
      emit('proceedInitiated', '1')
   }
})

const mounted = () => {

}

onMounted(() => {
   mounted()
})
</script>