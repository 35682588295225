import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full mb-5" }
const _hoisted_2 = {
  key: 0,
  class: "w-full mb-5"
}
const _hoisted_3 = { class: "w-full mb-5" }
const _hoisted_4 = { class: "flex" }

import { ref } from 'vue'
import Corporate from '@/composables/admin/corporate'
import { isValidEmail, resetErrors, handleApiError, isLoggedIn } from '@/utils/common'
import { Corporate as CorporateType } from '@/types/admin'


export default /*@__PURE__*/_defineComponent({
  __name: 'SaveSubUser',
  props: ['data'],
  emits: ['success', 'error'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const isLoading = ref<boolean>(false)
const { createSubUser, updateSubUser } = Corporate()
const authUser = isLoggedIn()
const subUser = ref<CorporateType>({
   id: '',
   name: '',
   email: '',
   commission: 0,
   address: '',
   phone: '',
   isParent: false
})
const errors = ref<any>({
   name: false,
   email: false,
   phone: false
})

if (props.data) {
   subUser.value = props.data
}

const validateForm = () : boolean => {

   let response = true

   if (subUser.value.name == '') {
      response = false
      errors.value.name = true
   }

   if (subUser.value.email == '' || !isValidEmail(subUser.value.email)) {
      response = false
      errors.value.email = true
   }

   if (subUser.value.phone == '') {
      response = false
      errors.value.phone = true
   }

   return response 
}

const handleSubmit = async () => {

   resetErrors(errors.value)
   const isValid = validateForm()

   if (isValid && !isLoading.value) {
      
      isLoading.value = true

      if (!props.data) {

         const response = await createSubUser(subUser.value)
         if (response.status == 201) {
            emit('success')
         } else {
            const error = handleApiError(response)
            emit('error', error)
         }
      } else {

         const response = await updateSubUser(subUser.value)
         if (response.status == 200) {
            emit('success')
         } else {
            const error = handleApiError(response)
            emit('error', error)
         }
      }

      isLoading.value = false
   }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        for: "name",
        class: _normalizeClass([!errors.value.name ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
      }, "Name", 2),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((subUser.value.name) = $event)),
        type: "text",
        class: _normalizeClass([!errors.value.name ? 'border-gray-300' : 'border-theme-error', "mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm"])
      }, null, 2), [
        [_vModelText, subUser.value.name]
      ])
    ]),
    (__props.data == null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", {
            for: "name",
            class: _normalizeClass([!errors.value.email ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
          }, "Email", 2),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((subUser.value.email) = $event)),
            type: "text",
            class: _normalizeClass([!errors.value.email ? 'border-gray-300' : 'border-theme-error', "mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm"])
          }, null, 2), [
            [_vModelText, subUser.value.email]
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", {
        for: "name",
        class: _normalizeClass([!errors.value.phone ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
      }, "Phone Number", 2),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((subUser.value.phone) = $event)),
        type: "text",
        class: _normalizeClass([!errors.value.phone ? 'border-gray-300' : 'border-theme-error', "mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm"])
      }, null, 2), [
        [_vModelText, subUser.value.phone]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (handleSubmit())),
        type: "button",
        class: _normalizeClass([isLoading.value ? 'cursor-not-allowed opacity-70' : 'hover:bg-indigo-700', "ml-auto mt-20 bg-indigo-600 py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white outline-none"])
      }, " Save ", 2)
    ])
  ], 64))
}
}

})