import { getAdminToken, getBackendBaseUrl } from "@/utils/common"
import { LocationSchedule, Car as CarData, UserSearch } from '@/types/index'
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const Payment = () => {

   const getHeaders = () => { 
      return {
         'Authorization': `Bearer ${getAdminToken()}`,
         'Content-Type': 'application/json',
      }
   }

   // const createToken = async () : Promise<any> => {
   //    try {
   //       const response = await axios.put('https://credimax.gateway.mastercard.com/api/rest/version/72/merchant/3d64693c62a08580a5780f28a94ca2ff', {}, {headers: getHeaders()})
   //       return response
   //    } catch (err : any) {
   //       return err
   //    }
   // }

   const createSession = async () : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/payment-gateway/create-session', {}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const updateSession = async (session: string, token: string, order: string) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + 'api/payment-gateway/update-session', { order: order, session: session,  token: token}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const createToken = async (payment: any) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/payment-gateway/create-token', {payment: payment}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const initiateAuthentication = async (session: any, order: any, transaction: any) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + 'api/payment-gateway/initiate-authentication', {session: session, order: order, transaction: transaction}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const authenticatePayer = async (session: any, order: any, transaction: any) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + 'api/payment-gateway/authenticate-payer', {session: session, order: order, transaction: transaction}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const authorizePayment = async (session: any, order: any, transaction: any, cvc: string) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + 'api/payment-gateway/authorize-payment', {session: session, order: order, transaction: transaction, cvc: cvc}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }


   return {
      createToken,
      createSession,
      updateSession,
      initiateAuthentication,
      authenticatePayer,
      authorizePayment
   }
}

export default Payment