<template>

   <div class="p-5 relative" :class="layout == 'simple' ? 'col-span-7 md:col-span-1' : 'col-span-8 md:col-span-4'">
      <div class="flex flex-col space-y-2">
         <div :class="hasError ? 'text-theme-error' : 'text-theme-gray-2'" class="flex gap-2 uppercase text-small">
            <span>Pickup time</span>
         </div>
         <div class="flex items-center">
            <input v-if="layout=='simple'" @click="toggleTimeDrop" ref="componentRef" @keypress="validateTimeValue($event)" type="text" class="w-[90%] font-bold text-theme-gray-5 text-size-6 border-0 outline-none text-left" :value=selectedTime />
            <span v-else class="font-bold text-theme-gray-5 text-size-6">{{ selectedTime }}</span>
            <ul v-if="isTimeDropOpen" ref="dropdownRef" class="z-10 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5">
               <li v-for="(time, index) in times" :key="index" @click="selectedTime=time" class="py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer">
                  {{ time }}
               </li>
            </ul>
            <div v-if="hasError" class="ml-auto">
               <font-awesome-icon size="1x" icon="fa-solid fa-circle-exclamation" class="text-theme-error" />
            </div>
         </div>
      </div>
   </div>

</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { generateTimeArray, validateTimeValue } from '@/utils/common'

const props = defineProps(['layout', 'hasError', 'data'])
const emit = defineEmits(['timeChanged'])
const isTimeDropOpen = ref<boolean>(false)
const times : string[] = generateTimeArray()
const selectedTime = ref<string>('')
const componentRef = ref()

watch(selectedTime, (newValue, oldValue) => {
   let val : string | null = newValue
   
   if (newValue === '') {
      val = null
   }

  emit('timeChanged', val)
})

const toggleTimeDrop = () => {
   isTimeDropOpen.value = !isTimeDropOpen.value;
}

const listener = (event: any) => {
   if (event.target !== componentRef.value) {
      isTimeDropOpen.value = false
   }
}

onMounted(() => {
   document.addEventListener('click', listener)

   if (props.data) {
      selectedTime.value = props.data
   }
})

onBeforeUnmount(() => {
   document.removeEventListener('click', listener)
})

</script>