<template>

   <div class="flex flex-col overflow-x-hidden w-full">
      <div class="flex flex-col mx-auto w-full">
         <header>
            <slot name="header" />
         </header>
         <section class="flex flex-col flex-grow h-full">
            <slot name="content" />
         </section>
         <footer>
            <slot name="footer" />
         </footer>
      </div>
   </div>

</template>

<script setup lang="ts">

</script>